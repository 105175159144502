// Libraries
import React, { useState } from 'react';
import {
  Alert,
  Button,
  Modal,
  Spinner,
  StatusChip,
  Typography,
} from 'design-system';
import { Link } from 'react-router-dom';
import { format, parseISO } from 'date-fns';
import {
  DocumentTextIcon,
  EllipsisVerticalIcon,
  InformationCircleIcon,
  PaperAirplaneIcon,
  PlusIcon,
  PresentationChartLineIcon,
} from '@heroicons/react/16/solid';
import { TrashIcon } from '@heroicons/react/20/solid';
import { ArrowPathIcon } from '@heroicons/react/24/outline';
import { motion, AnimatePresence } from 'motion/react';
// Components
import { StatusChipProps } from 'design-system/status-chip.component';
import { Menu } from 'design-system/select.component';
// Utils
import { ROUTES, UUID_SHOW_ROUTE_STRING } from 'features/navigation';
import { ChipStatus } from 'design-system/status-chip.component';
import {
  CompanyAttributes,
  FeedbackAttributes,
  FeedbackStatus,
  IApiData,
  PriceQuoteStatus,
  PriceQuoteType,
  ProjectAttributes,
  useApi,
  WorksheetApiResponse,
} from 'api';
import { useSnackbar } from 'hooks';
import { extractApprovals } from '../utils';
import { FeedbackType } from 'features/home/customer-portal/types';
// Constants
import { QUOTE_MENU_OPTIONS } from '../worksheet/constants';

const generateQuoteVersionNumber = (
  company: Maybe<IApiData<CompanyAttributes>>,
  quoteType: PriceQuoteType
) => {
  if (!company) {
    throw new Error('Company is required to generate quote version number');
  }

  const typeIdentifier = quoteType === PriceQuoteType.preliminary ? 'P' : 'F';
  const companyInitials = company.attributes.name
    .split(' ')
    .map((word) => word.charAt(0))
    .join('');
  const uuidFragment = crypto.randomUUID().split('-')[0];
  return `${typeIdentifier}PQ-${companyInitials}-${uuidFragment}`;
};

interface QuoteCardProps {
  updateWorksheetWithApproval: (worksheetId: string, newFeedback: any) => void;
  setWorksheets: React.Dispatch<React.SetStateAction<WorksheetApiResponse[]>>;
  worksheet: WorksheetApiResponse;
  project: IApiData<ProjectAttributes>;
  setWorksheetId: (id: string) => void;
  openWorksheetModal: () => void;
  openPriceQuoteModal: () => void;
}

export const QuoteCard: React.FC<QuoteCardProps> = ({
  updateWorksheetWithApproval,
  setWorksheets,
  worksheet,
  project,
  setWorksheetId,
  openWorksheetModal,
  openPriceQuoteModal,
}) => {
  const { showSnackbar } = useSnackbar();
  const {
    patchProjectWorksheet,
    patchProjectWorksheetPriceQuote,
    postProjectWorksheetPriceQuote,
    postWorksheetFeedback,
    resendFeedbackApprovalEmail,
    shareProjectWorksheetPriceQuoteWithCustomer,
  } = useApi();
  const [isViewFeedbackModalOpen, setViewFeedbackModalOpen] = useState<boolean>(
    false
  );
  const [isSubmitting, setSubmitting] = useState<boolean>(false);
  const [isMoreActionsMenuOpen, setIsMoreActionsMenuOpen] = useState<boolean>(
    false
  );
  const [loadingApproval, setLoadingApproval] = useState<string>('');
  const [showResentApprovalAlert, setShowResentApprovalAlert] = useState<
    boolean
  >(false);

  const approvals = extractApprovals(worksheet);

  const hasManagementApproval =
    approvals?.managementApproval &&
    approvals.managementApproval.attributes.status === FeedbackStatus.approved;

  const isFinalQuote = worksheet.attributes.quoteType === PriceQuoteType.final;
  const isExpired = worksheet.attributes.status === ChipStatus.Expired;
  const { priceQuote } = worksheet.parsedIncluded || {};
  const hasPriceQuote = Boolean(priceQuote);

  const formulaLink = `${ROUTES.SHOW_FORMULA.route.replace(
    UUID_SHOW_ROUTE_STRING,
    worksheet.parsedIncluded?.formula?.id || ''
  )}`;

  const handlePriceQuoteModal = () => {
    // Setting the worksheet id sets the corresponding worksheet,
    // which has the price quote attached to it.
    setWorksheetId(worksheet.id);
    openPriceQuoteModal();
  };

  const handleCreatePriceQuote = () => {
    setSubmitting(true);

    postProjectWorksheetPriceQuote({
      urlParams: `${project.id}/worksheets/${worksheet.id}/price_quotes`,
      data: {
        priceQuote: {
          quoteType: worksheet.attributes.quoteType,
          quoteVersionNumber: generateQuoteVersionNumber(
            (project as any).company,
            worksheet.attributes.quoteType as PriceQuoteType
          ),
          status: PriceQuoteStatus.draft,
          formulaUuid: worksheet.parsedIncluded?.formula?.id,
        },
      },
      handleSuccess: (data) => {
        setWorksheets((prevWorksheets: any) =>
          prevWorksheets.map((ws: WorksheetApiResponse, wsIndex: number) => {
            return ws.id === worksheet.id
              ? {
                  ...ws,
                  parsedIncluded: {
                    ...ws.parsedIncluded,
                    priceQuote: data,
                  },
                }
              : ws;
          })
        );
        openPriceQuoteModal();
      },
      handleFinally: () => {
        setSubmitting(false);
      },
    });
  };

  const handleCreateApprovalRequest = (feedbackType: string) => {
    setLoadingApproval(feedbackType);

    postWorksheetFeedback({
      urlParams: `${worksheet.id}/feedbacks`,
      data: {
        feedback: {
          feedbackType,
          status: FeedbackStatus.pending,
        },
      },
      handleSuccess: (data) => {
        updateWorksheetWithApproval(worksheet.id, data);
      },
      handleFinally: () => {
        setLoadingApproval('');
      },
    });
  };

  const handleResendApprovalRequest = (
    approval: IApiData<FeedbackAttributes>
  ) => {
    setLoadingApproval(approval.attributes.feedbackType);

    resendFeedbackApprovalEmail({
      urlParams: `${worksheet.id}/feedbacks/${approval.id}/resend_feedback_approval_email`,
      data: {
        feedback: {
          feedbackType: approval.attributes.feedbackType,
          status: FeedbackStatus.pending,
        },
      },
      handleSuccess: (data) => {
        updateWorksheetWithApproval(worksheet.id, data);
        setShowResentApprovalAlert(true);
      },
      handleFinally: () => {
        setLoadingApproval('');
      },
    });
  };

  const handleShareWithCustomer = () => {
    if (!priceQuote) return;

    shareProjectWorksheetPriceQuoteWithCustomer({
      urlParams: `${project.id}/worksheets/${priceQuote.relationships.worksheet.data.id}/price_quotes/${priceQuote.id}/share_price_quote`,
      data: {
        price_quote: {
          status: '',
        },
      },
      handleSuccess: ({ data }: any) => {
        showSnackbar('Price quote shared with customer', 'success');
        setWorksheets((prevWorksheets: any) =>
          prevWorksheets.map((ws: WorksheetApiResponse) => {
            return ws.id === worksheet.id
              ? {
                  ...ws,
                  parsedIncluded: {
                    ...ws.parsedIncluded,
                    priceQuote: {
                      ...ws.parsedIncluded?.priceQuote,
                      attributes: {
                        ...ws.parsedIncluded?.priceQuote?.attributes,
                        status: PriceQuoteStatus.in_review,
                      },
                    },
                  },
                }
              : ws;
          })
        );
      },
      handleError: () => {
        showSnackbar('Error sharing price quote with customer', 'error');
      },
      handleFinally: () => setSubmitting(false),
    });
  };

  const renderPriceQuoteActions = () => {
    if (isExpired) return null;

    switch (priceQuote?.attributes.status) {
      case PriceQuoteStatus.draft:
        return (
          <Button
            size="small"
            text="Share with Customer"
            additionalStyles="ml-3"
            disabled={isSubmitting}
            action={handleShareWithCustomer}
          />
        );
      default:
        break;
    }
  };

  const handleMenuClick = (value: string, id: string) => {
    switch (value) {
      case QUOTE_MENU_OPTIONS.ARCHIVE:
        handleArchiveOrUnArchiveQuote({
          id,
          archive: true,
        });
        break;
      case QUOTE_MENU_OPTIONS.UNARCHIVE:
        handleArchiveOrUnArchiveQuote({
          id,
          archive: false,
        });
        break;
      default:
        break;
    }
  };

  const handleUnshareWithCustomer = () => {
    if (!priceQuote) return;

    patchProjectWorksheetPriceQuote({
      urlParams: `${project.id}/worksheets/${priceQuote.relationships.worksheet.data.id}/price_quotes/${priceQuote.id}/update_status`,
      data: {
        price_quote: {
          status: PriceQuoteStatus.draft,
        },
      },
      handleSuccess: ({ data }: any) => {
        showSnackbar('Price quote unshared with customer', 'success');
        setWorksheets((prevWorksheets: any) =>
          prevWorksheets.map((ws: WorksheetApiResponse) => {
            return ws.id === worksheet.id
              ? {
                  ...ws,
                  parsedIncluded: {
                    ...ws.parsedIncluded,
                    priceQuote: {
                      ...ws.parsedIncluded?.priceQuote,
                      attributes: {
                        ...ws.parsedIncluded?.priceQuote?.attributes,
                        status: PriceQuoteStatus.draft,
                      },
                    },
                  },
                }
              : ws;
          })
        );
      },
    });
  };

  const handleArchiveOrUnArchiveQuote = ({
    id,
    archive,
  }: {
    id: string;
    archive: boolean;
  }) => {
    patchProjectWorksheet({
      urlParams: `${project.id}/worksheets/${id}/archive_quote`,
      data: {
        worksheet: {
          quoteArchived: archive,
        },
      },
      handleSuccess: () => {
        showSnackbar('Quote archived successfully', 'success');
        setWorksheets((prevWorksheets: any) =>
          prevWorksheets.map((ws: WorksheetApiResponse) => {
            return ws.id === worksheet.id
              ? {
                  ...ws,
                  attributes: {
                    ...ws.attributes,
                    quoteArchived: archive,
                  },
                }
              : ws;
          })
        );
      },
      handleError: () => {
        showSnackbar('Error unsharing price quote with customer', 'error');
      },
      handleFinally: () => setSubmitting(false),
    });
  };

  const quoteMenuOptions = [
    {
      label: `${
        worksheet.attributes.quoteArchived ? 'Restore' : 'Archive'
      } this quote`,
      leadingIcon: worksheet.attributes.quoteArchived ? (
        <ArrowPathIcon className="w-5 h-5 text-royal-50" />
      ) : (
        <TrashIcon className="w-5 h-5 text-red-50" />
      ),
      value: worksheet.attributes.quoteArchived ? 'unarchive' : 'archive',
    },
  ];

  const renderApprovalLineItem = (
    approval: Maybe<IApiData<FeedbackAttributes>>,
    approvalType: string,
    displayName: string,
    dependentApprovals?: Maybe<IApiData<FeedbackAttributes>>[]
  ) => {
    const approvalIsPending =
      approval?.attributes.status === FeedbackStatus.pending;
    const approvalIsApproved =
      approval?.attributes.status === FeedbackStatus.approved;
    return (
      <div className="flex justify-between items-center">
        <div className="flex gap-3 items-center">
          <span>{displayName}</span>
          {!approvalIsApproved &&
            (loadingApproval === approvalType ? (
              <Spinner width="w-6" height="h-6" />
            ) : (
              <Button
                disabled={
                  dependentApprovals !== undefined &&
                  dependentApprovals?.some(
                    (dependentApproval) =>
                      dependentApproval?.attributes?.status !==
                      FeedbackStatus.approved
                  )
                }
                onClick={() =>
                  !approvalIsPending
                    ? handleCreateApprovalRequest(approvalType)
                    : handleResendApprovalRequest(approval)
                }
                leadingIcon={<PaperAirplaneIcon />}
                text={approvalIsPending ? 'Resend' : 'Send'}
                type={approvalIsPending ? 'secondary' : 'primary'}
              />
            ))}
        </div>
        {approval && (
          <div className="flex gap-3 items-center">
            <span className="text-grey-50 text-xs">
              {approvalIsPending ? 'Shared ' : ''}
              {format(parseISO(approval.attributes.updatedAt), 'MM/dd/yy')}
            </span>
            {(approvalIsPending || approvalIsApproved) && (
              <StatusChip
                status={
                  approvalIsPending ? ChipStatus.InReview : ChipStatus.Approved
                }
              />
            )}
          </div>
        )}
      </div>
    );
  };

  return (
    <>
      {isViewFeedbackModalOpen && (
        <Modal handleClose={() => setViewFeedbackModalOpen(false)}>
          <div className="py-4">
            <div className="px-4 mb-6">
              <Typography
                variant="h4"
                font="inter"
                weight="bold"
                additionalStyles="mb-6"
              >
                Feedback for {priceQuote?.attributes.quoteVersionNumber}
              </Typography>
              <Typography variant="p" font="inter">
                {priceQuote?.attributes.feedbackNote || 'No feedback provided.'}
              </Typography>
            </div>
            <hr className="border-t border-grey-90" />
            <div className="flex px-6 justify-end mt-2">
              <Button
                size="large"
                text="Close"
                action={() => setViewFeedbackModalOpen(false)}
              />
            </div>
          </div>
        </Modal>
      )}
      <AnimatePresence mode="wait">
        <motion.div
          layout
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.3, ease: 'linear' }}
          className="flex flex-col bg-white rounded"
        >
          {showResentApprovalAlert && (
            <div className="col-span-12 mt-4 mx-4">
              <Alert
                content={'Approval request resent!'}
                onClose={() => setShowResentApprovalAlert(false)}
                severity="success"
              />
            </div>
          )}
          <div className="flex flex-col gap-3 sm:gap-0 sm:flex-row sm:justify-between p-4 border-b border-grey-90">
            <div className="flex items-center gap-3">
              <div className="text-lg">
                {isFinalQuote ? 'Final' : 'Prelim'} Quote v{' '}
                {worksheet.attributes.quoteVersion}
              </div>
              <StatusChip
                status={
                  (priceQuote?.attributes
                    .status as StatusChipProps['status']) ||
                  PriceQuoteStatus.draft
                }
              />
            </div>
            <div className="relative flex items-center gap-3 text-xs">
              <div className="flex flex-col sm:items-end">
                <Link to={formulaLink} target="_blank">
                  <span className="text-royal-50 cursor-pointer">
                    Formula #:
                    {worksheet.parsedIncluded?.formula?.attributes.externalId}
                  </span>
                </Link>
                <span className="text-grey-50">
                  {worksheet.parsedIncluded?.formula?.attributes.name}
                </span>
              </div>
              <div className="relative">
                <EllipsisVerticalIcon
                  onClick={() => setIsMoreActionsMenuOpen(true)}
                  className="h-5 w-5 text-royal-50 cursor-pointer"
                />

                {isMoreActionsMenuOpen && (
                  <Menu
                    width="w-[200px]"
                    onClose={() => setIsMoreActionsMenuOpen(false)}
                    options={quoteMenuOptions}
                    onChange={(option: Option) => {
                      handleMenuClick(option.value, worksheet.id);
                    }}
                  />
                )}
              </div>
            </div>
          </div>
          <div className="flex flex-col p-4 gap-9">
            <div>
              <div className="text-xs font-mono text-grey-50 uppercase font-[500] mb-3">
                Worksheet
              </div>
              <div className="flex flex-col sm:flex-row gap-3 sm:items-center">
                <div
                  onClick={() => {
                    setWorksheetId(worksheet.id);
                    openWorksheetModal();
                  }}
                  className={`${
                    isExpired
                      ? 'text-grey-50 cursor-default'
                      : 'text-royal-50 cursor-pointer'
                  } flex gap-2 px-3 py-4 rounded-[4px] border border-grey-90 `}
                >
                  <PresentationChartLineIcon className="h-5 w-5 " />
                  <span className="">Pricing Worksheet</span>
                </div>
                <div className="flex flex-col text-xs text-grey-50">
                  <span className="">
                    Last edited{' '}
                    {format(
                      new Date(worksheet.attributes.updatedAt),
                      'MM/dd/yy'
                    )}{' '}
                    at {format(new Date(worksheet.attributes.updatedAt), 'p')}
                  </span>
                </div>
              </div>
            </div>
            <div>
              <div className="flex gap-3 items-center text-xs font-mono text-grey-50 uppercase font-[500] mb-3">
                <span>Worksheet Approvals</span>
                <InformationCircleIcon className="h-4 w-4 cursor-pointer" />
              </div>
              <div className="flex flex-col gap-3">
                {isFinalQuote
                  ? [
                      renderApprovalLineItem(
                        approvals.purchasingApproval,
                        FeedbackType.purchasing,
                        '1. Purchasing'
                      ),
                      renderApprovalLineItem(
                        approvals.operationsApproval,
                        FeedbackType.operations,
                        '2. Operations'
                      ),
                      renderApprovalLineItem(
                        approvals.managementApproval,
                        FeedbackType.management,
                        '3. Management',
                        [
                          approvals.operationsApproval,
                          approvals.purchasingApproval,
                        ]
                      ),
                    ]
                  : renderApprovalLineItem(
                      approvals.managementApproval,
                      FeedbackType.management,
                      'Management'
                    )}
              </div>
            </div>
            <div className="flex flex-col gap-3 p-3 rounded border border-grey-90">
              <span className="text-xs font-mono text-grey-50 uppercase font-[500]">
                Quote
              </span>
              {hasPriceQuote ? (
                <div className="flex flex-col gap-3 sm:gap-0 sm:flex-row justify-between sm:items-center">
                  <div className="flex items-center">
                    <Button
                      size="large"
                      leadingIcon={<DocumentTextIcon className="h-5 w-5" />}
                      action={handlePriceQuoteModal}
                      className={`${
                        isExpired
                          ? 'text-grey-50 cursor-default'
                          : 'text-royal-50 cursor-pointer'
                      } flex  px-3 py-4 rounded-[4px] border border-grey-90 `}
                      text={`${isFinalQuote ? 'Final' : 'Prelim'} Quote Doc`}
                    />
                    {renderPriceQuoteActions()}
                  </div>

                  <div className="flex gap-3 text-xs font-inter items-center">
                    {priceQuote?.attributes.status ===
                    PriceQuoteStatus.in_review ? (
                      <>
                        <span className="text-grey-50">
                          Shared{' '}
                          {new Date(
                            priceQuote!.attributes.updatedAt || ''
                          ).toLocaleDateString()}
                        </span>
                        <span
                          className="text-grey-50 cursor-pointer underline"
                          onClick={handleUnshareWithCustomer}
                        >
                          Un-Share
                        </span>
                      </>
                    ) : null}
                    {priceQuote?.attributes.status ===
                    PriceQuoteStatus.rejected ? (
                      <span
                        onClick={() => setViewFeedbackModalOpen(true)}
                        className="underline cursor-pointer text-royal-50"
                      >
                        View Feedback
                      </span>
                    ) : null}
                    <StatusChip status={priceQuote?.attributes.status as any} />
                  </div>
                </div>
              ) : (
                <Button
                  leadingIcon={<PlusIcon className="h-5 w-5" />}
                  type="primary"
                  disabled={isSubmitting || !hasManagementApproval}
                  action={handleCreatePriceQuote}
                  text="Customer Quote Doc"
                  additionalStyles="pr-3"
                />
              )}
            </div>
          </div>
        </motion.div>
      </AnimatePresence>
    </>
  );
};
