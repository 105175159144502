// Libraries
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ArrowLeftIcon, ArrowRightIcon } from '@heroicons/react/16/solid';
import { useFormikContext } from 'formik';
// Components
import { BottomBar, Button, DoubleButton } from 'design-system';
// Utils
import { InternalBriefFormValues } from './form.config';
// Constants
import { ROUTES } from 'features/navigation';

interface InternalBriefBottomBarProps {
  currentPage: number;
  fixedDivWidth?: string;
  pages: { title: string; component: React.ReactNode }[];
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
  [key: string]: any;
}

export const InternalBriefBottomBar: React.FC<InternalBriefBottomBarProps> = ({
  currentPage,
  fixedDivWidth,
  pages,
  setCurrentPage,
  ...rest
}) => {
  const navigate = useNavigate();
  const { submitForm, validateForm } = useFormikContext<
    InternalBriefFormValues
  >();

  const bottomBarButtons = [
    <Button
      size="large"
      text="Cancel"
      type="secondary"
      onClick={() => {
        const userConfirmed = window.confirm(
          'Are you sure you want to exit? Your brief will not be saved.'
        );
        if (userConfirmed) navigate(ROUTES.HOME.route);
      }}
    />,
    <DoubleButton
      buttonOneAction={() => {
        if (currentPage > 0) setCurrentPage(currentPage - 1);
      }}
      buttonOneIcon={<ArrowLeftIcon />}
      buttonOneText="Prev"
      buttonTwoAction={() => {
        if (currentPage < pages.length - 1) {
          setCurrentPage(currentPage + 1);
        } else {
          submitForm();
        }
      }}
      buttonTwoIcon={
        currentPage < pages.length - 1 ? <ArrowRightIcon /> : undefined
      }
      buttonTwoText={currentPage < pages.length - 1 ? `Next` : `Submit`}
      size="large"
    />,
    <Button
      iconSize="16"
      size="large"
      text="Next"
      trailingIcon={<ArrowRightIcon />}
      onClick={() => {
        validateForm().then((errors) => {
          if (
            errors.projectName ||
            errors.company?.value ||
            errors.approvalDate ||
            errors.cogsType ||
            errors.cogsRange ||
            errors.targetCost ||
            errors.size ||
            errors.initialDateOfBrief ||
            errors.minimumOrderQuantity ||
            errors.targetDate
          ) {
            alert('Please correct all errors before proceeding');
            return;
          }
          setCurrentPage(currentPage + 1);
        });
      }}
    />,
    <Button iconSize="16" size="large" text="Save" action={submitForm} />,
    // the 4th button is the custom button
    <Button
      iconSize="16"
      size="large"
      text={rest.customButton?.label}
      action={rest.customButton?.action}
      type={rest.customButton?.type}
    />,
  ];

  const renderButtons = () => {
    // if the user is in the middle of multiple pages
    if (currentPage > 0) {
      return [bottomBarButtons[0], bottomBarButtons[1]];
    }
    // if there is only one page
    if (currentPage === 0 && pages.length === 0) {
      return [bottomBarButtons[4], bottomBarButtons[3]];
    }
    // if there's multiple pages and the user is on the first page
    return [bottomBarButtons[0], bottomBarButtons[2]];
  };

  return <BottomBar fixedDivWidth={fixedDivWidth} buttons={renderButtons()} />;
};
