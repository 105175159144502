// Libraries
import React from 'react';
import { HorizontalCard } from './horizontal-card.component';
import { VerticalCard } from './vertical-card.component';

interface CardProps {
  orientation: 'horizontal' | 'vertical';
  title: string | React.ReactNode;
  content?: string | string[];
  icon?: React.ReactComponentElement<any> | null;
  iconColor?: string;
  image?: string;
  isDisabled?: boolean;
  action?: () => void;
  [key: string]: any;
}

export const Card: React.FC<CardProps> = ({
  orientation,
  title,
  content,
  icon: Icon,
  iconColor,
  isDisabled,
  image,
  action,
  ...rest
}) => {
  const renderCard = () => {
    switch (orientation) {
      case 'horizontal':
        return (
          <HorizontalCard
            title={title}
            content={content}
            icon={Icon}
            iconColor={iconColor}
            image={image}
            isDisabled={isDisabled}
            action={action}
            {...rest}
          />
        );
      case 'vertical':
        return (
          <VerticalCard
            title={title}
            content={content}
            icon={Icon}
            iconColor={iconColor}
            image={image}
            isDisabled={isDisabled}
            action={action}
            {...rest}
          />
        );
    }
  };

  return renderCard();
};
