// Libraries
import React from 'react';
// Components
import { Button } from 'design-system';
import { PencilSquareIcon } from '@heroicons/react/24/solid';
import { InternalRtlBriefEdit } from './internal-rtl-brief-edit.component';
import {
  BriefAttributes,
  CustomerAttributes,
  IApiData,
  ProjectAttributes,
  SampleRecipientAttributes,
} from 'api';
import { RtlBriefShowPage } from '../form-modules/rtl-brief-show-page.component';

interface InternalRtlBriefProps {
  rtlFormulaUuid: Maybe<string>;
  isEditMode: boolean;
  project: IApiData<ProjectAttributes> & {
    brief: IApiData<BriefAttributes>;
    contact: IApiData<CustomerAttributes>;
    sampleRecipients: IApiData<SampleRecipientAttributes>[];
  };
  handleEditClick: () => void;
}

export const InternalRtlBriefPage: React.FC<InternalRtlBriefProps> = ({
  rtlFormulaUuid,
  isEditMode,
  project,
  handleEditClick,
}) => {
  return (
    <div className="bg-grey-99 relative">
      {!isEditMode && (
        <div className="absolute top-6 right-6">
          <Button
            type="secondary"
            leadingIcon={<PencilSquareIcon />}
            text="Edit"
            action={handleEditClick}
            size="large"
          />
        </div>
      )}
      {isEditMode ? (
        <InternalRtlBriefEdit rtlFormulaUuid={rtlFormulaUuid} />
      ) : (
        <RtlBriefShowPage
          project={project}
          brief={project.brief}
          sampleRecipients={project.sampleRecipients}
          contact={project.contact}
          formulaUuid={rtlFormulaUuid!}
        />
      )}
    </div>
  );
};
