// Libraries
import React, { useEffect, useRef, useState } from 'react';
import { Formik } from 'formik';
//Components
import { InternalBriefNav } from 'features/brief/internal-brief';
import {
  ClaimsShowPage,
  ConceptProductSpecificShowPage,
  FormulaSamplesShowPage,
  IngredientsAndFormulaShowPage,
  PackagingShowPage,
  ProjectOverviewShowPage,
  RegulatoryShowPage,
  TestingShowPage,
} from './customer-brief-view-pages';
import { InternalBriefPage } from 'features/brief/internal-brief/internal-brief-page.component';
import { RtlBriefShowPage } from 'features/brief/form-modules/rtl-brief-show-page.component';
// Utils
import { IApiData, ProjectAttributes } from 'api';

interface ICustomerBriefView {
  project: IApiData<ProjectAttributes>;
}

export const CustomerBriefView: React.FC<ICustomerBriefView> = ({
  project,
}) => {
  // State
  const [currentPage, setCurrentPage] = useState(0);

  const fixedDivRef = useRef<HTMLDivElement>(null);
  const brief = project.parsedIncluded.brief;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentPage]);

  if (!project || !brief) {
    return null;
  }

  const pages = [
    {
      component: <ProjectOverviewShowPage project={project} />,
      title: 'Project Overview',
    },
    {
      component: <ConceptProductSpecificShowPage project={project} />,
      title: 'Concept & Product',
    },
    {
      component: <IngredientsAndFormulaShowPage project={project} />,
      title: 'Ingredients & Formula',
    },
    { component: <ClaimsShowPage project={project} />, title: 'Claims' },
    { component: <PackagingShowPage project={project} />, title: 'Packaging' },
    { component: <TestingShowPage project={project} />, title: 'Testing' },
    {
      component: <RegulatoryShowPage project={project} />,
      title: 'Regulatory',
    },
    {
      component: <FormulaSamplesShowPage project={project} />,
      title: 'Formula Samples',
    },
  ];

  return (
    <Formik initialValues={{}} onSubmit={() => {}}>
      {project.attributes.projectType === 'RTL' ? (
        <div className="max-w-[1256px] col-start-1 col-span-2 md:col-span-6 h-auto -mb-4 md:mb-0 lg:-mb-28">
          <div className="flex flex-col md:flex-row">
            <RtlBriefShowPage
              project={project}
              brief={project.parsedIncluded.brief}
              sampleRecipients={project.parsedIncluded?.sampleRecipients}
              contact={project.parsedIncluded?.contact}
              formulaUuid={project.parsedIncluded?.rtlFormula?.id}
            />
          </div>
        </div>
      ) : (
        <div
          ref={fixedDivRef}
          className="max-w-[1256px] col-start-1 col-span-2 md:col-span-6 h-auto -mb-20 md:mb-0 font-inter"
        >
          <div className="flex flex-col md:flex-row">
            <div className="mb-3 md:mb-0">
              <InternalBriefNav
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                isCustomerBrief={true}
              />
            </div>
            <InternalBriefPage page={pages[currentPage]} />
          </div>
        </div>
      )}
    </Formik>
  );
};
