// Libraries
import React from 'react';

interface ButtonProps {
  additionalStyles?: string;
  leadingIcon?: React.ReactComponentElement<any>;
  action?: () => void;
  iconSize?: string;
  isSubmitButton?: boolean;
  selected?: boolean;
  size?: 'small' | 'medium' | 'large';
  text: string;
  trailingIcon?: React.ReactComponentElement<any>;
  type?:
    | 'primary'
    | 'secondary'
    | 'soft'
    | 'accepted'
    | 'urgent'
    | 'urgentSecondary';
  width?: string;
  [key: string]: any;
}

const BUTTON_SIZE = {
  small: 'p-1 text-xs h-6',
  medium: 'px-[6px] py-2 text-sm h-[34px]',
  large: 'px-[10px] py-3 text-base h-10',
};

const BUTTON_COLOR = {
  primary:
    'text-grey-90 bg-royal-50 border-royal-50 hover:bg-royal-40 active:bg-royal-30 focus:border-royal-30',
  secondary:
    'text-grey-20 bg-white border-grey-90 hover:bg-royal-95 active:bg-royal-90 focus:bg-white focus:border-royal-50',
  soft:
    'text-grey-20 bg-blue-95 border-grey-90 hover:bg-royal-90 active:bg-royal-80 focus:border-royal-50',
  accepted:
    'text-grey-90 bg-green-50 border-green-50 hover:bg-green-40 active:bg-green-30 focus:border-green-30',
  urgent: 'text-grey-50 bg-red-50 hover:red-50 active:red-50',
  urgentSecondary: 'text-red-50 bg-white border-grey-90 hover:bg-red-95',
};

export const Button: React.FC<ButtonProps> = ({
  additionalStyles,
  action,
  iconSize,
  isSubmitButton = false,
  leadingIcon,
  selected,
  size = 'medium',
  text,
  type = 'primary',
  trailingIcon,
  width,
  ...rest
}) => {
  let derivedIconSize;
  switch (size) {
    case 'small':
      derivedIconSize = '14px';
      break;
    case 'medium':
      derivedIconSize = '16px';
      break;
    case 'large':
      derivedIconSize = '20px';
      break;
    default:
      derivedIconSize = '16px';
  }

  return (
    <button
      type={`${isSubmitButton ? 'submit' : 'button'}`}
      onClick={() => {
        if (action) action();
      }}
      className={`${BUTTON_SIZE[size]} ${BUTTON_COLOR[type]} ${
        width ? width : 'w-auto max-w-max'
      } ${selected && 'border-royal-50 bg-blue-95'} ${
        type === 'primary' ? 'disabled:border-none' : ''
      } border inline-flex justify-center items-center rounded-[4px] cursor-pointer font-[500] disabled:text-grey-60 disabled:bg-grey-95 disabled:outline-0 disabled:cursor-default ${
        additionalStyles ?? ''
      }`}
      {...rest}
    >
      {leadingIcon &&
        React.cloneElement(leadingIcon, {
          className: `${
            rest.disabled
              ? 'text-grey-60'
              : type === 'secondary'
              ? 'text-royal-50'
              : ''
          }`,
          height: iconSize ? iconSize : derivedIconSize,
          width: iconSize ? iconSize : derivedIconSize,
        })}{' '}
      <span
        className={`${
          !leadingIcon ? 'ml-0' : size === 'small' ? 'ml-1' : 'ml-2'
        } whitespace-nowrap`}
      >
        {text}
      </span>
      {trailingIcon &&
        React.cloneElement(trailingIcon, {
          className: `ml-1${
            rest.disabled
              ? 'text-grey-60'
              : type === 'secondary'
              ? 'text-royal-50'
              : ''
          }`,
          height: iconSize ? iconSize : derivedIconSize,
          width: iconSize ? iconSize : derivedIconSize,
        })}{' '}
    </button>
  );
};
