// Libraries
import React from 'react';
import { Card, Chip, Divider, Typography } from 'design-system';
import { CheckIcon } from '@heroicons/react/16/solid';
// Utils
import { IApiData, ProjectAttributes } from 'api';
// Assets
import aerosol from 'img/aerosol.png';
import butter from 'img/butter.png';
import cream from 'img/cream.png';
import foam from 'img/foam.png';
import gelCream from 'img/gel-cream.png';
import liquid from 'img/liquid.png';
import lotion from 'img/lotion.png';
import serum from 'img/serum.png';
import spray from 'img/spray.png';
import stick from 'img/form-stick.png';
import gel from 'img/form-gel.png';
import oil from 'img/form-oil.png';
import translucent from 'img/translucent.png';
import transparent from 'img/transparent.png';
import opaque from 'img/opaque.png';
// Constants
import { STRUCTURES } from 'features/brief/brief.constants';
import { OPACITIES } from 'features/brief/brief.constants';

interface IngredientsAndFormulaShowPageProps {
  project: IApiData<ProjectAttributes>;
}

const STRUCTURES_MAP = [
  {
    title: STRUCTURES.BUTTER,
    image: butter,
  },
  {
    title: STRUCTURES.SERUM,
    image: serum,
  },
  {
    title: STRUCTURES.STICK,
    image: stick,
  },
  {
    title: STRUCTURES.LOTION,
    image: lotion,
  },
  {
    title: STRUCTURES.OIL,
    image: oil,
  },
  {
    title: STRUCTURES.CONTINUOUS_SPRAY_BOV,
    image: spray,
  },
  {
    title: STRUCTURES.CREAM,
    image: cream,
  },
  {
    title: STRUCTURES.GEL,
    image: gel,
  },
  {
    title: STRUCTURES.GEL_CREAM,
    image: gelCream,
  },
  {
    title: STRUCTURES.MOUSSE_FOAM,
    image: foam,
  },
  {
    title: STRUCTURES.LIQUID,
    image: liquid,
  },
  {
    title: STRUCTURES.AEROSOL,
    image: aerosol,
  },
];

const OPACITY_MAP = [
  {
    title: OPACITIES.TRANSLUCENT,
    image: translucent,
  },
  {
    title: OPACITIES.CLEAR_TRANSPARENT,
    image: transparent,
  },
  {
    title: OPACITIES.OPAQUE,
    image: opaque,
  },
];
const FRAGRANCE_MAP = {
  free: 'Fragrance-free',
  own: 'I have my own',
  bmark: 'I have a benchmark fragrance',
  custom: 'I want to develope a frangrance',
};
const STANDARDS_MAP = [
  {
    label: 'Sephora Clean',
    value: '8a2046e9-182b-46af-9f09-d97c4b8f0db1',
    isRtl: false,
  },
  {
    label: 'Ulta (Conscious Beauty)',
    value: '7772ff2e-a7aa-49a2-8b07-2a2b5f7eaad6',
    isRtl: false,
  },
  {
    label: 'Target Clean',
    value: '9844590e-f5ef-43d4-978e-39a07e75f3fc',
    isRtl: false,
  },
  {
    label: 'Credo Clean',
    value: '3bff8d98-08dc-407e-886f-9f83a6c0a429',
    isRtl: false,
  },
];

export const IngredientsAndFormulaShowPage: React.FC<IngredientsAndFormulaShowPageProps> = ({
  project,
}) => {
  const brief = project.parsedIncluded?.brief;
  const standards = project.parsedIncluded?.standards;
  const briefStructure = STRUCTURES_MAP.find(
    (structure) => structure.title === brief.attributes?.structure
  );
  const briefOpacity = OPACITY_MAP.find(
    (opacity) => opacity.title === project.attributes?.opacity
  );
  const fragrance =
    FRAGRANCE_MAP[
      brief.attributes?.fragranceType as keyof typeof FRAGRANCE_MAP
    ];
  // This is the display label version of the standards that are on the brief
  const briefStandardLabels = STANDARDS_MAP.filter((standard) =>
    standards?.some((st: any) => st.id === standard.value)
  );

  const renderStandards = () => {
    return briefStandardLabels.map((standard, index) => (
      <Chip
        key={index}
        value={standard.value}
        label={standard.label}
        borderColor="border-royal-50"
        bgColor="bg-blue-95"
        noHover={true}
      />
    ));
  };

  return (
    <div className="flex flex-col gap-y-12">
      <div className="flex flex-col gap-y-6">
        <Typography font="inter" variant="h3" id="formula-specifics">
          Formula Specifics
        </Typography>
        <div className="flex flex-col gap-y-3">
          <Typography font="inter" variant="h4" weight="semibold">
            Form/Structure
          </Typography>
          {briefStructure && (
            <Card
              orientation="horizontal"
              title={briefStructure?.title}
              image={briefStructure?.image}
              isDisabled={true}
              cardwidth="sm:w-[144px]"
              className="sm:w-[303px] h-[74px] cursor-auto"
            />
          )}
        </div>
        <div className="flex flex-col gap-y-3">
          <Typography font="inter" variant="h4" weight="semibold">
            Opacity
          </Typography>
          {briefOpacity && (
            <Card
              orientation="horizontal"
              title={briefOpacity?.title}
              image={briefOpacity?.image}
              isDisabled={true}
              cardwidth="sm:w-[144px]"
              className="sm:w-[303px] h-[74px] cursor-auto"
            />
          )}
        </div>
        <div className="flex flex-col gap-y-3">
          <Typography font="inter" variant="h4" weight="semibold">
            Color
          </Typography>
          <Typography font="inter" variant="h4">
            {project.attributes?.colorNote}
          </Typography>
        </div>
      </div>

      <Divider />

      <div className="flex flex-col gap-y-6">
        <Typography font="inter" variant="h3" id="ingredient-notes">
          Ingredient Notes
        </Typography>
        <div className="flex flex-col gap-y-5">
          <Typography
            font="inter"
            variant="h5"
            weight="semibold"
            color="secondary"
          >
            Must-have/Key Ingredients
          </Typography>
          <Typography font="inter" variant="h4">
            {brief.attributes.mustHaveIngredients}
          </Typography>
        </div>
        <div className="flex flex-col gap-y-5">
          <Typography
            font="inter"
            variant="h5"
            weight="semibold"
            color="secondary"
          >
            Restricted ingredients
          </Typography>
          <Typography font="inter" variant="h4">
            {brief.attributes.mustExcludeIngredients}
          </Typography>
        </div>
        <div className="flex flex-col">
          <Typography font="inter" variant="h6" weight="medium">
            Exclude banned ingredients for:
          </Typography>
          <div className="flex flex-wrap gap-3">{renderStandards()}</div>
        </div>
      </div>

      <Divider />

      <div className="flex flex-col gap-y-6">
        <Typography font="inter" variant="h3" id="fragrance">
          Fragrance
        </Typography>
        <div className="flex flex-col gap-y-3">
          <Typography font="inter" variant="h6" weight="medium">
            Do you need a fragrance?
          </Typography>
          <div className="flex flex-row gap-x-1 items-center">
            <CheckIcon className="w-4 h-4 text-grey-60" />
            <Typography font="inter" variant="h4">
              {fragrance}
            </Typography>
          </div>
        </div>
        <div className="flex flex-col gap-y-5">
          <Typography font="inter" variant="h6" weight="semibold">
            Fragrance Notes
          </Typography>
          <Typography font="inter" variant="h4">
            {brief.attributes.fragranceComment}
          </Typography>
        </div>
      </div>
    </div>
  );
};
