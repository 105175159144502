// Libraries
import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams, Link } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
  DocumentPlusIcon,
  InformationCircleIcon,
  PencilSquareIcon,
  TrashIcon,
} from '@heroicons/react/16/solid';
// Components
import {
  Alert,
  Autocomplete,
  BottomBar,
  Button,
  Chip,
  CollapsibleSection,
  IncrementerDecrementer,
  Modal,
  Page,
  Select,
  Switch,
  TextArea,
  TextField,
  Tooltip,
} from 'design-system';
import { RtlFormulaCatalogueTopNav, ShoppingCart } from './';
// Utils
import { useApi } from 'api';
import { RtlShoppingCartContext, useAlert, MobileContext } from 'context';
import { USDollar } from 'features/utils';
// Constants
import { RTL_CATALOGUE_IMAGES, TYPE_OF_SYSTEM } from './constants';
import { PRODUCT_OPTIONS } from 'features/brief/form-modules/form-module.constants';
import {
  primaryComponentOptions,
  PRODUCT_CATEGORIES,
  OPACITIES,
  STRUCTURES,
} from 'features/brief/brief.constants';
import {
  CLOSURE_MENU_ITEMS,
  MATERIAL_MENU_ITEMS,
} from 'features/pricing-quote/constants';
import { MASTER_BOX_OPTIONS } from 'features/brief/form-modules/master-box.component';
import { UNITS } from 'features/types';
import { ROUTES, UUID_SHOW_ROUTE_STRING } from 'features/navigation';
import { RtlFormulaCatalogueBreadcrumbs } from './rtl-formula-catalogue-top-nav.component';

const RENDER_MODE = {
  CREATE: 'create',
  EDIT: 'edit',
  VIEW: 'view',
};

const STATUS = {
  DRAFT: 'draft',
  PUBLISHED: 'published',
};

interface FormItem {
  title: string;
  items: React.ReactNode | React.ReactNode[];
}

interface FormItems {
  [key: string]: FormItem;
}

type RtlCatalogueFormValues = {
  clinicalClaimAntiRedness: boolean;
  clinicalClaimBlueLightProtection: boolean;
  clinicalClaimGlutenFree: boolean;
  clinicalClaimHydration: boolean;
  clinicalClaimHypoallergenic: boolean;
  clinicalClaimNoAnimalDerivedIngredients: boolean;
  clinicalClaimNoAnimalTesting: boolean;
  clinicalClaimNonOtcComment: string;
  clinicalClaimSoothing: boolean;
  clinicalClaimVegan: boolean;
  closure: string;
  colorNote: string;
  countryComplianceCanada: boolean;
  countryComplianceEu: boolean;
  countryComplianceNote: string;
  countryComplianceOther: boolean;
  countryComplianceUs: boolean;
  displayName: string;
  formulaNumber: { label: string; value: string };
  fragranceNote: string;
  imageId: string;
  internalNotes: string;
  status: string;
  keyBenefitBrightening: boolean;
  keyBenefitEyesReduceAppearanceOfDarkCircles: boolean;
  keyBenefitEyesReduceAppearanceOfFineLinesCrowsFeet: boolean;
  keyBenefitEyesReduceAppearanceOfPuffiness: boolean;
  keyBenefitFirming: boolean;
  keyBenefitHydration: boolean;
  keyBenefitMattifying: boolean;
  keyBenefitMoisturization: boolean;
  keyBenefitRadianceGlow: boolean;
  keyBenefitSelfTanner: boolean;
  keyBenefitSmoothing: boolean;
  keyBenefitTightening: boolean;
  masterBox: string;
  material: string;
  minimumOrderQuantity: string;
  opacity: string;
  otc: boolean;
  price: string;
  primaryComponent: string;
  productDescription: string;
  productType: string;
  productionLocation: string;
  regulatoryGlutenFree: boolean;
  regulatoryIfraCompliant: boolean;
  regulatoryNoAnimalDerivedIngredients: boolean;
  regulatoryNoAnimalTesting: boolean;
  regulatoryVegan: boolean;
  retailerClaimCyclicSiliconeFree: boolean;
  retailerClaimSephoraClean: boolean;
  retailerClaimTargetClean: boolean;
  retailerClaimUltaClean: boolean;
  rinseOffLeaveOn: string;
  fillSize: string;
  spf: string;
  structure: string;
  testingFinalComponent: boolean;
  testingHript: boolean;
  testingHriptWithDermatologistSignature: boolean;
  testingHriptWithHypoallergenicClaim: boolean;
  testingInVitro: boolean;
  testingOcularIrritation: boolean;
  testingPet: boolean;
  testingSpfDeterminationFda: boolean;
  testingSpfDeterminationIso: boolean;
  testingStability: boolean;
  testingWaterActivity: boolean;
  tinted: boolean;
  typeOfSystem: string;
  unit: string;
};

export const RtlFormulaCatalogueEntryForm: React.FC = () => {
  const { pathname, state } = useLocation();
  const { uuid } = useParams<{
    uuid: string;
  }>();
  const { showAlertWithTimeout } = useAlert();
  const { addItem } = useContext(RtlShoppingCartContext);
  const { isMobile } = useContext(MobileContext);
  const {
    deleteRtlCatalogueEntry,
    getRtlCatalogueEntry,
    patchRtlCatalogueEntry,
    postRtlCatalogueEntry,
    searchFormulas,
  } = useApi();
  const navigate = useNavigate();

  const { passedProductCategory } = state || {};

  const [numOfSamples, setNumOfSamples] = useState(1);
  const [rtlCatalogueEntry, setRtlCatalogueEntry] = useState<any>(undefined);
  const [showSaveAsDraftModal, setShowSaveAsDraftModal] = useState<boolean>(
    false
  );
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [renderMode, setRenderMode] = useState(
    pathname === ROUTES.CREATE_RTL_CATALOGUE_ENTRY.route
      ? RENDER_MODE.CREATE
      : RENDER_MODE.VIEW
  );

  const productCategory =
    passedProductCategory ||
    rtlCatalogueEntry?.product.attributes.productCategory ||
    '';

  const isDraft = rtlCatalogueEntry?.attributes.status === STATUS.DRAFT;
  const isPublished = rtlCatalogueEntry?.attributes.status === STATUS.PUBLISHED;

  const [initialValues, setInitialValues] = useState<RtlCatalogueFormValues>({
    clinicalClaimAntiRedness: false,
    clinicalClaimBlueLightProtection: false,
    clinicalClaimGlutenFree: false,
    clinicalClaimHydration: false,
    clinicalClaimHypoallergenic: false,
    clinicalClaimNoAnimalDerivedIngredients: false,
    clinicalClaimNoAnimalTesting: false,
    clinicalClaimNonOtcComment: '',
    clinicalClaimSoothing: false,
    clinicalClaimVegan: false,
    closure: '',
    colorNote: '',
    countryComplianceCanada: false,
    countryComplianceEu: false,
    countryComplianceNote: '',
    countryComplianceOther: false,
    countryComplianceUs: false,
    displayName: '',
    formulaNumber: { label: '', value: '' },
    fragranceNote: '',
    imageId: '01',
    internalNotes: '',
    status: STATUS.DRAFT,
    keyBenefitBrightening: false,
    keyBenefitEyesReduceAppearanceOfDarkCircles: false,
    keyBenefitEyesReduceAppearanceOfFineLinesCrowsFeet: false,
    keyBenefitEyesReduceAppearanceOfPuffiness: false,
    keyBenefitFirming: false,
    keyBenefitHydration: false,
    keyBenefitMattifying: false,
    keyBenefitMoisturization: false,
    keyBenefitRadianceGlow: false,
    keyBenefitSelfTanner: false,
    keyBenefitSmoothing: false,
    keyBenefitTightening: false,
    masterBox: '',
    material: '',
    minimumOrderQuantity: '',
    opacity: '',
    otc: false,
    price: '',
    primaryComponent: '',
    productDescription: '',
    productType: '',
    productionLocation: '',
    regulatoryGlutenFree: false,
    regulatoryIfraCompliant: false,
    regulatoryNoAnimalDerivedIngredients: false,
    regulatoryNoAnimalTesting: false,
    regulatoryVegan: false,
    retailerClaimCyclicSiliconeFree: false,
    retailerClaimSephoraClean: false,
    retailerClaimTargetClean: false,
    retailerClaimUltaClean: false,
    rinseOffLeaveOn: '',
    fillSize: '',
    spf: '',
    structure: '',
    testingFinalComponent: false,
    testingHript: false,
    testingHriptWithDermatologistSignature: false,
    testingHriptWithHypoallergenicClaim: false,
    testingInVitro: false,
    testingOcularIrritation: false,
    testingPet: false,
    testingSpfDeterminationFda: false,
    testingSpfDeterminationIso: false,
    testingStability: false,
    testingWaterActivity: false,
    tinted: false,
    typeOfSystem: '',
    unit: '',
  });
  useEffect(() => {
    if (renderMode !== RENDER_MODE.CREATE) {
      getRtlCatalogueEntry({
        urlParams: uuid!,
        handleSuccess: (data) => {
          const { product } = data;
          const parsedData = {
            clinicalClaimAntiRedness:
              product.attributes.clinicalClaimAntiRedness,
            clinicalClaimBlueLightProtection:
              product.attributes.clinicalClaimBlueLightProtection,
            clinicalClaimGlutenFree: product.attributes.clinicalClaimGlutenFree,
            clinicalClaimHydration: product.attributes.clinicalClaimHydration,
            clinicalClaimHypoallergenic:
              product.attributes.clinicalClaimHypoallergenic,
            clinicalClaimNoAnimalDerivedIngredients:
              product.attributes.clinicalClaimNoAnimalDerivedIngredients,
            clinicalClaimNoAnimalTesting:
              product.attributes.clinicalClaimNoAnimalTesting,
            clinicalClaimNonOtcComment:
              product.attributes.clinicalClaimNonOtcComment,
            clinicalClaimSoothing: product.attributes.clincialClaimSoothing,
            clinicalClaimVegan: product.attributes.clinicalClaimVegan,
            closure: product.attributes.closure,
            colorNote: product.attributes.colorNote,
            countryComplianceCanada: product.attributes.countryComplianceCanada,
            countryComplianceEu: product.attributes.countryComplianceEu,
            countryComplianceNote: product.attributes.countryComplianceNote,
            countryComplianceOther: product.attributes.countryComplianceOther,
            countryComplianceUs: product.attributes.countryComplianceUs,
            displayName: data.attributes.displayName,
            formulaNumber: {
              label: data?.formula?.attributes.externalId,
              value: data?.formula?.id,
            },
            fragranceNote: product.attributes.fragranceNote,
            imageId: data.attributes.imageId,
            internalNotes: product.attributes.internalNotes,
            status: data.attributes.status,
            keyBenefitBrightening: product.attributes.keyBenefitBrightening,
            keyBenefitEyesReduceAppearanceOfDarkCircles:
              product.attributes.keyBenefitEyesReduceAppearanceOfDarkCircles,
            keyBenefitEyesReduceAppearanceOfFineLinesCrowsFeet:
              product.attributes
                .keyBenefitEyesReduceAppearanceOfFineLinesCrowsFeet,
            keyBenefitEyesReduceAppearanceOfPuffiness:
              product.attributes.keyBenefitEyesReduceAppearanceOfPuffiness,
            keyBenefitFirming: product.attributes.keyBenefitFirming,
            keyBenefitHydration: product.attributes.keyBenefitHydration,
            keyBenefitMattifying: product.attributes.keyBenefitMattifying,
            keyBenefitMoisturization:
              product.attributes.keyBenefitMoisturization,
            keyBenefitRadianceGlow: product.attributes.keyBenefitRadianceGlow,
            keyBenefitSelfTanner: product.attributes.keyBenefitSelfTanner,
            keyBenefitSmoothing: product.attributes.keyBenefitSmoothing,
            keyBenefitTightening: product.attributes.keyBenefitTightening,
            masterBox: product.attributes.masterBox,
            material: product.attributes.material,
            minimumOrderQuantity: data.attributes.minimumOrderQuantity,
            opacity: product.attributes.opacity,
            otc: product.attributes.otc,
            price: data.attributes.price,
            primaryComponent: product.attributes.primaryComponent,
            productDescription: data.attributes.productDescription,
            productType: product.attributes.productType,
            productionLocation: product.attributes.productionLocation,
            regulatoryGlutenFree: product.attributes.regulatoryGlutenFree,
            regulatoryIfraCompliant: product.attributes.regulatoryIfraCompliant,
            regulatoryNoAnimalDerivedIngredients:
              product.attributes.regulatoryNoAnimalDerivedIngredients,
            regulatoryNoAnimalTesting:
              product.attributes.regulatoryNoAnimalTesting,
            regulatoryVegan: product.attributes.regulatoryVegan,
            retailerClaimCyclicSiliconeFree:
              product.attributes.retailerClaimCyclicSiliconeFree,
            retailerClaimSephoraClean:
              product.attributes.retailerClaimSephoraClean,
            retailerClaimTargetClean:
              product.attributes.retailerClaimTargetClean,
            retailerClaimUltaClean: product.attributes.retailerClaimUltaClean,
            rinseOffLeaveOn: product.attributes.rinseOffLeaveOn,
            fillSize: product.attributes.fillSize,
            spf: product.attributes.spf,
            structure: product.attributes.structure,
            testingFinalComponent: product.attributes.testingFinalComponent,
            testingHript: product.attributes.testingHript,
            testingHriptWithDermatologistSignature:
              product.attributes.testingHriptWithDermatologistSignature,
            testingHriptWithHypoallergenicClaim:
              product.attributes.testingHriptWithHypoallergenicClaim,
            testingInVitro: product.attributes.testingInVitro,
            testingOcularIrritation: product.attributes.testingOcularIrritation,
            testingPet: product.attributes.testingPet,
            testingSpfDeterminationFda:
              product.attributes.testingSpfDeterminationFda,
            testingSpfDeterminationIso:
              product.attributes.testingSpfDeterminationIso,
            testingStability: product.attributes.testingStability,
            testingWaterActivity: product.attributes.testingWaterActivity,
            tinted: product.attributes.tinted,
            typeOfSystem: product.attributes.typeOfSystem,
            unit: product.attributes.unit,
          };
          setInitialValues(parsedData);
          setRtlCatalogueEntry(data);
        },
      });
    }
  }, [getRtlCatalogueEntry, uuid, renderMode]);

  const formik = useFormik<RtlCatalogueFormValues>({
    initialValues,
    enableReinitialize: true,
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema: Yup.object({
      displayName: Yup.string().required('Please enter a formula name'),
    }).shape({
      formulaNumber: Yup.object().when('status', {
        is: 'published',
        then: Yup.object().shape({
          label: Yup.string().required(
            'Formula selection is required to publish a formula'
          ),
          value: Yup.string().required(
            'Formula selection is required to publish a formula'
          ),
        }),
      }),
      price: Yup.string().when('status', {
        is: 'published',
        then: Yup.string().required(
          'Price per ounce is required to publish a formula'
        ),
      }),
      minimumOrderQuantity: Yup.string().when('status', {
        is: 'published',
        then: Yup.string().required('Please enter a minimum order quantity'),
      }),
    }),
    validate: (values) => {
      const errors: Partial<RtlCatalogueFormValues> = {};
      if (!values.displayName) {
        errors.displayName = 'Please enter a formula name';
      }

      if (Object.keys(errors).length > 0) {
        showAlertWithTimeout(
          {
            content: 'Please fix the validation errors before submitting.',
            severity: 'error',
          },
          5000
        );
      }
      return errors;
    },
    onSubmit: (values, { setSubmitting, setErrors }) => {
      const {
        clinicalClaimAntiRedness,
        clinicalClaimBlueLightProtection,
        clinicalClaimGlutenFree,
        clinicalClaimHydration,
        clinicalClaimHypoallergenic,
        clinicalClaimNoAnimalDerivedIngredients,
        clinicalClaimNoAnimalTesting,
        clinicalClaimNonOtcComment,
        clinicalClaimSoothing,
        clinicalClaimVegan,
        closure,
        colorNote,
        countryComplianceCanada,
        countryComplianceEu,
        countryComplianceNote,
        countryComplianceOther,
        countryComplianceUs,
        formulaNumber,
        fragranceNote,
        internalNotes,
        keyBenefitBrightening,
        keyBenefitEyesReduceAppearanceOfDarkCircles,
        keyBenefitEyesReduceAppearanceOfFineLinesCrowsFeet,
        keyBenefitEyesReduceAppearanceOfPuffiness,
        keyBenefitFirming,
        keyBenefitHydration,
        keyBenefitMattifying,
        keyBenefitMoisturization,
        keyBenefitRadianceGlow,
        keyBenefitSelfTanner,
        keyBenefitSmoothing,
        keyBenefitTightening,
        masterBox,
        material,
        minimumOrderQuantity,
        opacity,
        otc,
        price,
        primaryComponent,
        productDescription,
        productType,
        productionLocation,
        regulatoryGlutenFree,
        regulatoryIfraCompliant,
        regulatoryNoAnimalDerivedIngredients,
        regulatoryNoAnimalTesting,
        regulatoryVegan,
        retailerClaimCyclicSiliconeFree,
        retailerClaimSephoraClean,
        retailerClaimTargetClean,
        retailerClaimUltaClean,
        rinseOffLeaveOn,
        fillSize,
        spf,
        status,
        structure,
        testingFinalComponent,
        testingHript,
        testingHriptWithDermatologistSignature,
        testingHriptWithHypoallergenicClaim,
        testingInVitro,
        testingOcularIrritation,
        testingPet,
        testingSpfDeterminationFda,
        testingSpfDeterminationIso,
        testingStability,
        testingWaterActivity,
        tinted,
        typeOfSystem,
        unit,
      } = values;
      const payload = {
        rtlCatalogueEntry: {
          imageId: values.imageId,
          displayName: values.displayName,
          formulaUuid: formulaNumber.value,
          minimumOrderQuantity,
          price,
          productDescription,
          status,
          productAttributes: {
            productCategory,
            productType,
            clinicalClaimAntiRedness,
            clinicalClaimBlueLightProtection,
            clinicalClaimGlutenFree,
            clinicalClaimHydration,
            clinicalClaimHypoallergenic,
            clinicalClaimNoAnimalDerivedIngredients,
            clinicalClaimNoAnimalTesting,
            clinicalClaimNonOtcComment,
            clinicalClaimSoothing,
            clinicalClaimVegan,
            closure,
            colorNote,
            countryComplianceCanada,
            countryComplianceEu,
            countryComplianceNote,
            countryComplianceOther,
            countryComplianceUs,
            fragranceNote,
            internalNotes,
            keyBenefitBrightening,
            keyBenefitEyesReduceAppearanceOfDarkCircles,
            keyBenefitEyesReduceAppearanceOfFineLinesCrowsFeet,
            keyBenefitEyesReduceAppearanceOfPuffiness,
            keyBenefitFirming,
            keyBenefitHydration,
            keyBenefitMattifying,
            keyBenefitMoisturization,
            keyBenefitRadianceGlow,
            keyBenefitSelfTanner,
            keyBenefitSmoothing,
            keyBenefitTightening,
            masterBox,
            material,
            opacity,
            otc,
            primaryComponent,
            productionLocation,
            regulatoryGlutenFree,
            regulatoryIfraCompliant,
            regulatoryNoAnimalDerivedIngredients,
            regulatoryNoAnimalTesting,
            regulatoryVegan,
            retailerClaimCyclicSiliconeFree,
            retailerClaimSephoraClean,
            retailerClaimTargetClean,
            retailerClaimUltaClean,
            rinseOffLeaveOn,
            fillSize,
            spf,
            structure,
            testingFinalComponent,
            testingHript,
            testingHriptWithDermatologistSignature,
            testingHriptWithHypoallergenicClaim,
            testingInVitro,
            testingOcularIrritation,
            testingPet,
            testingSpfDeterminationFda,
            testingSpfDeterminationIso,
            testingStability,
            testingWaterActivity,
            tinted,
            typeOfSystem,
            unit,
          },
        },
      };
      renderMode === RENDER_MODE.CREATE
        ? postRtlCatalogueEntry({
            data: payload,
            handleSuccess: () => {
              navigate(ROUTES.RTL_FORMULA_CATALOGUE.route);
              showAlertWithTimeout({
                content: 'RTL catalogue entry created successfully',
                severity: 'success',
              });
            },
          })
        : patchRtlCatalogueEntry({
            urlParams: rtlCatalogueEntry?.id,
            data: payload,
            handleSuccess: () => {
              showAlertWithTimeout({
                content: 'RTL catalogue entry updated successfully',
                severity: 'success',
              });
              setRenderMode(RENDER_MODE.VIEW);
            },
          });
    },
  });

  const { values, getFieldProps, setFieldValue } = formik;

  const formula = rtlCatalogueEntry?.formula;

  const setTypedFieldValue = <T extends keyof RtlCatalogueFormValues>(
    field: T,
    value: RtlCatalogueFormValues[T]
  ) => {
    formik.setFieldValue(field, value);
  };

  const handleAddToCart = () => {
    showAlertWithTimeout({
      content: <span>Item added to cart</span>,
      severity: 'success',
    });
    const cartItem = {
      id: uuid!,
      imageId: values.imageId,
      quantity: numOfSamples,
      formulaUuid: formula!.id,
      price: 100 * numOfSamples,
      displayName: values.displayName,
      structure: values.structure,
      primaryContainer: values.primaryComponent,
    };
    addItem(cartItem);
    setNumOfSamples(1);
  };

  const handleDelete = () => {
    deleteRtlCatalogueEntry({
      urlParams: rtlCatalogueEntry?.id,
      handleSuccess: () => {
        showAlertWithTimeout({
          content: 'RTL catalogue entry deleted successfully',
          severity: 'success',
        });
        navigate(ROUTES.RTL_FORMULA_CATALOGUE.route);
      },
    });
  };

  const renderFormulaImages = () => {
    return (
      <div className="relative min-w-[360px] lg:min-w-[406px]">
        <div
          className={`sm:sticky ${
            renderMode === RENDER_MODE.VIEW ? 'sm:top-16' : 'sm:top-6'
          } flex flex-col gap-6`}
        >
          <img
            src={
              RTL_CATALOGUE_IMAGES.find((image) =>
                renderMode !== RENDER_MODE.VIEW
                  ? image.id === values.imageId
                  : image.id === rtlCatalogueEntry?.attributes.imageId
              )?.src || RTL_CATALOGUE_IMAGES[0].src
            }
            alt="selected formula"
            className="h-[406px] w-[406px] rounded object-cover lg:object-fill"
          />
          {renderMode !== RENDER_MODE.VIEW ? (
            <div className="flex flex-wrap mx-3 justify-between gap-y-[16px] mt-4 max-w-[406px]">
              {RTL_CATALOGUE_IMAGES.map((image, index) => (
                <img
                  alt={`formula ${index}`}
                  className={`h-[83px] w-[83px] box-border cursor-pointer border-4  ${
                    values.imageId === image.id
                      ? 'border-royal-50'
                      : 'border-white'
                  }`}
                  key={index}
                  onClick={() => setFieldValue('imageId', image.id)}
                  src={image.src}
                />
              ))}
            </div>
          ) : (
            <div className="flex flex-col sm:flex-row gap-6">
              <IncrementerDecrementer
                value={numOfSamples}
                setValue={setNumOfSamples}
              />
              <Button
                disabled={isDraft}
                onClick={handleAddToCart}
                size="large"
                type="primary"
                text={isDraft ? 'Publish to Add to Cart' : 'Add to Cart'}
                width="w-full"
              />
            </div>
          )}
        </div>
      </div>
    );
  };

  const renderFormSection = (section: any) => {
    return (
      <CollapsibleSection key={section.title} title={section.title}>
        <div className="flex flex-col px-4 border border-grey-90 rounded">
          {section.items.map((item: any, index: number) => (
            <div key={index}>{item}</div>
          ))}
        </div>
      </CollapsibleSection>
    );
  };

  const renderYesNoChip = (value: boolean) => {
    return (
      <Chip
        bgColor={value ? 'bg-green-95' : 'bg-red-95'}
        borderColor={value ? 'border-green-80' : 'border-red-80'}
        label={value ? 'Yes' : 'No'}
        textColor={value ? 'text-green-40' : 'text-red-40'}
        size="small"
        value={value ? 'yes' : 'no'}
      />
    );
  };

  interface RenderFormRowProps {
    label: string;
    input: JSX.Element;
    value?: any;
    isLast?: boolean;
  }

  const renderFormRow = ({
    label,
    input,
    value,
    isLast,
  }: RenderFormRowProps): JSX.Element => {
    return renderMode === RENDER_MODE.EDIT ||
      renderMode === RENDER_MODE.CREATE ? (
      <div
        key={label}
        className={`flex gap-6 items-center py-[6px] ${
          isLast ? '' : 'border-b border-grey-90'
        }`}
      >
        <span className="w-1/2 text-grey-50">{label}</span>
        <div className="w-1/2 flex justify-end">{input}</div>
      </div>
    ) : (
      <div
        className={`flex justify-between py-4 ${
          isLast ? '' : 'border-b border-grey-90'
        }`}
      >
        <span className="text-grey-50">{label}</span>
        {value || 'N/A'}
      </div>
    );
  };

  const renderSwitch = (key: keyof RtlCatalogueFormValues) => {
    return (
      <div className="flex py-[4px]">
        <Switch
          size="lg"
          isChecked={Boolean(values[key])}
          handleChange={() => setTypedFieldValue(key, !values[key])}
        />
      </div>
    );
  };

  const renderDraftAlert = () => {
    return (
      <Alert
        isBanner
        content="It will not be visible to PML customers until all fields are completed and the formula is published."
        headline="This entry is a draft"
        severity="error"
      />
    );
  };

  const renderBasicInfoSection = () => {
    return renderMode === RENDER_MODE.VIEW ? (
      <>
        {isDraft && renderDraftAlert()}
        <div className="flex flex-col gap-9 sm:gap-0 sm:flex-row justify-between sm:items-center">
          <span className="text-xl text-grey-20 font-semibold">
            {values.displayName}
          </span>
          <Button
            leadingIcon={<PencilSquareIcon className="w-6 h-6" />}
            onClick={() => setRenderMode(RENDER_MODE.EDIT)}
            size="large"
            type="soft"
            text="Edit"
          />
        </div>
        <span>{values.productDescription}</span>
        <div className="flex gap-2 items-center">
          <span className="text-lg font-semibold text-grey-20">
            {USDollar.format(Number(values.price))}
          </span>
          <span className="text-grey-50 text-xs">per ounce</span>
          <Tooltip title="Unit cost is subject to change based on order quantity, updated materials prices, etc.">
            <InformationCircleIcon className="cursor-pointer w-4 h-4 text-grey-50" />
          </Tooltip>
        </div>
      </>
    ) : (
      <>
        <TextField
          error={formik.errors.displayName}
          label="RTL Catalogue Formula Name (Required)"
          width={'w-[357px]'}
          placeholder="Enter a formula name"
          {...getFieldProps('displayName')}
        />
        <TextArea
          label="Formula Description"
          rows={6}
          placeholder="Describe this formula"
          {...getFieldProps('productDescription')}
        />
        <TextField
          error={formik.errors.price}
          label="Price per ounce (Required to publish)"
          placeholder="00.00"
          leadingText="$"
          trailingText="per ounce"
          helperText="Enter the closest current estimate."
          {...getFieldProps('price')}
        />
      </>
    );
  };

  const renderBottomBar = () => {
    if (renderMode === RENDER_MODE.VIEW) return;

    return (
      <BottomBar
        additionalStyles={'fixed bottom-0 w-full left-0'}
        buttons={[
          <Button
            size="large"
            text="Cancel"
            type="secondary"
            onClick={() => {
              renderMode !== RENDER_MODE.EDIT
                ? navigate(ROUTES.RTL_FORMULA_CATALOGUE.route)
                : setRenderMode(RENDER_MODE.VIEW);
            }}
          />,
          <Button
            leadingIcon={<DocumentPlusIcon className="text-royal-50" />}
            onClick={() =>
              isPublished
                ? formik.handleSubmit()
                : setShowSaveAsDraftModal(true)
            }
            size="large"
            text={isPublished ? 'Save' : 'Save Draft'}
            type="secondary"
          />,
          !isPublished && (
            <Button
              size="large"
              text="Publish"
              onClick={() => {
                setFieldValue('status', 'published', false).then(() => {
                  formik.handleSubmit();
                });
              }}
            />
          ),
        ]}
      />
    );
  };

  const formItems: FormItems = {
    formulaDetails: {
      title: 'Formula Details',
      items: [
        renderFormRow({
          label: 'Formula Number',
          input: (
            <Autocomplete
              error={formik.errors.formulaNumber?.value}
              onChange={(option: Option) => {
                setTypedFieldValue('formulaNumber', option);
              }}
              initialValue={values.formulaNumber.label}
              placeholder="Select a formula (Required to publish)"
              responseParser={(response) =>
                response?.results.map((result: any) => ({
                  label: result._source.external_id,
                  value: result._source.uuid,
                  additionalData: {
                    name: result._source.name,
                    updatedAt: result._source.updated_at,
                  },
                }))
              }
              search={searchFormulas}
              urlParams={'&filters=external_id'}
              width="w-full"
              customMenu="searchFormulaNumber"
            />
          ),
          value: (
            <Link
              to={ROUTES.SHOW_FORMULA.route.replace(
                UUID_SHOW_ROUTE_STRING,
                formula?.id
              )}
              target="_blank"
              rel="noopener noreferrer"
              className="text-royal-50 cursor-pointer"
            >
              {values.formulaNumber.label}
            </Link>
          ),
        }),
        renderFormRow({
          label: 'MOQ',
          input: (
            <TextField
              {...getFieldProps('minimumOrderQuantity')}
              error={formik.errors.minimumOrderQuantity}
              helperText={formik.errors.minimumOrderQuantity}
            />
          ),
          value: values.minimumOrderQuantity,
        }),
        <div className="flex justify-between py-4 border-b border-grey-90">
          <span className="text-grey-50">Product Category</span>
          {productCategory || ''}
        </div>,
        renderFormRow({
          label: 'Product Type',
          input: (
            <Select
              placeholder="Types"
              options={
                PRODUCT_OPTIONS.find(
                  (option) => option.value === productCategory
                )?.productTypes.map((type) => ({
                  label: type.label,
                  value: type.value,
                })) || []
              }
              onChange={(option: Option) => {
                setTypedFieldValue('productType', option.value);
              }}
              value={values.productType}
              width="w-full"
            />
          ),
          value: values.productType,
        }),
        renderFormRow({
          label: 'Form/Structure',
          input: (
            <Select
              placeholder="Structures"
              options={
                Object.values(STRUCTURES).map((structure) => {
                  return { label: structure, value: structure };
                }) || []
              }
              onChange={(option: Option) =>
                setTypedFieldValue('structure', option.value)
              }
              value={values.structure}
              width="w-full"
            />
          ),
          value: values.structure,
        }),
        renderFormRow({
          label: 'Opacity',
          input: (
            <Select
              options={Object.values(OPACITIES).map((opacity) => {
                return { label: opacity, value: opacity };
              })}
              onChange={(option: Option) =>
                setTypedFieldValue('opacity', option.value)
              }
              placeholder="Select a format"
              value={values.opacity}
              width="w-full"
            />
          ),
          value: values.opacity,
        }),
        renderFormRow({
          label: 'Color Notes',
          input: <TextField {...getFieldProps('colorNote')} />,
          value: values.colorNote,
        }),
        renderFormRow({
          label: 'Fragrance Notes',
          input: <TextField {...getFieldProps('fragranceNote')} />,
          value: values.fragranceNote,
          isLast: true,
        }),
      ],
    },
    sunCareSpecifics: {
      title: 'Sun Care Specifics',
      items: [
        renderFormRow({
          label: 'SPF Type',
          input: <TextField {...getFieldProps('spf')} />,
          value: values.spf,
        }),
        renderFormRow({
          label: 'Type of System',
          input: (
            <Select
              onChange={(option: Option) =>
                setTypedFieldValue('typeOfSystem', option.value)
              }
              options={TYPE_OF_SYSTEM.map((type) => ({
                label: type.label,
                value: type.value,
              }))}
              value={values.typeOfSystem}
              width="w-full"
            />
          ),
          value: values.typeOfSystem,
        }),
        renderFormRow({
          label: 'Tinted',
          input: renderSwitch('tinted'),
          value: renderYesNoChip(values.tinted),
          isLast: true,
        }),
      ],
    },
    sunCareKeyBenefits: {
      title: 'Sun Care Key Benefits',
      items: [],
    },
    skinCareSpecifics: {
      title: 'Skin Care Specifics',
      items: [
        renderFormRow({
          label: 'Rinse Off / Leave On',
          input: (
            <Select
              onChange={(option: Option) =>
                setTypedFieldValue('rinseOffLeaveOn', option.value)
              }
              options={[
                { label: 'Rinse Off', value: 'Rinse Off' },
                { label: 'Leave On', value: 'Leave On' },
              ]}
              value={values.rinseOffLeaveOn}
              width="w-full"
            />
          ),
          value: values.rinseOffLeaveOn,
        }),
        renderFormRow({
          label: 'Type of System',
          input: (
            <Select
              onChange={(option: Option) =>
                setTypedFieldValue('typeOfSystem', option.value)
              }
              options={TYPE_OF_SYSTEM.map((type) => ({
                label: type.label,
                value: type.value,
              }))}
              value={values.typeOfSystem}
              width="w-full"
            />
          ),
          value: values.typeOfSystem,
          isLast: true,
        }),
      ],
    },
    skinCareKeyBenefits: {
      title: 'Skin Care Key Benefits',
      items: [
        renderFormRow({
          label: 'Brightening',
          input: renderSwitch('keyBenefitBrightening'),
          value: renderYesNoChip(values.keyBenefitBrightening),
        }),
        renderFormRow({
          label: 'Firming',
          input: renderSwitch('keyBenefitFirming'),
          value: renderYesNoChip(values.keyBenefitFirming),
        }),
        renderFormRow({
          label: 'Hydration',
          input: renderSwitch('keyBenefitHydration'),
          value: renderYesNoChip(values.keyBenefitHydration),
        }),
        renderFormRow({
          label: 'Mattifying',
          input: renderSwitch('keyBenefitMattifying'),
          value: renderYesNoChip(values.keyBenefitMattifying),
        }),
        renderFormRow({
          label: 'Moisturization',
          input: renderSwitch('keyBenefitMoisturization'),
          value: renderYesNoChip(values.keyBenefitMoisturization),
        }),
        renderFormRow({
          label: 'Radiance/Glow',
          input: renderSwitch('keyBenefitRadianceGlow'),
          value: renderYesNoChip(values.keyBenefitRadianceGlow),
        }),
        renderFormRow({
          label: 'Self Tanner',
          input: renderSwitch('keyBenefitSelfTanner'),
          value: renderYesNoChip(values.keyBenefitSelfTanner),
        }),
        renderFormRow({
          label: 'Smoothing',
          input: renderSwitch('keyBenefitSmoothing'),
          value: renderYesNoChip(values.keyBenefitSmoothing),
        }),
        renderFormRow({
          label: 'Tightening',
          input: renderSwitch('keyBenefitTightening'),
          value: renderYesNoChip(values.keyBenefitTightening),
        }),
        renderFormRow({
          label: 'Eyes: Reduce appearance of dark circles',
          input: renderSwitch('keyBenefitEyesReduceAppearanceOfDarkCircles'),
          value: renderYesNoChip(
            values.keyBenefitEyesReduceAppearanceOfDarkCircles
          ),
        }),
        renderFormRow({
          label: 'Eyes: Reduce appearance of fine lines/crows feet',
          input: renderSwitch(
            'keyBenefitEyesReduceAppearanceOfFineLinesCrowsFeet'
          ),
          value: renderYesNoChip(
            values.keyBenefitEyesReduceAppearanceOfFineLinesCrowsFeet
          ),
        }),
        renderFormRow({
          label: 'Eyes: Reduce appearance of puffiness',
          input: renderSwitch('keyBenefitEyesReduceAppearanceOfPuffiness'),
          value: renderYesNoChip(
            values.keyBenefitEyesReduceAppearanceOfPuffiness
          ),
          isLast: true,
        }),
      ],
    },
    packaging: {
      title: 'Packaging',
      items: [
        <div
          className={`flex gap-6 items-center py-[6px] ${'border-b border-grey-90'}`}
        >
          <span className="w-1/2 text-grey-50">Minimum Fill Size</span>
          <div className="flex w-1/2 gap-6 justify-between">
            {renderMode !== RENDER_MODE.VIEW ? (
              <>
                <TextField
                  placeholder="Quantity"
                  {...getFieldProps('fillSize')}
                />
                <Select
                  placeholder="Units"
                  onChange={(option: Option) =>
                    setTypedFieldValue('unit', option.value)
                  }
                  options={Object.values(UNITS).map((unit) => ({
                    label: unit,
                    value: unit,
                  }))}
                  value={values.unit}
                  width="w-full"
                />
              </>
            ) : (
              <span className="ml-auto py-[6px]">
                {values.fillSize && values.unit
                  ? `${values.fillSize} ${values.unit}`
                  : 'N/A'}
              </span>
            )}
          </div>
        </div>,
        renderFormRow({
          label: 'Primary Component',
          input: (
            <Select
              onChange={(option: Option) =>
                setTypedFieldValue('primaryComponent', option.value)
              }
              options={primaryComponentOptions}
              placeholder="Select"
              value={values.primaryComponent}
              width="w-full"
            />
          ),
          value: primaryComponentOptions.find(
            (option) => option.value === values.primaryComponent
          )?.label,
        }),
        renderFormRow({
          label: 'Material',
          input: (
            <Select
              onChange={(option: Option) =>
                setTypedFieldValue('material', option.value)
              }
              options={Object.values(MATERIAL_MENU_ITEMS).map(
                (material) =>
                  ({
                    label: material.label,
                    value: material.value,
                  } as Option)
              )}
              placeholder="Select"
              value={values.material}
              width="w-full"
            />
          ),
          value: MATERIAL_MENU_ITEMS[values.material]?.label,
        }),
        renderFormRow({
          label: 'Closure',
          input: (
            <Select
              onChange={(option: Option) =>
                setTypedFieldValue('closure', option.value)
              }
              options={Object.values(CLOSURE_MENU_ITEMS).map(
                (closure) =>
                  ({
                    label: closure.label,
                    value: closure.value,
                  } as Option)
              )}
              placeholder="Select"
              value={values.closure}
              width="w-full"
            />
          ),
          value: CLOSURE_MENU_ITEMS[values.closure]?.label,
        }),
        renderFormRow({
          label: 'Master Box',
          input: (
            <Select
              onChange={(option: Option) =>
                setFieldValue('masterBox', option.value)
              }
              options={MASTER_BOX_OPTIONS}
              placeholder="Select"
              value={values.masterBox}
              width="w-full"
            />
          ),
          value: MASTER_BOX_OPTIONS.find(
            (option) => option.value === values.masterBox
          )?.label,
          isLast: true,
        }),
      ],
    },
    otc: {
      title: 'OTC',
      items: [
        renderFormRow({
          label: 'OTC',
          input: renderSwitch('otc'),
          value: renderYesNoChip(values.otc),
          isLast: true,
        }),
      ],
    },
    retailerClaims: {
      title: 'Retailer Claims',
      items: [
        renderFormRow({
          label: 'Ulta Clean',
          input: renderSwitch('retailerClaimUltaClean'),
          value: renderYesNoChip(values.retailerClaimUltaClean),
        }),
        renderFormRow({
          label: 'Sephora',
          input: renderSwitch('retailerClaimSephoraClean'),
          value: renderYesNoChip(values.retailerClaimSephoraClean),
        }),
        renderFormRow({
          label: 'Target Clean',
          input: renderSwitch('retailerClaimTargetClean'),
          value: renderYesNoChip(values.retailerClaimTargetClean),
        }),
        renderFormRow({
          label: 'Cyclic Silicone Free',
          input: renderSwitch('retailerClaimCyclicSiliconeFree'),
          value: renderYesNoChip(values.retailerClaimCyclicSiliconeFree),
          isLast: true,
        }),
      ],
    },
    clinicalDataClaims: {
      title: 'Clinical/Data Claims',
      items: [
        renderFormRow({
          label: 'Anti-Redness',
          input: renderSwitch('clinicalClaimAntiRedness'),
          value: renderYesNoChip(values.clinicalClaimAntiRedness),
        }),
        renderFormRow({
          label: 'Blue Light Protection',
          input: renderSwitch('clinicalClaimBlueLightProtection'),
          value: renderYesNoChip(values.clinicalClaimBlueLightProtection),
        }),
        renderFormRow({
          label: 'Gluten Free',
          input: renderSwitch('clinicalClaimGlutenFree'),
          value: renderYesNoChip(values.clinicalClaimGlutenFree),
        }),
        renderFormRow({
          label: 'Hydration (immediate and/or longterm)',
          input: renderSwitch('clinicalClaimHydration'),
          value: renderYesNoChip(values.clinicalClaimHydration),
        }),
        renderFormRow({
          label: 'Hypoallergenic',
          input: renderSwitch('clinicalClaimHypoallergenic'),
          value: renderYesNoChip(values.clinicalClaimHypoallergenic),
        }),
        renderFormRow({
          label: 'No Animal Derived Ingredients',
          input: renderSwitch('clinicalClaimNoAnimalDerivedIngredients'),
          value: renderYesNoChip(
            values.clinicalClaimNoAnimalDerivedIngredients
          ),
        }),
        renderFormRow({
          label: 'No Animal Testing',
          input: renderSwitch('clinicalClaimNoAnimalTesting'),
          value: renderYesNoChip(values.clinicalClaimNoAnimalTesting),
        }),
        renderFormRow({
          label: 'Soothing',
          input: renderSwitch('clinicalClaimSoothing'),
          value: renderYesNoChip(values.clinicalClaimSoothing),
        }),
        renderFormRow({
          label: 'Vegan',
          input: renderSwitch('clinicalClaimVegan'),
          value: renderYesNoChip(values.clinicalClaimVegan),
        }),
        renderFormRow({
          label: 'Non-OTC Claim Comments',
          input: (
            <TextArea
              {...getFieldProps('clinicalClaimNonOtcComment')}
              placeholder="Enter a comment"
              width="w-full"
            />
          ),
          value: values.clinicalClaimNonOtcComment,
          isLast: true,
        }),
      ],
    },
    countryCompliance: {
      title: 'Country Compliance',
      items: [
        renderFormRow({
          label: 'US',
          input: renderSwitch('countryComplianceUs'),
          value: renderYesNoChip(values.countryComplianceUs),
        }),
        renderFormRow({
          label: 'Canada',
          input: renderSwitch('countryComplianceCanada'),
          value: renderYesNoChip(values.countryComplianceCanada),
        }),
        renderFormRow({
          label: 'EU',
          input: renderSwitch('countryComplianceEu'),
          value: renderYesNoChip(values.countryComplianceEu),
        }),
        renderFormRow({
          label: 'Other',
          input: renderSwitch('countryComplianceOther'),
          value: renderYesNoChip(values.countryComplianceOther),
        }),
        renderFormRow({
          label: 'Notes',
          input: <TextField {...getFieldProps('countryComplianceNote')} />,
          value: values.countryComplianceNote,
          isLast: true,
        }),
      ],
    },
    testing: {
      title: 'Testing',
      items: [
        renderFormRow({
          label: 'In Vitro FDA Critical Wavelength (Broad Spectrum)',
          input: renderSwitch('testingInVitro'),
          value: renderYesNoChip(values.testingInVitro),
        }),
        renderFormRow({
          label: 'Compatibility (Final Component)',
          input: renderSwitch('testingFinalComponent'),
          value: renderYesNoChip(values.testingFinalComponent),
        }),
        renderFormRow({
          label: 'Human Repeat Insult Patch Test (HRIPT)',
          input: renderSwitch('testingHript'),
          value: renderYesNoChip(values.testingHript),
        }),
        renderFormRow({
          label: 'Human Repeat Insult Patch Test with Dermatologist Signature',
          input: renderSwitch('testingHriptWithDermatologistSignature'),
          value: renderYesNoChip(values.testingHriptWithDermatologistSignature),
        }),
        renderFormRow({
          label: 'Human Repeat Insult Patch Test with Hypoallergenic Claim',
          input: renderSwitch('testingHriptWithHypoallergenicClaim'),
          value: renderYesNoChip(values.testingHriptWithHypoallergenicClaim),
        }),
        renderFormRow({
          label: 'Ocular Irritation',
          input: renderSwitch('testingOcularIrritation'),
          value: renderYesNoChip(values.testingOcularIrritation),
        }),
        renderFormRow({
          label: 'Preservative Efficacy Test (PET)',
          input: renderSwitch('testingPet'),
          value: renderYesNoChip(values.testingPet),
        }),
        renderFormRow({
          label: 'SPF Determination: FDA',
          input: renderSwitch('testingSpfDeterminationFda'),
          value: renderYesNoChip(values.testingSpfDeterminationFda),
        }),
        renderFormRow({
          label: 'SPF Determination: ISO',
          input: renderSwitch('testingSpfDeterminationIso'),
          value: renderYesNoChip(values.testingSpfDeterminationIso),
        }),
        renderFormRow({
          label: 'Stability (FT, 4C, 40C)',
          input: renderSwitch('testingStability'),
          value: renderYesNoChip(values.testingStability),
        }),
        renderFormRow({
          label: 'Water Activity (Anhydrous)',
          input: renderSwitch('testingWaterActivity'),
          value: renderYesNoChip(values.testingWaterActivity),
          isLast: true,
        }),
      ],
    },
    regulatory: {
      title: 'Regulatory',
      items: [
        renderFormRow({
          label: 'Vegan Ingredients',
          input: renderSwitch('regulatoryVegan'),
          value: renderYesNoChip(values.regulatoryVegan),
        }),
        renderFormRow({
          label: 'Gluten Free Ingredients',
          input: renderSwitch('regulatoryGlutenFree'),
          value: renderYesNoChip(values.regulatoryGlutenFree),
        }),
        renderFormRow({
          label: 'No Animal Derived Ingredients',
          input: renderSwitch('regulatoryNoAnimalDerivedIngredients'),
          value: renderYesNoChip(values.regulatoryNoAnimalDerivedIngredients),
        }),
        renderFormRow({
          label: 'No Animal Testing',
          input: renderSwitch('regulatoryNoAnimalTesting'),
          value: renderYesNoChip(values.regulatoryNoAnimalTesting),
        }),
        renderFormRow({
          label: 'IFRA Compliance (International Fragrance Association)',
          input: renderSwitch('regulatoryIfraCompliant'),
          value: renderYesNoChip(values.regulatoryIfraCompliant),
          isLast: true,
        }),
      ],
    },
    internal: {
      title: 'Internal',
      items: [
        renderFormRow({
          label: 'Production Location',
          input: (
            <Select
              placeholder="Select"
              options={[
                { label: 'Miami', value: 'Miami' },
                { label: 'Los Angeles', value: 'Los Angeles' },
              ]}
              onChange={(option: Option) =>
                setTypedFieldValue('productionLocation', option.value)
              }
              value={values.productionLocation}
              width="w-full"
            />
          ),
          value: values.productionLocation,
        }),
        renderFormRow({
          label: 'PML Notes',
          input: (
            <TextArea {...getFieldProps('internalNotes')} width="w-full" />
          ),
          value: values.internalNotes,
          isLast: true,
        }),
      ],
    },
  };

  return (
    <>
      <Page>
        {renderMode === RENDER_MODE.VIEW &&
          (isMobile ? (
            <RtlFormulaCatalogueTopNav />
          ) : (
            <RtlFormulaCatalogueTopNav
              breadcrumb={{
                productCategory,
                productName: rtlCatalogueEntry?.attributes.displayName,
              }}
            />
          ))}
        <ShoppingCart />
        <div className="sm:col-span-12 col-span-2 flex flex-col sm:flex-row lg:gap-6 mt-16 mb-[200px] max-w-[1440px] font-inter text-grey-20 mx-auto w-full">
          {isMobile && (
            <div className="mb-6">
              <RtlFormulaCatalogueBreadcrumbs
                breadcrumb={{
                  productCategory,
                  productName: rtlCatalogueEntry?.attributes.displayName,
                }}
              />
            </div>
          )}
          {renderFormulaImages()}
          <div className="flex flex-col w-full gap-9 sm:px-6 mt-12 sm:mt-0">
            {renderBasicInfoSection()}
            {renderFormSection(formItems['formulaDetails'])}
            {productCategory === PRODUCT_CATEGORIES.SUN_CARE &&
              renderFormSection(formItems['sunCareSpecifics'])}
            {productCategory === PRODUCT_CATEGORIES.SUN_CARE &&
              renderFormSection(formItems['sunCareKeyBenefits'])}
            {productCategory === PRODUCT_CATEGORIES.SKIN_CARE &&
              renderFormSection(formItems['skinCareSpecifics'])}
            {productCategory === PRODUCT_CATEGORIES.SKIN_CARE &&
              renderFormSection(formItems['skinCareKeyBenefits'])}
            {renderFormSection(formItems['packaging'])}
            {renderFormSection(formItems['otc'])}
            {renderFormSection(formItems['retailerClaims'])}
            {renderFormSection(formItems['clinicalDataClaims'])}
            {renderFormSection(formItems['countryCompliance'])}
            {renderFormSection(formItems['testing'])}
            {renderFormSection(formItems['regulatory'])}
            {renderFormSection(formItems['internal'])}
            {renderMode === RENDER_MODE.EDIT && (
              <Button
                leadingIcon={<TrashIcon className="h-6 w-6" />}
                size="large"
                text="Delete this RTL Formula"
                type="urgentSecondary"
                onClick={() => setShowDeleteModal(true)}
              />
            )}
          </div>
        </div>
        {showDeleteModal && (
          <Modal handleClose={() => setShowDeleteModal(false)}>
            <div className="flex flex-col">
              <div className="flex flex-col gap-3 p-6">
                <span className="font-semibold">Are you sure?</span>
                <span>Deleting this RTL Formula listing cannot be undone.</span>
              </div>
              <div className="flex gap-4 p-4 border-t border-grey-90 justify-end">
                <Button
                  size="large"
                  type="secondary"
                  text="Nevermind"
                  onClick={() => setShowDeleteModal(false)}
                />
                <Button
                  type="urgentSecondary"
                  size="large"
                  text="Yes, delete"
                  onClick={() => handleDelete()}
                />
              </div>
            </div>
          </Modal>
        )}
        {showSaveAsDraftModal && (
          <Modal handleClose={() => setShowSaveAsDraftModal(false)}>
            <div className="flex flex-col">
              <div className="flex flex-col gap-3 p-6">
                <span className="font-semibold">Save as draft?</span>
                <span>
                  Drafts are visible to PML employees, but cannot be seen by
                  customers
                </span>
                <span className="text-red-50 text-xs">
                  {formik.errors.displayName}
                </span>
              </div>
              <div className="flex gap-4 p-4 border-t border-grey-90 justify-end">
                <Button
                  size="large"
                  type="secondary"
                  text="Nevermind"
                  onClick={() => setShowSaveAsDraftModal(false)}
                />
                <Button
                  size="large"
                  text="Save Draft"
                  onClick={() =>
                    setFieldValue('status', STATUS.DRAFT, false).then(() => {
                      formik.handleSubmit();
                      setShowSaveAsDraftModal(false);
                    })
                  }
                />
              </div>
            </div>
          </Modal>
        )}
        {renderBottomBar()}
      </Page>
    </>
  );
};
