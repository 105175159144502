// Libraries
import React, { useContext, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useFormikContext } from 'formik';
import { Alert, Checkbox, Modal } from 'design-system';
// Components
import {
  ComponentsSection,
  FormulaSection,
  LaborSection,
  MarginSection,
  OrderQuantitySection,
  NotesSection,
} from './worksheet-sections';
import { CostSummary } from './cost-summary.component';
import { FillSizeSelector } from './fill-size-selector.component';
import { WorksheetInfo } from './worksheet-info.component';
import { SectionSummaryRow } from './section-summary-row.component';
import { FillSizeModal } from './fill-size-modal.component';
// Utils
import { QuoteCalculator } from '../quote-calculator/quote-calculator.component';
import { SIZES, WorksheetFormValues } from './worksheet.types';
import { USDollar } from 'features/utils';
import {
  FeedbackAttributes,
  FeedbackStatus,
  FormulaAttributes,
  IApiData,
  ProjectAttributes,
  WorksheetAttributes,
  WorksheetIngredientAttributes,
} from 'api';
import { extractApprovals } from '../utils';
// Utils
import { UserContext } from 'context';
// Constants
import { SECTIONS, WORKSHEET_FORM_KEYS, orderQuantityData } from './constants';
import { UNITS } from 'features/types';
import { PERMISSIONS } from 'features/rbac';

const SECTION_PARAM = 'section';

const {
  ADDITIONAL_COMPONENT_ONE_A,
  ADDITIONAL_COMPONENT_ONE_B,
  ADDITIONAL_COMPONENT_ONE_C,
  ADDITIONAL_COMPONENT_THREE_A,
  ADDITIONAL_COMPONENT_THREE_B,
  ADDITIONAL_COMPONENT_THREE_C,
  ADDITIONAL_COMPONENT_TWO_A,
  ADDITIONAL_COMPONENT_TWO_B,
  ADDITIONAL_COMPONENT_TWO_C,
  BATCHING_HOURS_ONE,
  BATCHING_HOURS_THREE,
  BATCHING_HOURS_TWO,
  COMPOUNDING_HEAD_COUNT_ONE,
  COMPOUNDING_HEAD_COUNT_THREE,
  COMPOUNDING_HEAD_COUNT_TWO,
  COMPOUNDING_PAY_RATE_ONE,
  COMPOUNDING_PAY_RATE_THREE,
  COMPOUNDING_PAY_RATE_TWO,
  EFFICIENCY_PERCENTAGE_ONE,
  EFFICIENCY_PERCENTAGE_THREE,
  EFFICIENCY_PERCENTAGE_TWO,
  FILLING_HEAD_COUNT_ONE,
  FILLING_HEAD_COUNT_THREE,
  FILLING_HEAD_COUNT_TWO,
  FILLING_PAY_RATE_ONE,
  FILLING_PAY_RATE_THREE,
  FILLING_PAY_RATE_TWO,
  INGREDIENTS,
  MASTER_BOX_ONE,
  MASTER_BOX_THREE,
  MASTER_BOX_TWO,
  NUMBER_OF_BATCHES_ONE,
  NUMBER_OF_BATCHES_THREE,
  NUMBER_OF_BATCHES_TWO,
  ORDER_QUANTITY_ONE,
  ORDER_QUANTITY_THREE,
  ORDER_QUANTITY_TWO,
  PROFIT_ONE,
  PROFIT_THREE,
  PROFIT_TWO,
  RUN_RATE_ONE,
  RUN_RATE_THREE,
  RUN_RATE_TWO,
  TANK_SIZE_ONE,
  TANK_SIZE_TWO,
  TANK_SIZE_THREE,
  SIZE_ONE_PROFIT_ONE,
  SIZE_ONE_PROFIT_TWO,
  SIZE_ONE_PROFIT_THREE,
  SIZE_TWO_PROFIT_ONE,
  SIZE_TWO_PROFIT_TWO,
  SIZE_TWO_PROFIT_THREE,
  SIZE_THREE_PROFIT_ONE,
  SIZE_THREE_PROFIT_TWO,
  SIZE_THREE_PROFIT_THREE,
  SIZE_ONE_TANK_SIZE_ONE,
  SIZE_ONE_TANK_SIZE_TWO,
  SIZE_ONE_TANK_SIZE_THREE,
  SIZE_TWO_TANK_SIZE_ONE,
  SIZE_TWO_TANK_SIZE_TWO,
  SIZE_TWO_TANK_SIZE_THREE,
  SIZE_THREE_TANK_SIZE_ONE,
  SIZE_THREE_TANK_SIZE_TWO,
  SIZE_THREE_TANK_SIZE_THREE,
  SIZE_ONE_NUMBER_OF_BATCHES_ONE,
  SIZE_ONE_NUMBER_OF_BATCHES_TWO,
  SIZE_ONE_NUMBER_OF_BATCHES_THREE,
  SIZE_TWO_NUMBER_OF_BATCHES_ONE,
  SIZE_TWO_NUMBER_OF_BATCHES_TWO,
  SIZE_TWO_NUMBER_OF_BATCHES_THREE,
  SIZE_THREE_NUMBER_OF_BATCHES_ONE,
  SIZE_THREE_NUMBER_OF_BATCHES_TWO,
  SIZE_THREE_NUMBER_OF_BATCHES_THREE,
} = WORKSHEET_FORM_KEYS;

interface WorksheetProps {
  closeWorksheet: () => void;
  project: Maybe<IApiData<ProjectAttributes>>;
  formula: IApiData<FormulaAttributes>;
  handleApprovalClick: (approval: IApiData<FeedbackAttributes>) => void;
  initialValues: WorksheetFormValues;
  isPriceQuoteEditable: boolean;
  setShowApprovalAlert: React.Dispatch<React.SetStateAction<boolean>>;
  showApprovalAlert: boolean;
  worksheet: IApiData<WorksheetAttributes>;
}

export const Worksheet: React.FC<WorksheetProps> = ({
  closeWorksheet,
  handleApprovalClick,
  project,
  formula,
  initialValues,
  isPriceQuoteEditable,
  setShowApprovalAlert,
  showApprovalAlert,
  worksheet,
}) => {
  const { permissions } = useContext(UserContext)!;

  const isPurchasingApprover = permissions.includes(
    PERMISSIONS.PURCHASING_APPROVER
  );

  const isOperationsApprover = permissions.includes(
    PERMISSIONS.OPERATIONS_APPROVER
  );

  const isManagementApprover = permissions.includes(
    PERMISSIONS.MANAGEMENT_APPROVER
  );

  const [searchParams] = useSearchParams();
  const sectionParam = searchParams.get(SECTION_PARAM);
  const [activeSection, setActiveSection] = useState<string | null>(null);

  useEffect(() => {
    if (sectionParam) {
      setActiveSection(sectionParam);
    }
  }, [sectionParam]);

  const { values, setValues, setFieldValue, handleSubmit } = useFormikContext<
    WorksheetFormValues
  >();
  const [showFillSizeModal, setShowFillSizeModal] = useState(false);
  const [selectedSizeIndex, setSelectedSizeIndex] = useState<SIZES>(SIZES.ONE);
  const [editFillSizeValue, setEditFillSizeValue] = useState<number | null>(
    null
  );
  const [editFillSizeIndex, setEditFillSizeIndex] = useState<number | null>(
    null
  );

  const { sizeOneQuantity, sizeTwoQuantity, sizeThreeQuantity } = values;
  const sizes = [sizeOneQuantity, sizeTwoQuantity, sizeThreeQuantity];
  const selectedSizeInOz = sizes[selectedSizeIndex]!;

  const approvals = extractApprovals(worksheet);

  const getProfitFromSelectedSizeIndex = () => {
    switch (selectedSizeIndex) {
      case SIZES.ONE:
        return {
          profitOne: values[SIZE_ONE_PROFIT_ONE],
          profitTwo: values[SIZE_ONE_PROFIT_TWO],
          profitThree: values[SIZE_ONE_PROFIT_THREE],
        };
      case SIZES.TWO:
        return {
          profitOne: values[SIZE_TWO_PROFIT_ONE],
          profitTwo: values[SIZE_TWO_PROFIT_TWO],
          profitThree: values[SIZE_TWO_PROFIT_THREE],
        };
      case SIZES.THREE:
        return {
          profitOne: values[SIZE_THREE_PROFIT_ONE],
          profitTwo: values[SIZE_THREE_PROFIT_TWO],
          profitThree: values[SIZE_THREE_PROFIT_THREE],
        };
      default:
        return {
          profitOne: values[PROFIT_ONE],
          profitTwo: values[PROFIT_TWO],
          profitThree: values[PROFIT_THREE],
        };
    }
  };

  const {
    profitOne,
    profitTwo,
    profitThree,
  } = getProfitFromSelectedSizeIndex();

  const quoteCalculatorOne = new QuoteCalculator({
    orderQuantity: values[ORDER_QUANTITY_ONE] as number,
    profit: profitOne,
    size: selectedSizeInOz,
    unit: UNITS.oz,
    ingredients: values[INGREDIENTS],
  });

  const quoteCalculatorTwo = new QuoteCalculator({
    orderQuantity: values[ORDER_QUANTITY_TWO] as number,
    profit: profitTwo,
    size: selectedSizeInOz,
    unit: UNITS.oz,
    ingredients: values[INGREDIENTS],
  });

  const quoteCalculatorThree = new QuoteCalculator({
    orderQuantity: values[ORDER_QUANTITY_THREE] as number,
    profit: profitThree,
    size: selectedSizeInOz,
    unit: UNITS.oz,
    ingredients: values[INGREDIENTS],
  });

  const formulaCosts = quoteCalculatorOne.calculateFormulaCost();

  // Calling these so that the margin calculation will work properly on all three columns.
  quoteCalculatorTwo.calculateFormulaCost();
  quoteCalculatorThree.calculateFormulaCost();

  const laborCosts = [
    quoteCalculatorOne.calculateLaborCost({
      batchingHours: values[BATCHING_HOURS_ONE],
      fillingHeadCount: values[FILLING_HEAD_COUNT_ONE],
      compoundingHeadCount: values[COMPOUNDING_HEAD_COUNT_ONE],
      runRatePerMinute: values[RUN_RATE_ONE],
      fillingPayRate: values[FILLING_PAY_RATE_ONE],
      numberOfBatches: values[NUMBER_OF_BATCHES_ONE],
      compoundingPayRate: values[COMPOUNDING_PAY_RATE_ONE],
      tankSize: values[TANK_SIZE_ONE],
    }),
    quoteCalculatorTwo.calculateLaborCost({
      batchingHours: values[BATCHING_HOURS_TWO],
      fillingHeadCount: values[FILLING_HEAD_COUNT_TWO],
      compoundingHeadCount: values[COMPOUNDING_HEAD_COUNT_TWO],
      runRatePerMinute: values[RUN_RATE_TWO],
      fillingPayRate: values[FILLING_PAY_RATE_TWO],
      numberOfBatches: values[NUMBER_OF_BATCHES_TWO],
      compoundingPayRate: values[COMPOUNDING_PAY_RATE_TWO],
      tankSize: values[TANK_SIZE_TWO],
    }),
    quoteCalculatorThree.calculateLaborCost({
      batchingHours: values[BATCHING_HOURS_THREE],
      fillingHeadCount: values[FILLING_HEAD_COUNT_THREE],
      compoundingHeadCount: values[COMPOUNDING_HEAD_COUNT_THREE],
      runRatePerMinute: values[RUN_RATE_THREE],
      fillingPayRate: values[FILLING_PAY_RATE_THREE],
      numberOfBatches: values[NUMBER_OF_BATCHES_THREE],
      compoundingPayRate: values[COMPOUNDING_PAY_RATE_THREE],
      tankSize: values[TANK_SIZE_THREE],
    }),
  ];

  const componentsCosts = [
    quoteCalculatorOne.calculateComponentsCost({
      masterBox: values[MASTER_BOX_ONE],
      additionalComponentOne: values[ADDITIONAL_COMPONENT_ONE_A],
      additionalComponentTwo: values[ADDITIONAL_COMPONENT_ONE_B],
      additionalComponentThree: values[ADDITIONAL_COMPONENT_ONE_C],
    }),
    quoteCalculatorTwo.calculateComponentsCost({
      masterBox: values[MASTER_BOX_TWO],
      additionalComponentOne: values[ADDITIONAL_COMPONENT_TWO_A],
      additionalComponentTwo: values[ADDITIONAL_COMPONENT_TWO_B],
      additionalComponentThree: values[ADDITIONAL_COMPONENT_TWO_C],
    }),
    quoteCalculatorThree.calculateComponentsCost({
      masterBox: values[MASTER_BOX_THREE],
      additionalComponentOne: values[ADDITIONAL_COMPONENT_THREE_A],
      additionalComponentTwo: values[ADDITIONAL_COMPONENT_THREE_B],
      additionalComponentThree: values[ADDITIONAL_COMPONENT_THREE_C],
    }),
  ];

  const margins = [
    quoteCalculatorOne.calculateMargin(),
    quoteCalculatorTwo.calculateMargin(),
    quoteCalculatorThree.calculateMargin(),
  ];

  const contributionsPerRun = [
    quoteCalculatorOne.getContributionPerRun(),
    quoteCalculatorTwo.getContributionPerRun(),
    quoteCalculatorThree.getContributionPerRun(),
  ];

  const totalExcessRaws = [
    quoteCalculatorOne.getTotalExcessRawMaterialsCost(),
    quoteCalculatorTwo.getTotalExcessRawMaterialsCost(),
    quoteCalculatorThree.getTotalExcessRawMaterialsCost(),
  ];

  const onCancel = (section: string) => {
    setActiveSection(null);
    switch (section) {
      case SECTIONS.ORDER_QTY:
        setValues({
          ...values,
          [ORDER_QUANTITY_TWO]: initialValues[ORDER_QUANTITY_TWO],
          [ORDER_QUANTITY_THREE]: initialValues[ORDER_QUANTITY_THREE],
        });
        break;
      case SECTIONS.FORMULA:
        const { ingredients } = values;
        const initialIngredients = initialValues[INGREDIENTS];
        ingredients.forEach(
          (
            _ingredient: IApiData<WorksheetIngredientAttributes>,
            index: number
          ) => {
            setFieldValue(
              `${INGREDIENTS}[${index}].attributes.pricePerPound`,
              initialIngredients[index].attributes.pricePerPound
            );
            setFieldValue(
              `${INGREDIENTS}[${index}].attributes.supplier`,
              initialIngredients[index].attributes.supplier
            );
          }
        );
        break;
      case SECTIONS.LABOR:
        setValues({
          ...values,
          [EFFICIENCY_PERCENTAGE_ONE]: initialValues[EFFICIENCY_PERCENTAGE_ONE],
          [EFFICIENCY_PERCENTAGE_TWO]: initialValues[EFFICIENCY_PERCENTAGE_TWO],
          [EFFICIENCY_PERCENTAGE_THREE]:
            initialValues[EFFICIENCY_PERCENTAGE_THREE],
          [RUN_RATE_ONE]: initialValues[RUN_RATE_ONE],
          [RUN_RATE_TWO]: initialValues[RUN_RATE_TWO],
          [RUN_RATE_THREE]: initialValues[RUN_RATE_THREE],
          [COMPOUNDING_HEAD_COUNT_ONE]:
            initialValues[COMPOUNDING_HEAD_COUNT_ONE],
          [COMPOUNDING_HEAD_COUNT_TWO]:
            initialValues[COMPOUNDING_HEAD_COUNT_TWO],
          [COMPOUNDING_HEAD_COUNT_THREE]:
            initialValues[COMPOUNDING_HEAD_COUNT_THREE],
          [COMPOUNDING_PAY_RATE_ONE]: initialValues[COMPOUNDING_PAY_RATE_ONE],
          [COMPOUNDING_PAY_RATE_TWO]: initialValues[COMPOUNDING_PAY_RATE_TWO],
          [COMPOUNDING_PAY_RATE_THREE]:
            initialValues[COMPOUNDING_PAY_RATE_THREE],
          [FILLING_HEAD_COUNT_ONE]: initialValues[FILLING_HEAD_COUNT_ONE],
          [FILLING_HEAD_COUNT_TWO]: initialValues[FILLING_HEAD_COUNT_TWO],
          [FILLING_HEAD_COUNT_THREE]: initialValues[FILLING_HEAD_COUNT_THREE],
          [FILLING_PAY_RATE_ONE]: initialValues[FILLING_PAY_RATE_ONE],
          [FILLING_PAY_RATE_TWO]: initialValues[FILLING_PAY_RATE_TWO],
          [FILLING_PAY_RATE_THREE]: initialValues[FILLING_PAY_RATE_THREE],
          [BATCHING_HOURS_ONE]: initialValues[BATCHING_HOURS_ONE],
          [BATCHING_HOURS_TWO]: initialValues[BATCHING_HOURS_TWO],
          [BATCHING_HOURS_THREE]: initialValues[BATCHING_HOURS_THREE],
          [TANK_SIZE_ONE]: initialValues[TANK_SIZE_ONE],
          [TANK_SIZE_TWO]: initialValues[TANK_SIZE_TWO],
          [TANK_SIZE_THREE]: initialValues[TANK_SIZE_THREE],
          [SIZE_ONE_TANK_SIZE_ONE]: initialValues[SIZE_ONE_TANK_SIZE_ONE],
          [SIZE_ONE_TANK_SIZE_TWO]: initialValues[SIZE_ONE_TANK_SIZE_TWO],
          [SIZE_ONE_TANK_SIZE_THREE]: initialValues[SIZE_ONE_TANK_SIZE_THREE],
          [SIZE_TWO_TANK_SIZE_ONE]: initialValues[SIZE_TWO_TANK_SIZE_ONE],
          [SIZE_TWO_TANK_SIZE_TWO]: initialValues[SIZE_TWO_TANK_SIZE_TWO],
          [SIZE_TWO_TANK_SIZE_THREE]: initialValues[SIZE_TWO_TANK_SIZE_THREE],
          [SIZE_THREE_TANK_SIZE_ONE]: initialValues[SIZE_THREE_TANK_SIZE_ONE],
          [SIZE_THREE_TANK_SIZE_TWO]: initialValues[SIZE_THREE_TANK_SIZE_TWO],
          [SIZE_THREE_TANK_SIZE_THREE]:
            initialValues[SIZE_THREE_TANK_SIZE_THREE],
          [SIZE_ONE_NUMBER_OF_BATCHES_ONE]:
            initialValues[SIZE_ONE_NUMBER_OF_BATCHES_ONE],
          [SIZE_ONE_NUMBER_OF_BATCHES_TWO]:
            initialValues[SIZE_ONE_NUMBER_OF_BATCHES_TWO],
          [SIZE_ONE_NUMBER_OF_BATCHES_THREE]:
            initialValues[SIZE_ONE_NUMBER_OF_BATCHES_THREE],
          [SIZE_TWO_NUMBER_OF_BATCHES_ONE]:
            initialValues[SIZE_TWO_NUMBER_OF_BATCHES_ONE],
          [SIZE_TWO_NUMBER_OF_BATCHES_TWO]:
            initialValues[SIZE_TWO_NUMBER_OF_BATCHES_TWO],
          [SIZE_TWO_NUMBER_OF_BATCHES_THREE]:
            initialValues[SIZE_TWO_NUMBER_OF_BATCHES_THREE],
          [SIZE_THREE_NUMBER_OF_BATCHES_ONE]:
            initialValues[SIZE_THREE_NUMBER_OF_BATCHES_ONE],
          [SIZE_THREE_NUMBER_OF_BATCHES_TWO]:
            initialValues[SIZE_THREE_NUMBER_OF_BATCHES_TWO],
          [SIZE_THREE_NUMBER_OF_BATCHES_THREE]:
            initialValues[SIZE_THREE_NUMBER_OF_BATCHES_THREE],
        });
        break;
      case SECTIONS.COMPONENTS:
        setValues({
          ...values,
          [MASTER_BOX_ONE]: initialValues[MASTER_BOX_ONE],
          [MASTER_BOX_TWO]: initialValues[MASTER_BOX_TWO],
          [MASTER_BOX_THREE]: initialValues[MASTER_BOX_THREE],
          [ADDITIONAL_COMPONENT_ONE_A]:
            initialValues[ADDITIONAL_COMPONENT_ONE_A],
          [ADDITIONAL_COMPONENT_ONE_B]:
            initialValues[ADDITIONAL_COMPONENT_ONE_B],
          [ADDITIONAL_COMPONENT_ONE_C]:
            initialValues[ADDITIONAL_COMPONENT_ONE_C],
          [ADDITIONAL_COMPONENT_TWO_A]:
            initialValues[ADDITIONAL_COMPONENT_TWO_A],
          [ADDITIONAL_COMPONENT_TWO_B]:
            initialValues[ADDITIONAL_COMPONENT_TWO_B],
          [ADDITIONAL_COMPONENT_TWO_C]:
            initialValues[ADDITIONAL_COMPONENT_TWO_C],
          [ADDITIONAL_COMPONENT_THREE_A]:
            initialValues[ADDITIONAL_COMPONENT_THREE_A],
          [ADDITIONAL_COMPONENT_THREE_B]:
            initialValues[ADDITIONAL_COMPONENT_THREE_B],
          [ADDITIONAL_COMPONENT_THREE_C]:
            initialValues[ADDITIONAL_COMPONENT_THREE_C],
        });
        break;
      case SECTIONS.MARGIN:
        setValues({
          ...values,
          [PROFIT_ONE]: initialValues[PROFIT_ONE],
          [PROFIT_TWO]: initialValues[PROFIT_TWO],
          [PROFIT_THREE]: initialValues[PROFIT_THREE],
          [SIZE_ONE_PROFIT_ONE]: initialValues[SIZE_ONE_PROFIT_ONE],
          [SIZE_ONE_PROFIT_TWO]: initialValues[SIZE_ONE_PROFIT_TWO],
          [SIZE_ONE_PROFIT_THREE]: initialValues[SIZE_ONE_PROFIT_THREE],
          [SIZE_TWO_PROFIT_ONE]: initialValues[SIZE_TWO_PROFIT_ONE],
          [SIZE_TWO_PROFIT_TWO]: initialValues[SIZE_TWO_PROFIT_TWO],
          [SIZE_TWO_PROFIT_THREE]: initialValues[SIZE_TWO_PROFIT_THREE],
          [SIZE_THREE_PROFIT_ONE]: initialValues[SIZE_THREE_PROFIT_ONE],
          [SIZE_THREE_PROFIT_TWO]: initialValues[SIZE_THREE_PROFIT_TWO],
          [SIZE_THREE_PROFIT_THREE]: initialValues[SIZE_THREE_PROFIT_THREE],
        });
        break;
      default:
        break;
    }
  };

  const onSubmit = () => {
    handleSubmit();
    setActiveSection(null);
  };

  const handleBottomSummarySubmit = () => {
    handleSubmit();
    closeWorksheet();
  };

  const handleFillSizeModal = (fillSizeIndex?: number) => {
    fillSizeIndex !== null && fillSizeIndex !== undefined
      ? setEditFillSizeValue(sizes[fillSizeIndex]!)
      : setEditFillSizeValue(null);
    fillSizeIndex !== null && fillSizeIndex !== undefined
      ? setEditFillSizeIndex(fillSizeIndex)
      : setEditFillSizeIndex(null);
    setShowFillSizeModal(!showFillSizeModal);
  };

  return (
    <>
      {showFillSizeModal && (
        <Modal handleClose={handleFillSizeModal}>
          <FillSizeModal
            closeFillSizeModal={handleFillSizeModal}
            fillSizeValue={editFillSizeValue}
            fillSizeIndex={editFillSizeIndex}
            setSelectedSizeIndex={setSelectedSizeIndex}
          />
        </Modal>
      )}
      <div className="relative w-full bg-white max-w-[1440px] mx-auto">
        <div className="grid grid-cols-12 flex-col font-inter ml-16 mr-16 gap-x-6">
          {!isPriceQuoteEditable && (
            <div className="col-span-12 mt-4">
              <Alert
                content={
                  'This form is currently under review by the customer and therefore cannot be edited.'
                }
                severity="warning"
                isBanner
              />
            </div>
          )}
          {showApprovalAlert && (
            <div className="col-span-12 mt-4">
              <Alert
                content={'Approval submitted!'}
                onClose={() => setShowApprovalAlert(false)}
                severity="success"
              />
            </div>
          )}
          <WorksheetInfo
            isPriceQuoteEditable={isPriceQuoteEditable}
            project={project as any}
            formulaName={formula.attributes.name}
            formulaExternalId={formula.attributes.externalId}
            formulaUuid={formula.id}
            worksheet={worksheet}
          />
          <div className="col-start-2 col-span-10 my-[17px] flex justify-end">
            <Checkbox
              defaultChecked={
                approvals.managementApproval?.attributes?.status ===
                FeedbackStatus.approved
              }
              disabled={
                !approvals?.managementApproval ||
                approvals.managementApproval?.attributes?.status ===
                  FeedbackStatus.approved ||
                !isManagementApprover
              }
              onClick={() =>
                handleApprovalClick &&
                approvals.managementApproval &&
                handleApprovalClick(approvals.managementApproval)
              }
              primaryLabel="Management Review Complete"
              primaryLabelProps={{ color: 'primary', size: 'sm' }}
            />
          </div>
          <FillSizeSelector
            isPriceQuoteEditable={isPriceQuoteEditable}
            openFillSizeModal={handleFillSizeModal}
            selectedSizeIndex={selectedSizeIndex}
            setSelectedSizeIndex={setSelectedSizeIndex}
          />
        </div>
      </div>
      <div className="bg-grey-95 h-[108%]">
        <div className="relative w-full max-w-[1440px] mx-auto">
          <div className="grid grid-cols-12 flex-col font-inter ml-16 mr-16 gap-x-6">
            <div className=" col-start-2 col-span-10 mt-11">
              <OrderQuantitySection
                data={orderQuantityData}
                isActive={activeSection === SECTIONS.ORDER_QTY}
                isPriceQuoteEditable={isPriceQuoteEditable}
                setActiveSection={setActiveSection}
                onCancel={() => onCancel(SECTIONS.ORDER_QTY)}
                onSubmit={onSubmit}
              />
              <SectionSummaryRow
                data={{
                  title: 'Formula',
                  values: [
                    USDollar.format(formulaCosts.totalCostPerSizeAndUnit || 0),
                    USDollar.format(formulaCosts.totalCostPerSizeAndUnit || 0),
                    USDollar.format(formulaCosts.totalCostPerSizeAndUnit || 0),
                  ],
                }}
                innerContent={
                  <FormulaSection
                    selectedSizeInOz={selectedSizeInOz}
                    approval={approvals.purchasingApproval}
                    costs={formulaCosts}
                    handleApprovalClick={handleApprovalClick}
                    isActive={activeSection === SECTIONS.FORMULA}
                    isApprover={isPurchasingApprover}
                  />
                }
                isActive={activeSection === SECTIONS.FORMULA}
                onCancel={() => onCancel(SECTIONS.FORMULA)}
                onSubmit={onSubmit}
                setActiveSection={() => setActiveSection(SECTIONS.FORMULA)}
              />
              <SectionSummaryRow
                data={{
                  title: 'Labor Cost',
                  values: laborCosts.map(({ laborCost }) =>
                    USDollar.format(laborCost || 0)
                  ),
                }}
                innerContent={
                  <LaborSection
                    approval={approvals.operationsApproval}
                    costs={laborCosts}
                    handleApprovalClick={handleApprovalClick}
                    isActive={activeSection === SECTIONS.LABOR}
                    isApprover={isOperationsApprover}
                    isPriceQuoteEditable={isPriceQuoteEditable}
                    selectedSizeIndex={selectedSizeIndex}
                  />
                }
                isActive={activeSection === SECTIONS.LABOR}
                onCancel={() => onCancel(SECTIONS.LABOR)}
                onSubmit={onSubmit}
                setActiveSection={() => setActiveSection(SECTIONS.LABOR)}
              />
              <SectionSummaryRow
                data={{
                  title: 'Components Cost',
                  values: componentsCosts.map(({ componentTotalCost }) =>
                    USDollar.format(componentTotalCost || 0)
                  ),
                }}
                innerContent={
                  <ComponentsSection
                    costs={componentsCosts}
                    isActive={activeSection === SECTIONS.COMPONENTS}
                    isPriceQuoteEditable={isPriceQuoteEditable}
                  />
                }
                isActive={activeSection === SECTIONS.COMPONENTS}
                onCancel={() => onCancel(SECTIONS.COMPONENTS)}
                onSubmit={onSubmit}
                setActiveSection={() => setActiveSection(SECTIONS.COMPONENTS)}
              />
              <SectionSummaryRow
                data={{
                  title: 'Margin',
                  values: margins.map(
                    ({ margin }) => `${Math.round(margin || 0)}%`
                  ),
                }}
                innerContent={
                  <MarginSection
                    costs={margins}
                    isActive={activeSection === SECTIONS.MARGIN}
                    isPriceQuoteEditable={isPriceQuoteEditable}
                    selectedSizeIndex={selectedSizeIndex}
                  />
                }
                isActive={activeSection === SECTIONS.MARGIN}
                onCancel={() => onCancel(SECTIONS.MARGIN)}
                onSubmit={onSubmit}
                setActiveSection={() => setActiveSection(SECTIONS.MARGIN)}
                isLastSection={true}
              />
              <div className="w-1/2">
                <NotesSection onSubmit={onSubmit} />
              </div>
            </div>
          </div>
        </div>
        <CostSummary
          contributionsPerRun={contributionsPerRun}
          totalExcessRaws={totalExcessRaws}
          laborCosts={laborCosts}
          closeWorksheet={closeWorksheet}
          costs={margins}
          onSubmit={handleBottomSummarySubmit}
          selectedSizeIndex={selectedSizeIndex}
        />
      </div>
    </>
  );
};
