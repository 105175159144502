// Libraries
import React, { useEffect, useRef, useState } from 'react';
import { createStyles, Grid, makeStyles, Typography } from '@material-ui/core';
import { Link, useSearchParams } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { useLocation } from 'react-router-dom';
// Components
import { ActionsPanel } from './actions-panel.component';
import { BriefSummaryPanel } from './brief-summary-panel.component';
import { CalculationErrorAlert } from './calculation-error-alert.component';
import { DonutChartContainer } from './donut-chart-container.component';
import { FormulaErrors } from './formula-errors.component';
import { FormulasDropdown } from 'features/formula';
import { FormulaWarnings } from './formula-warnings.component';
import { Loader } from './loader.component';
import { PricingChart } from './pricing-chart.component';
import { PricingLaborAudit } from './pricing-labor-audit.component';
import { PricingQuoteInputs } from './pricing-quote-inputs.component';
import { SharePricingQuoteModal } from './share-pricing-quote-modal.component';
// Utils
import { calculateTankSizeAndOutput } from './pricing-calculator.component';
import { generateFormulaErrors } from './formula-errors-generator.util';
import { generateFormulaWarnings } from './formula-warnings-generator.util';
import { generateProjectErrors } from './project-errors-generator.util';
import { IPricingData, IPricingQuote, Tank, WarningOrError } from './types';
import { ITheme, interRegular } from 'styles/mui/themeV2';
import { useApi, FormulaAttributes, IApiData, IngredientAttributes } from 'api';
import { usePricingQuoteParams } from './hooks';
import { UserSession } from 'context';
import { useSnackbar, useAnalytics } from 'hooks';
// Constants
import { PRODUCTION_LOCATIONS, FORMULA_ID } from './constants';
import { BASE_URL, ROUTES, UUID_SHOW_ROUTE_STRING } from 'features/navigation';

const urlEncodeUserName = ({ givenName, familyName }: UserSession) => {
  return encodeURIComponent(`${givenName} ${familyName}`);
};

const useStyles = makeStyles((theme: ITheme) =>
  createStyles({
    grayText: {
      color: theme.palette.gray.main,
    },
    formulaSelect: {
      fontFamily: interRegular.fontFamily,
      fontSize: theme.typography.h3.fontSize,
      marginLeft: theme.spacing(-1),
      marginTop: theme.spacing(4),
    },
    label: {
      marginBottom: theme.spacing(2),
      marginRight: theme.spacing(10),
    },
    marginTargetTextField: {
      width: '100%',
      paddingBottom: theme.spacing(4),
    },
    quoteContainer: {
      padding: theme.spacing(10),
    },
    chartContainer: {
      position: 'sticky',
      top: 72,
      backgroundColor: theme.palette.gray.light,
    },
    loadingPanel: {
      height: '62vh',
      backgroundColor: theme.palette.gray.light,
    },
    auditContainer: {
      color: 'red',
      marginTop: 10,
    },
  })
);
export const PricingQuoteContainer: React.FC<IPricingQuote> = ({
  brief,
  pricingQuote,
  projectId,
  setTabValue,
}) => {
  const classes = useStyles();
  const { getProjectFormulas, getFormula, postEmail } = useApi();
  const { user } = useAuth0();
  const { showSnackbar } = useSnackbar();
  // Search params
  const [searchParams, setSearchParams] = useSearchParams();
  const { hasFormulaParam } = usePricingQuoteParams();
  // Audit state
  const [changedInputUser, setChangedInputUser] = useState<Maybe<string>>(
    undefined
  );

  /* ========== FORMULA, BRIEF, & INGREDIENT STATE ========== */
  const [isLoadingFormulas, setIsLoadingFormulas] = useState<boolean>(true);
  const [isFormulaLoading, setIsFormulaLoading] = useState<boolean>(false);
  const [formulaErrors, setFormulaErrors] = useState<WarningOrError[]>([]);

  const [formulas, setFormulas] = useState<
    Maybe<IApiData<FormulaAttributes>[]>
  >(undefined);
  const [selectedFormula, setSelectedFormula] = useState<
    Maybe<IApiData<FormulaAttributes>>
  >(undefined);
  const [ingredients, setIngredients] = useState<
    Maybe<IApiData<IngredientAttributes>[]>
  >(undefined);
  const [isShareQuoteModalOpen, setIsShareQuoteModalOpen] = useState<boolean>(
    false
  );
  const [briefWithSupplement, setBriefWithSupplement] = useState<Maybe<any>>(
    undefined
  );
  const {
    productionLocation,
  }: {
    productionLocation: Maybe<valueof<typeof PRODUCTION_LOCATIONS>>;
  } = briefWithSupplement ? briefWithSupplement : {};

  const analytics = useAnalytics();
  const location = useLocation();

  // Sets formulas to determine formula error messages
  useEffect(() => {
    if (projectId) {
      getProjectFormulas({
        urlParams: `${projectId}/formulas`,
        handleSuccess: ({ data }: { data: IApiData<FormulaAttributes>[] }) => {
          setFormulas(data);
        },
        handleFinally: () => setIsLoadingFormulas(false),
      });
    }
  }, [projectId, getProjectFormulas, brief]);

  useEffect(() => {
    if (!briefWithSupplement || !productionLocation) {
      return;
    }

    const newProductionTank: Maybe<
      Tank & {
        tanks: Tank[];
      }
    > = calculateTankSizeAndOutput({
      productionLocation: productionLocation,
      brief: briefWithSupplement,
    });

    setProductionTank(newProductionTank);
  }, [briefWithSupplement, productionLocation]);
  /* ========== CHART STATE ========== */
  const [pricingData, setPricingData] = useState<Maybe<IPricingData>>(
    undefined
  );

  useEffect(() => {
    if (!hasFormulaParam) return;

    getFormula({
      urlParams: `/${searchParams.get(FORMULA_ID)}`,
      handleSuccess: setSelectedFormula,
    });
  }, [hasFormulaParam, searchParams, getFormula]);

  const handleFormulaSelect = (formula: IApiData<FormulaAttributes>) => {
    setSelectedFormula(formula);

    const previousFormulaId = searchParams.get(FORMULA_ID);

    // If we already have this formula ID in the URL skip set state
    if (previousFormulaId !== formula.id) {
      searchParams.set(FORMULA_ID, formula.id);
      setSearchParams(searchParams);
    }
  };

  const [productionTank, setProductionTank] = useState<
    Maybe<
      Tank & {
        tanks: Tank[];
      }
    >
  >(undefined);

  // When a formula is selected, fetch it's ingredients and project brief
  // We use a ref here to generateFormulaErrors from the new formula and ingredients
  // without creating an infinite loop w/ ingredients in the dependency array
  const ingredientsRef = useRef(ingredients);

  useEffect(() => {
    if (!selectedFormula) return;

    setIsFormulaLoading(true);
    getFormula({
      urlParams: selectedFormula.id,
      handleSuccess: (data) => {
        setIngredients(
          (ingredientsRef.current = data.ingredients?.map(
            ({ id, attributes }: IApiData<IngredientAttributes>) => {
              return {
                id,
                attributes: { ...attributes, price: attributes.highestPrice },
              };
            }
          ))
        );

        setBriefWithSupplement({
          id: brief.id,
          ...brief.attributes,
          ...brief.attributes.briefSupplement?.data.attributes,
        });
      },
      handleFinally: () => {
        setFormulaErrors(
          generateFormulaErrors(selectedFormula, ingredientsRef.current)
        );
        setIsFormulaLoading(false);
      },
    });
  }, [brief, getFormula, selectedFormula]);

  const sendPricingQuoteEmail = (emails: string[]) => {
    if (!selectedFormula) return;

    const additionalParams = `&formulaId=${selectedFormula.id}`;
    const currentDate = new Date();
    const formattedDate = changedInputUser
      ? `${currentDate.getMonth()}-${currentDate.getDate()}-${currentDate.getFullYear()}`
      : '';
    const auditParams = changedInputUser
      ? `&userName=${changedInputUser}&date=${formattedDate}`
      : '';
    const url = `${BASE_URL}${ROUTES.SHOW_PROJECT.route.replace(
      UUID_SHOW_ROUTE_STRING,
      projectId
    )}?tab=pricing${additionalParams}${auditParams}`;

    postEmail({
      data: {
        email: {
          addresses: [user?.email, ...emails],
          method: 'share-pricing-quote',
          projectUuid: projectId,
          shareUrl: encodeURIComponent(url),
        },
      },
      handleSuccess: () => showSnackbar('Email successfully sent!', 'success'),
      handleError: (e) => {
        showSnackbar('Failed to send email', 'error');
        Rollbar.error(e);
      },
      handleFinally: () => setIsShareQuoteModalOpen(false),
    });
  };

  useEffect(() => {
    analytics?.pageView(location.pathname);
  }, [analytics, location.pathname]);

  const projectErrors = generateProjectErrors({
    brief,
    formulas,
    productionLocation,
  });
  const hasProjectErrors = Boolean(projectErrors.length);
  const hasFormulaErrors = Boolean(formulaErrors.length);

  return (
    <Loader isLoading={isLoadingFormulas}>
      <Grid container className={classes.quoteContainer}>
        <Grid item xs={12} sm={6}>
          {hasProjectErrors && briefWithSupplement && (
            <CalculationErrorAlert errors={projectErrors} />
          )}
          {formulas && (
            <div className="mb-4">
              <FormulasDropdown
                formula={selectedFormula}
                projectUuid={projectId}
                handleClick={handleFormulaSelect}
              />
              <Link
                to={ROUTES.SHOW_FORMULA.route.replace(
                  UUID_SHOW_ROUTE_STRING,
                  selectedFormula?.id || ''
                )}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Typography className={classes.grayText} variant="body1">
                  {selectedFormula?.attributes.name}
                </Typography>
              </Link>
              <FormulaWarnings
                warnings={generateFormulaWarnings(selectedFormula, ingredients)}
              />
            </div>
          )}
          <FormulaErrors errors={formulaErrors} />
          <BriefSummaryPanel brief={brief} setTabValue={setTabValue} />
          {!hasProjectErrors && (
            <>
              <PricingQuoteInputs
                briefWithSupplement={briefWithSupplement}
                pricingQuote={pricingQuote}
                productionLocation={productionLocation}
                productionTank={productionTank}
                setChangedInputUser={setChangedInputUser}
                setProductionTank={setProductionTank}
                urlEncodeUserName={urlEncodeUserName}
              />
              <PricingLaborAudit setChangedInputUser={setChangedInputUser} />
              <ActionsPanel
                briefWithSupplement={briefWithSupplement}
                openSharePricingQuoteModal={() =>
                  setIsShareQuoteModalOpen(true)
                }
                productionTank={productionTank}
              />
            </>
          )}
        </Grid>

        {/* RIGHT SIDE OF THE PAGE */}
        <DonutChartContainer
          brief={brief.attributes}
          isLoading={isFormulaLoading}
          isMissingRequiredInformation={hasProjectErrors || hasFormulaErrors}
          ingredients={ingredients}
          productionLocation={productionLocation}
          productionTank={productionTank}
          pricingData={pricingData}
          pricingQuote={pricingQuote}
          selectedFormula={selectedFormula}
        >
          <PricingChart
            briefWithSupplement={briefWithSupplement}
            ingredients={ingredients}
            pricingData={pricingData}
            productionLocation={productionLocation}
            productionTank={productionTank}
            selectedFormula={selectedFormula}
            setPricingData={setPricingData}
            targetCost={brief.attributes?.targetCost}
            targetMaxCost={brief.attributes?.targetMaxCost}
          />
        </DonutChartContainer>
        <SharePricingQuoteModal
          closeModal={() => setIsShareQuoteModalOpen(false)}
          isOpen={isShareQuoteModalOpen}
          sendPricingQuoteEmail={sendPricingQuoteEmail}
        />
      </Grid>
    </Loader>
  );
};
