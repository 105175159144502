// Libraries
import React from 'react';
// Components
import { Typography } from 'design-system';
import { FormulaCard } from 'features/formula/formula-card';

// The types have to be any because the shape of the incoming props is variable
interface RtlBriefShowPageProps {
  project: any;
  brief: any;
  contact: any;
  sampleRecipients: any;
  formulaUuid: string;
}

export const RtlBriefShowPage: React.FC<RtlBriefShowPageProps> = ({
  project,
  brief,
  contact,
  sampleRecipients,
  formulaUuid,
}) => {
  const renderSampleRecipients = () => {
    return sampleRecipients?.map((recipient: any, index: number) => (
      <div key={index} className="flex flex-col gap-y-1">
        <Typography variant="h4" font="inter">
          {recipient?.attributes?.fullName}
        </Typography>
        <Typography variant="h4" font="inter">
          {recipient?.attributes?.address1}
        </Typography>
        <Typography variant="h4" font="inter">
          {recipient?.attributes?.address2}
        </Typography>
        <div className="flex flex-row gap-x-1">
          <Typography variant="h4" font="inter">
            {recipient?.attributes?.city}
          </Typography>
          ,
          <Typography variant="h4" font="inter">
            {recipient?.attributes?.state}
          </Typography>
          <Typography variant="h4" font="inter">
            {recipient?.attributes?.zip}
          </Typography>
        </div>
        <Typography variant="h4" font="inter">
          {recipient?.attributes?.email}
        </Typography>
        <Typography variant="h4" font="inter">
          {recipient?.attributes?.phone}
        </Typography>
      </div>
    ));
  };

  const convertNumber = (
    value: number | string,
    numberType: 'float' | 'int'
  ) => {
    if (value === undefined) return 'N/A';

    if (typeof value === 'number') {
      return value.toLocaleString();
    } else {
      if (numberType === 'float') {
        return Number(parseFloat(value).toFixed(2)).toLocaleString();
      }
      if (numberType === 'int') {
        return parseInt(
          brief.attributes?.minimumOrderQuantity,
          10
        ).toLocaleString();
      }
    }
  };

  return (
    <div className="flex flex-col gap-y-12 bg-grey-99 h-auto md:px-56 lg:px-56 pt-12 rounded-tl rounded-tr w-full pl-6 pr-6 md:p-9 md:pb-[200px] lg:p-12 lg:pb-[200px]">
      <div className="flex flex-col gap-y-3">
        <Typography variant="h6" font="mono" color="secondary">
          POINT OF CONTACT
        </Typography>
        <Typography variant="h4" font="inter">
          {contact?.attributes?.fullName}
        </Typography>
      </div>

      <div className="flex flex-col gap-y-3">
        <Typography variant="h6" font="mono" color="secondary">
          FILL SIZE
        </Typography>
        <div className="flex flex-row gap-x-1">
          <Typography variant="h4" font="inter">
            {convertNumber(brief.attributes.size, 'float')}
          </Typography>
          <Typography variant="h4">{brief?.attributes?.unit}</Typography>
        </div>
      </div>

      <div className="flex flex-col gap-y-3">
        <Typography variant="h6" font="mono" color="secondary">
          CUSTOMER NOTES
        </Typography>
        <Typography variant="h4" font="inter">
          {project.attributes.description}
        </Typography>
      </div>

      <div className="flex flex-col gap-y-3">
        <Typography variant="h6" font="mono" color="secondary">
          INITIAL ORDER QUANTITY
        </Typography>
        <Typography variant="h4" font="inter">
          {convertNumber(brief.attributes.minimumOrderQuantity, 'int')}
        </Typography>
      </div>

      <div className="flex flex-col gap-y-3">
        <Typography variant="h6" font="mono" color="secondary">
          TARGET LAUNCH DATE
        </Typography>
        <Typography variant="h4" font="inter">
          {brief.attributes.targetDate
            ? new Date(brief.attributes?.targetDate).toLocaleDateString()
            : 'N/A'}
        </Typography>
      </div>

      <div className="flex flex-col gap-y-3">
        <Typography variant="h6" font="mono" color="secondary">
          SAMPLE RECIPIENT
        </Typography>
        <div className="flex flex-col gap-y-12">{renderSampleRecipients()}</div>
      </div>

      <FormulaCard formulaUuid={formulaUuid} />
    </div>
  );
};
