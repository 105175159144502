// Libraries
import React, { useContext, useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import _ from 'lodash';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Tooltip } from '@material-ui/core';
import {
  ArrowUpTrayIcon,
  BeakerIcon,
  CheckIcon,
  ChevronDownIcon,
  EyeDropperIcon,
  InformationCircleIcon,
  PencilIcon,
  PlusIcon,
  QueueListIcon,
  TrashIcon,
  XCircleIcon,
} from '@heroicons/react/16/solid';
// Components
import {
  Autocomplete,
  BottomBar,
  BreadcrumbMenu,
  Button,
  ChevronSelect,
  Spinner,
  SquareIcon,
  Switch,
  TextArea,
  TextField,
} from 'design-system';
// Components
import { ChemicalFamilyModal } from '.';
// Utils
import { LeftNavContext, MobileContext, useAlert, UserContext } from 'context';
import { ChemicalFamilyAssociationAttributes, IApiData, useApi } from 'api';
// Constants
import { ROUTES, UUID_SHOW_ROUTE_STRING } from 'features/navigation';
import {
  CHEMICAL_FAMILY_ASSOCIABLE_TYPES,
  CHEMICAL_FAMILY_TYPES,
} from './constants';

const MODES = {
  CREATE: 'create',
  EDIT: 'edit',
  VIEW: 'view',
};
type Mode = typeof MODES[keyof typeof MODES];

const CHEMICAL_FAMILY_TYPE_OPTIONS = [
  { label: 'Retailer Standard', value: 'retailerStandard' },
  { label: 'Client No-No', value: 'clientNoNoList' },
  { label: 'INCI Family', value: 'inciFamily' },
  { label: 'Claim Group', value: 'claimGroup' },
  { label: 'Other', value: 'other' },
];

interface ChemicalFamilyProps {
  initialMode?: Mode;
}

export type ChemicalFamilyFormValues = {
  chemicalFamilyAssociations: ChemicalFamilyAssociationAttributes[];
  description: string;
  name: string;
  referenceUrl: string;
  chemicalFamilyType: string;
  company: { name: string; uuid: string };
  isVisibleToCustomers: boolean;
};

export const ChemicalFamily: React.FC<ChemicalFamilyProps> = ({
  initialMode,
}) => {
  const [mode, setMode] = useState<Mode>(initialMode || MODES.VIEW);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const tableHeaders = ['Name', 'Status', 'Date Added', 'Added By', 'Notes'];
  const [initialValues, setInitialValues] = useState<ChemicalFamilyFormValues>({
    chemicalFamilyAssociations: [],
    description: '',
    name: '',
    referenceUrl: '',
    chemicalFamilyType: '',
    company: { name: '', uuid: '' },
    isVisibleToCustomers: false,
  });

  const { isLeftNavOpen } = useContext(LeftNavContext);
  const { isMobile } = useContext(MobileContext);
  const userSession = useContext(UserContext)!.userSession;
  const { uuid } = useParams<{
    uuid: string;
  }>();

  const { showAlert, showAlertWithTimeout } = useAlert();
  const {
    deleteAllChemicalFamilyAssociations,
    deleteChemicalFamily,
    deleteChemicalFamilyAssociation,
    getChemicalFamily,
    patchChemicalFamily,
    postChemicalFamily,
    searchCompanies,
  } = useApi();
  const navigate = useNavigate();

  useEffect(() => {
    if (mode === MODES.VIEW) {
      setIsLoading(true);
      getChemicalFamily({
        urlParams: uuid!,
        handleSuccess: (data) => {
          const parsedData = {
            ...data.attributes,
            chemicalFamilyType: _.camelCase(data.attributes.chemicalFamilyType),
            company: {
              name: data.company?.attributes.name,
              uuid: data.company?.id,
            },
            chemicalFamilyAssociations:
              data.chemicalFamilyAssociations?.length > 0
                ? data.chemicalFamilyAssociations?.map(
                    (
                      association: IApiData<ChemicalFamilyAssociationAttributes>
                    ) => {
                      return {
                        ...association.attributes,
                      };
                    }
                  )
                : [],
          };
          setInitialValues(parsedData as ChemicalFamilyFormValues);
          setIsLoading(false);
        },
      });
    }
  }, [getChemicalFamily, mode, uuid]);

  const ChemicalFamilyButtons = () => {
    return (
      <div className="flex flex-col sm:flex-row gap-3">
        <Button
          disabled={!values.chemicalFamilyType}
          leadingIcon={<ArrowUpTrayIcon className="h-6 w-6" />}
          size="large"
          type="secondary"
          text="Import list..."
          width="w-full"
        />
        <Button
          disabled={!values.chemicalFamilyType}
          leadingIcon={<PlusIcon className="h-6 w-6" />}
          onClick={() => {
            mode === MODES.VIEW && setMode(MODES.EDIT);
            setIsModalOpen(true);
          }}
          size="large"
          type="primary"
          text="Add new..."
          width="w-full"
        />
      </div>
    );
  };

  const formik = useFormik<ChemicalFamilyFormValues>({
    initialValues,
    enableReinitialize: true,
    validationSchema: Yup.object({
      company: Yup.object().when('chemicalFamilyType', {
        is: CHEMICAL_FAMILY_TYPES.CLIENT_NO_NO_LIST,
        then: Yup.object().shape({
          name: Yup.string().required('Please select a company'),
          uuid: Yup.string().required('Please select a company'),
        }),
      }),
      name: Yup.string()
        .required('Please enter a name')
        .test('name-required', 'Please enter a name', function (value) {
          if (!value) {
            showAlert({
              content: 'Please enter a name',
              severity: 'error',
            });
            return false;
          }
          return true;
        }),
      chemicalFamilyType: Yup.string()
        .required('Please choose a group type')
        .test(
          'chemicalFamilyType-required',
          'Please choose a group type',
          function (value) {
            if (!value || value === '') {
              showAlert({
                content: 'Please choose a group type',
                severity: 'error',
              });
              return false;
            }
            return true;
          }
        ),
      referenceUrl: Yup.string().url('Please enter a valid URL'),
    }),
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: (values) => {
      const {
        name,
        description,
        chemicalFamilyType,
        company,
        isVisibleToCustomers,
        referenceUrl,
        chemicalFamilyAssociations,
      } = values;

      const payload = {
        chemicalFamily: {
          chemicalFamilyAssociations: chemicalFamilyAssociations.map(
            (association) => {
              return {
                ...association,
                relationshipType: _.snakeCase(association.relationshipType),
              };
            }
          ),
          company: company?.uuid && { uuid: company.uuid },
          description,
          name,
          referenceUrl,
          chemicalFamilyType: _.snakeCase(chemicalFamilyType),
          isVisibleToCustomers,
        },
      };
      if (mode === MODES.CREATE) {
        postChemicalFamily({
          data: payload,
          handleSuccess: () => {
            navigate(ROUTES.CHEMICAL_FAMILY_INDEX.route);
            showAlertWithTimeout({
              content: 'RM Inci Group successfully created.',
              severity: 'success',
            });
          },
        });
      } else {
        patchChemicalFamily({
          urlParams: uuid!,
          data: payload,
          handleSuccess: () => {
            showAlertWithTimeout({
              content: 'RM Inci Group successfully updated.',
              severity: 'success',
            });
            setMode(MODES.VIEW);
          },
        });
      }
    },
  });

  const { values, getFieldProps, setFieldValue } = formik;
  const hasAssociations = values.chemicalFamilyAssociations.length > 0;

  const handleModalSubmission = (value: any) => {
    if (
      values.chemicalFamilyAssociations
        .map((association) => association.associable.uuid)
        .includes(value.associable.uuid)
    ) {
      showAlertWithTimeout({
        content: 'This item is already in the group.',
        severity: 'error',
      });
      return;
    }

    setFieldValue('chemicalFamilyAssociations', [
      ...values.chemicalFamilyAssociations,
      value,
    ]);
    setIsModalOpen(false);
  };

  const handleDeleteChemicalFamily = () => {
    deleteChemicalFamily({
      urlParams: uuid!,
      handleSuccess: () => {
        navigate(ROUTES.CHEMICAL_FAMILY_INDEX.route);
        showAlertWithTimeout({
          content: 'RM Inci Group successfully deleted.',
          severity: 'success',
        });
      },
    });
  };

  const handleDeleteAssocation = (association: any) => {
    if (!association.uuid) {
      const updatedAssociations = values.chemicalFamilyAssociations.filter(
        (item) => item.uuid !== association.uuid
      );
      setFieldValue('chemicalFamilyAssociations', updatedAssociations);
      showAlertWithTimeout({
        content: 'Item successfully deleted.',
        severity: 'success',
      });
      return;
    }
    deleteChemicalFamilyAssociation({
      urlParams: `${association.uuid}`,
      handleSuccess: () => {
        const updatedAssociations = values.chemicalFamilyAssociations.filter(
          (item) => item.uuid !== association.uuid
        );
        setFieldValue('chemicalFamilyAssociations', updatedAssociations);
        showAlertWithTimeout({
          content: 'Item successfully deleted.',
          severity: 'success',
        });
      },
    });
  };

  const handleDeleteAllAssociations = () => {
    deleteAllChemicalFamilyAssociations({
      urlParams: `${encodeURIComponent(uuid!)}/delete_all_associations`,
      handleSuccess: () => {
        setFieldValue('chemicalFamilyAssociations', []);
        showAlertWithTimeout({
          content: 'All items successfully deleted.',
          severity: 'success',
        });
        setMode(MODES.VIEW);
      },
    });
  };

  const renderIcon = (associableType: string) => {
    switch (associableType) {
      case CHEMICAL_FAMILY_ASSOCIABLE_TYPES.INCI:
        return (
          <SquareIcon
            backgroundColor="bg-teal-90"
            icon={<EyeDropperIcon className="w-4 h-4 text-teal-40" />}
          />
        );
      case CHEMICAL_FAMILY_ASSOCIABLE_TYPES.RAW_MATERIAL:
        return (
          <SquareIcon
            backgroundColor="bg-blue-90"
            icon={<BeakerIcon className="w-4 h-4 text-blue-40" />}
          />
        );
      case CHEMICAL_FAMILY_ASSOCIABLE_TYPES.CHEMICAL_FAMILY:
        return (
          <SquareIcon
            backgroundColor="bg-peach-90"
            icon={<QueueListIcon className="w-4 h-4 text-peach-40" />}
          />
        );
      default:
        return null;
    }
  };

  // Render empty state when there are no associations present
  const renderEmptyState = () => {
    return (
      <div className="flex flex-col gap-3 sm:gap-[38px] px-6 py-36 bg-grey-95 rounded">
        <span className="mx-auto text-grey-50">
          {values.chemicalFamilyType
            ? 'No items yet.'
            : 'Please select a Group Type to get started.'}
        </span>
        <div className="flex mx-auto gap-3">
          <ChemicalFamilyButtons />
        </div>
      </div>
    );
  };

  const getUrl = (association: ChemicalFamilyAssociationAttributes) => {
    let baseUrl = '';

    switch (association.associableType) {
      case CHEMICAL_FAMILY_ASSOCIABLE_TYPES.INCI:
        baseUrl = ROUTES.SHOW_INCI.route;
        break;
      case CHEMICAL_FAMILY_ASSOCIABLE_TYPES.RAW_MATERIAL:
        baseUrl = ROUTES.SHOW_RAW_MATERIAL.route;
        break;
      case CHEMICAL_FAMILY_ASSOCIABLE_TYPES.CHEMICAL_FAMILY:
        baseUrl = ROUTES.SHOW_CHEMICAL_FAMILY.route;
        break;
      default:
        throw new Error('Invalid associable type provided'); // Handle unexpected types
      // This should never happen as the switch statement covers all known types
    }

    return baseUrl.replace(UUID_SHOW_ROUTE_STRING, association.associable.uuid);
  };

  // Desktop view of the chemical family table
  const renderDesktopTable = () => {
    return (
      <>
        <div className="grid grid-cols-chemicalFamilyAssociationsTable py-3 gap-12">
          {tableHeaders.map((header, idx) => (
            <div key={idx} className="flex">
              <div className="flex cursor-pointer items-center gap-1">
                <span className="text-sm">{header}</span>
                <ChevronDownIcon className="h-4 w-4 text-royal-50" />
              </div>
            </div>
          ))}
        </div>
        {values.chemicalFamilyAssociations.map(
          (chemicalFamilyAssociation, idx) => (
            <div
              key={idx}
              className="grid grid-cols-chemicalFamilyAssociationsTable items-center gap-12 py-4 text-grey-50 border-t border-grey-90"
            >
              <Tooltip
                title={
                  chemicalFamilyAssociation.name ||
                  chemicalFamilyAssociation.associable.name ||
                  ''
                }
              >
                <Link
                  to={getUrl(chemicalFamilyAssociation)}
                  target="_blank"
                  className="text-royal-50 flex gap-2 cursor-pointer overflow-hidden overflow-ellipsis whitespace-nowrap"
                >
                  {renderIcon(chemicalFamilyAssociation.associableType)}
                  {
                    <span className="overflow-hidden overflow-ellipsis whitespace-nowrap">
                      {chemicalFamilyAssociation.name
                        ? chemicalFamilyAssociation.name
                        : chemicalFamilyAssociation.associable.name}
                    </span>
                  }
                </Link>
              </Tooltip>
              <div className="text-grey-20 flex">
                {`${_.startCase(chemicalFamilyAssociation.relationshipType)} ${
                  chemicalFamilyAssociation.minAmount
                    ? `${chemicalFamilyAssociation.minAmount}%`
                    : chemicalFamilyAssociation.maxAmount
                    ? `${chemicalFamilyAssociation.maxAmount}%`
                    : ''
                }`}
              </div>
              <div className="">
                {new Date(
                  chemicalFamilyAssociation.createdAt
                ).toLocaleDateString()}
              </div>
              <div>
                {chemicalFamilyAssociation.createdByFullName
                  ? chemicalFamilyAssociation.createdByFullName
                  : `${chemicalFamilyAssociation.givenName} ${chemicalFamilyAssociation.familyName}`}
              </div>
              <div className="text-nowrap overflow-hidden overflow-ellipsis">
                <Tooltip
                  placement="bottom-start"
                  title={chemicalFamilyAssociation.notes || ''}
                >
                  <span>{chemicalFamilyAssociation.notes}</span>
                </Tooltip>
              </div>
              {mode !== MODES.VIEW && (
                <TrashIcon
                  onClick={() =>
                    handleDeleteAssocation(chemicalFamilyAssociation)
                  }
                  className="w-5 h-5 cursor-pointer"
                />
              )}
            </div>
          )
        )}
        {mode === MODES.EDIT && (
          <div
            onClick={() => handleDeleteAllAssociations()}
            className="flex gap-1 justify-end py-3 text-grey-50 text-sm mr-1"
          >
            <span className="underline cursor-pointer">Delete all</span>
            <TrashIcon className="h-5 w-5 text-grey-50 cursor-pointer " />
          </div>
        )}
      </>
    );
  };

  // Mobile view of the chemical family table
  const renderMobileTable = () => {
    return (
      <div className="flex flex-col gap-6">
        {values.chemicalFamilyAssociations.map((association) => (
          <div
            key={association.uuid}
            className="flex flex-col gap-3 border-b border-grey-90 py-4"
          >
            <div
              onClick={() => navigate(getUrl(association))}
              className="flex items-center gap-2"
            >
              {renderIcon(association.associableType)}
              <span className="text-royal-50 cursor-pointer">
                {association.name}
              </span>
            </div>
            <div className="flex flex-col gap-3 text-grey-50">
              <span className="text-grey-20">
                {_.startCase(association.relationshipType)}
              </span>
              <span>
                {new Date(association.createdAt).toLocaleDateString()}
              </span>
              <span>
                {association.createdByFullName
                  ? association.createdByFullName
                  : `${association.givenName} ${association.familyName}`}
              </span>
              <div className="flex justify-between items-end">
                <span className="w-4/5">{association.notes}</span>
                <TrashIcon className="h-6 w-6" />
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  };

  return (
    <>
      {isModalOpen && (
        <ChemicalFamilyModal
          chemicalFamilyType={values.chemicalFamilyType}
          userSession={userSession}
          handleClose={() => setIsModalOpen(false)}
          onSubmit={handleModalSubmission}
        />
      )}
      <div
        className={`max-w-[1440px] flex flex-col font-inter pr-6 pb-[50px] sm:pr-16 text-grey-20 mx-auto ${
          isLeftNavOpen && !isMobile ? 'pl-[303px]' : 'sm:pl-32 pl-6'
        }`}
      >
        {isMobile && (
          <div className="sticky top-0 z-50 w-full p-6 pl-12 mx-auto bg-white font-agipo">
            RM & INCI Groups
          </div>
        )}
        <div className="py-[21px] sm:mt-0 mt-[106px]">
          {mode === MODES.VIEW ? (
            <BreadcrumbMenu
              breadcrumbs={[
                { label: 'Groups', link: ROUTES.CHEMICAL_FAMILY_INDEX.route },
                {
                  label: _.startCase(values.chemicalFamilyType),
                  link: ROUTES.CHEMICAL_FAMILY_INDEX.route,
                },
                { label: values.name },
              ]}
            />
          ) : (
            <ChevronSelect
              error={Boolean(formik.errors.chemicalFamilyType)}
              options={CHEMICAL_FAMILY_TYPE_OPTIONS}
              placeholder={'Group Type'}
              selectedOption={
                CHEMICAL_FAMILY_TYPE_OPTIONS.find(
                  (option) =>
                    option.value === _.camelCase(values.chemicalFamilyType)
                ) || { label: '', value: '' }
              }
              onChange={(option) => {
                setFieldValue('chemicalFamilyType', option.value);
                // Null out company field if user is not choosing a client no no list
                if (option.value !== CHEMICAL_FAMILY_TYPES.CLIENT_NO_NO_LIST) {
                  setFieldValue('company', { name: '', uuid: '' });
                }
              }}
            />
          )}
        </div>
        <div className="flex flex-col sm:flex-row sm:justify-between mt-[158px] mb-16 gap-16">
          <div className="sm:w-1/2 flex flex-col gap-4">
            {mode === MODES.VIEW ? (
              <span className="font-agipo text-[48px]">{values.name}</span>
            ) : (
              <div>
                <TextField
                  additionalClasses="font-agipo border-none text-[48px] p-0 -mt-4 h-[60px]"
                  placeholder="Group Title"
                  rows={1}
                  {...getFieldProps('name')}
                />
                <hr
                  className={`${
                    formik.errors.name ? 'border-red-50' : 'border-grey-90'
                  }`}
                />
              </div>
            )}
            {_.camelCase(values.chemicalFamilyType) ===
            CHEMICAL_FAMILY_TYPES.CLIENT_NO_NO_LIST ? (
              mode === MODES.VIEW ? (
                <div>No-No List for: {values.company?.name}</div>
              ) : (
                <Autocomplete
                  key={'company-autocomplete'}
                  error={formik.errors.company?.name}
                  helperText={formik.errors.company?.name}
                  clearField={() =>
                    setFieldValue('company', { name: '', uuid: '' })
                  }
                  label="Company Name"
                  placeholder="Select the company this No-No list belongs to"
                  onChange={(option: Option) => {
                    setFieldValue('company.name', option.label);
                    setFieldValue('company.uuid', option.value);
                  }}
                  responseParser={(response) =>
                    response?.data.map((result: any) => ({
                      label: result.attributes.name,
                      value: result.id,
                    }))
                  }
                  required
                  search={searchCompanies}
                  value={{
                    label: values.company?.name,
                    value: values.company?.uuid,
                  }}
                />
              )
            ) : (
              ''
            )}
            {mode === MODES.VIEW ? (
              <span className="text-grey-50">{values.description}</span>
            ) : (
              <TextArea
                placeholder="Information about this group. Can only be seen by PML employees."
                additionalStyles="no-scrollbar"
                {...getFieldProps('description')}
              />
            )}
            <div
              className={`flex items-center ${
                mode === MODES.VIEW ? 'gap-1' : 'gap-3'
              }`}
            >
              {mode === MODES.VIEW ? (
                values.isVisibleToCustomers && (
                  <>
                    <CheckIcon className="h-4 w-4 text-green-40" />
                    <div className="flex gap-1 items-center">
                      <span>Visible to customers</span>
                    </div>
                  </>
                )
              ) : (
                <>
                  <Switch
                    isChecked={values.isVisibleToCustomers}
                    handleChange={() =>
                      setFieldValue(
                        'isVisibleToCustomers',
                        !values.isVisibleToCustomers
                      )
                    }
                    size="lg"
                  />
                  <div className="flex gap-1 items-center">
                    <span>Visible to customers</span>
                    <Tooltip title="If toggled on, customers can select this group in the Must-Have and/or Restricted Ingredients section of the Project Builder Form.">
                      <InformationCircleIcon className="w-4 h-4 text-grey-50" />
                    </Tooltip>
                  </div>
                </>
              )}
            </div>
            {mode === MODES.VIEW ? (
              <span className="text-royal-50 cursor-pointer underline">
                {values.referenceUrl}
              </span>
            ) : (
              <TextField
                error={formik.errors.referenceUrl}
                helperText={formik.errors.referenceUrl}
                placeholder="http://"
                trailingIcon={
                  values.referenceUrl.length ? (
                    <XCircleIcon
                      onClick={() => setFieldValue('referenceUrl', '')}
                      className="h-5 w-5 cursor-pointer"
                    />
                  ) : null
                }
                {...getFieldProps('referenceUrl')}
              />
            )}
          </div>
          {mode === MODES.VIEW ? (
            <Button
              leadingIcon={<PencilIcon className="h-6 w-6 text-royal-50" />}
              onClick={() => setMode(MODES.EDIT)}
              size="large"
              type="secondary"
              text="Edit"
            />
          ) : hasAssociations ? (
            <div className="flex gap-3">
              <Button
                leadingIcon={<ArrowUpTrayIcon className="h-6 w-6" />}
                size="large"
                type="secondary"
                text="Import list..."
              />
              <Button
                leadingIcon={<PlusIcon className="h-6 w-6" />}
                onClick={() => setIsModalOpen(true)}
                size="large"
                type="primary"
                text="Add new..."
              />
            </div>
          ) : null}
        </div>
        {isLoading ? (
          <div className="flex mx-auto">
            <Spinner />
          </div>
        ) : !hasAssociations ? (
          renderEmptyState()
        ) : isMobile ? (
          renderMobileTable()
        ) : (
          renderDesktopTable()
        )}
        {mode === MODES.EDIT && (
          <div className="mt-16">
            <Button
              onClick={() => handleDeleteChemicalFamily()}
              leadingIcon={<TrashIcon className="h-6 w-6" />}
              size="large"
              text="Delete Group"
              type="urgentSecondary"
            />
          </div>
        )}
      </div>
      {mode !== MODES.VIEW && (
        <BottomBar
          buttons={[
            <Button
              onClick={() => {
                formik.handleReset(initialValues);
                setMode(MODES.VIEW);
              }}
              size="large"
              text="Cancel"
              type="secondary"
            />,
            <Button
              onClick={() => formik.handleSubmit()}
              size="large"
              text="Save"
              type="primary"
            />,
          ]}
        />
      )}
    </>
  );
};
