// Librares
import React, { useState } from 'react';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/16/solid';

interface ChevronSelectProps {
  error?: boolean;
  options: Option[];
  placeholder: string;
  selectedOption: Option;
  onChange: (option: Option) => void;
}

export const ChevronSelect: React.FC<ChevronSelectProps> = ({
  error,
  options,
  placeholder,
  selectedOption,
  onChange,
}) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
    <div className="relative text-grey-50 font-inter text-sm cursor-pointer min-w-[190px] w-fit-content">
      <div
        onClick={() => setIsMenuOpen(!isMenuOpen)}
        className={`flex items-center gap-1 ${error && 'text-red-50'}`}
      >
        {selectedOption.label || placeholder}{' '}
        {isMenuOpen ? (
          <ChevronUpIcon className="w-4 h-4" />
        ) : (
          <ChevronDownIcon className="w-4 h-4" />
        )}
      </div>
      {isMenuOpen && (
        <div className="absolute -left-[3px] flex flex-col bg-white rounded shadow-md min-w-[190px] w-fit-content">
          {options.map((option) => (
            <div
              key={option.value}
              className="p-3 hover:bg-blue-95"
              onClick={() => {
                onChange(option);
                setIsMenuOpen(false);
              }}
            >
              {option.label}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
