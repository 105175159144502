export const AUTH0_ERROR_CODES = {
  ERROR_UNVERIFIED_EMAIL: '0001-unverified-email',
  ERROR_UNAUTHORIZED_DOMAIN: '0002-unauthorized-domain',
};

export const ROLES = {
  EMPLOYEE: 'employee',
  CUSTOMER: 'customer',
  SENIOR_SALES_REP: 'senior sales rep',
};

export const PERMISSIONS = {
  ARCHIVE_INCI: 'archive:inci',
  ARCHIVE_FORMULA: 'archive:formula',
  LOCK_FORMULA: 'lock:formula',
  UNLOCK_FORMULA: 'unlock:formula',
  SET_TO_IN_PRODUCTION: 'set-to-in-production:formula',
  VISIT_AUTHORIZED_APPLICATION: 'visit:authorized-application',
  VISIT_EMPLOYEE_PAGE: 'visit:employee-page',
  VISIT_BETA_FORMULA: 'visit:beta-formula',
  ASSIGN_BRIEFS: 'assign:briefs',
  CREATE_PROJECT_FORMULA: 'create:project-formula',
  VERIFY_INCIS: 'verify:incis',
  UPDATE_VERIFIED_INCIS: 'update:verified-incis',
  VISIT_PRICING_QUOTE: 'visit:pricing-quote',
  OVERRIDE_PRICING_QUOTE: 'override:pricing-quote',
  VISIT_PRICE_QUOTE: 'visit:price-quote',
  VISIT_RTL_FORMULA_CATALOGUE: 'visit:rtl-formula-catalogue',
  VISIT_CHEMICAL_FAMILY: 'visit:chemical-family',
  PURCHASING_APPROVER: 'approve:purchasing',
  OPERATIONS_APPROVER: 'approve:operations',
  MANAGEMENT_APPROVER: 'approve:management',
};
