// Libraries
import React, { useEffect, useState } from 'react';
import { useFormikContext } from 'formik';
import _ from 'lodash';
// Components
import { SectionHeader } from '../section-header.component';
import { DoubleButton, TextField } from 'design-system';
// Utils
import { SectionProps } from './types';
import { SIZES, TankSizeUnit, WorksheetFormValues } from '../worksheet.types';
// Constants
import {
  laborCostInputs,
  NUMBER_OF_BATCHES_TITLE,
  TANK_SIZE_TITLE,
  TANK_SIZE_UNIT_TOGGLE_INPUT,
  WORKSHEET_FORM_KEYS,
} from '../constants';
import { highlightInput } from '../utils';
import { QuoteCalculator } from 'features/price-quote';

const getTankAndBatchInputKeysFromSizeIndex = (selectedSizeIndex: number) => {
  switch (selectedSizeIndex) {
    case SIZES.ONE:
      return {
        tankOneKey: WORKSHEET_FORM_KEYS.SIZE_ONE_TANK_SIZE_ONE,
        tankTwoKey: WORKSHEET_FORM_KEYS.SIZE_ONE_TANK_SIZE_TWO,
        tankThreeKey: WORKSHEET_FORM_KEYS.SIZE_ONE_TANK_SIZE_THREE,
        numberOfBatchesOneKey:
          WORKSHEET_FORM_KEYS.SIZE_ONE_NUMBER_OF_BATCHES_ONE,
        numberOfBatchesTwoKey:
          WORKSHEET_FORM_KEYS.SIZE_ONE_NUMBER_OF_BATCHES_TWO,
        numberOfBatchesThreeKey:
          WORKSHEET_FORM_KEYS.SIZE_ONE_NUMBER_OF_BATCHES_THREE,
      };
    case SIZES.TWO:
      return {
        tankOneKey: WORKSHEET_FORM_KEYS.SIZE_TWO_TANK_SIZE_ONE,
        tankTwoKey: WORKSHEET_FORM_KEYS.SIZE_TWO_TANK_SIZE_TWO,
        tankThreeKey: WORKSHEET_FORM_KEYS.SIZE_TWO_TANK_SIZE_THREE,
        numberOfBatchesOneKey:
          WORKSHEET_FORM_KEYS.SIZE_TWO_NUMBER_OF_BATCHES_ONE,
        numberOfBatchesTwoKey:
          WORKSHEET_FORM_KEYS.SIZE_TWO_NUMBER_OF_BATCHES_TWO,
        numberOfBatchesThreeKey:
          WORKSHEET_FORM_KEYS.SIZE_TWO_NUMBER_OF_BATCHES_THREE,
      };
    case SIZES.THREE:
      return {
        tankOneKey: WORKSHEET_FORM_KEYS.SIZE_THREE_TANK_SIZE_ONE,
        tankTwoKey: WORKSHEET_FORM_KEYS.SIZE_THREE_TANK_SIZE_TWO,
        tankThreeKey: WORKSHEET_FORM_KEYS.SIZE_THREE_TANK_SIZE_THREE,
        numberOfBatchesOneKey:
          WORKSHEET_FORM_KEYS.SIZE_THREE_NUMBER_OF_BATCHES_ONE,
        numberOfBatchesTwoKey:
          WORKSHEET_FORM_KEYS.SIZE_THREE_NUMBER_OF_BATCHES_TWO,
        numberOfBatchesThreeKey:
          WORKSHEET_FORM_KEYS.SIZE_THREE_NUMBER_OF_BATCHES_THREE,
      };
    default:
      return {
        tankOneKey: WORKSHEET_FORM_KEYS.TANK_SIZE_ONE,
        tankTwoKey: WORKSHEET_FORM_KEYS.TANK_SIZE_TWO,
        tankThreeKey: WORKSHEET_FORM_KEYS.TANK_SIZE_THREE,
        numberOfBatchesOneKey: WORKSHEET_FORM_KEYS.NUMBER_OF_BATCHES_ONE,
        numberOfBatchesTwoKey: WORKSHEET_FORM_KEYS.NUMBER_OF_BATCHES_TWO,
        numberOfBatchesThreeKey: WORKSHEET_FORM_KEYS.NUMBER_OF_BATCHES_THREE,
      };
  }
};

export const LaborSection: React.FC<SectionProps> = ({
  approval,
  handleApprovalClick,
  isActive,
  isApprover,
  isPriceQuoteEditable,
  selectedSizeIndex,
}) => {
  const [tankSizeUnit, setTankSizeUnit] = useState<keyof typeof TankSizeUnit>(
    TankSizeUnit.pounds
  );
  const { values, setValues, getFieldProps, setFieldValue } = useFormikContext<
    WorksheetFormValues
  >();

  const {
    tankOneKey,
    tankTwoKey,
    tankThreeKey,
    numberOfBatchesOneKey,
    numberOfBatchesTwoKey,
    numberOfBatchesThreeKey,
  } = getTankAndBatchInputKeysFromSizeIndex(selectedSizeIndex!);

  const tankSizeInLbsOne = values[tankOneKey];
  const tankSizeInLbsTwo = values[tankTwoKey];
  const tankSizeInLbsThree = values[tankThreeKey];

  const [gallonInputs, setGallonInputs] = useState([
    {
      value: QuoteCalculator.convertTankSizeToUnit({
        size: tankSizeInLbsOne || 0, // in pounds
        unit: TankSizeUnit.gallons,
      }),
    },
    {
      value: QuoteCalculator.convertTankSizeToUnit({
        size: tankSizeInLbsTwo || 0, // in pounds
        unit: TankSizeUnit.gallons,
      }),
    },
    {
      value: QuoteCalculator.convertTankSizeToUnit({
        size: tankSizeInLbsThree || 0, // in pounds
        unit: TankSizeUnit.gallons,
      }),
    },
  ]);

  useEffect(() => {
    setGallonInputs([
      {
        value: QuoteCalculator.convertTankSizeToUnit({
          size: tankSizeInLbsOne || 0, // in pounds
          unit: TankSizeUnit.gallons,
        }),
      },
      {
        value: QuoteCalculator.convertTankSizeToUnit({
          size: tankSizeInLbsTwo || 0, // in pounds
          unit: TankSizeUnit.gallons,
        }),
      },
      {
        value: QuoteCalculator.convertTankSizeToUnit({
          size: tankSizeInLbsThree || 0, // in pounds
          unit: TankSizeUnit.gallons,
        }),
      },
    ]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSizeIndex]);

  const renderTankInputs = ({
    index,
    leadingText,
    trailingText,
  }: {
    index: number;
    leadingText?: string;
    trailingText?: string | ((unit: keyof typeof TankSizeUnit) => string);
  }) => {
    if (tankSizeUnit === TankSizeUnit.gallons) {
      const { value } = gallonInputs[index];

      return (
        <TextField
          type="number"
          value={value}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            const tankKeys = [tankOneKey, tankTwoKey, tankThreeKey];

            setFieldValue(
              tankKeys[index],
              // convert from gallons to pounds
              QuoteCalculator.convertTankSizeToUnit({
                size: (e.target.value as any) || 0,
                unit: TankSizeUnit.pounds,
              })
            );

            setGallonInputs(
              gallonInputs.map((gallonInput, idx) => {
                return idx === index
                  ? {
                      ...gallonInput,
                      value: e.target.value as any,
                    }
                  : gallonInput;
              })
            );
          }}
          disabled={!isPriceQuoteEditable}
          onFocus={highlightInput}
          height="h-11"
          leadingText={leadingText}
          trailingText={
            _.isFunction(trailingText)
              ? trailingText(tankSizeUnit)
              : trailingText
          }
        />
      );
    } else {
      const props = getFieldProps(
        index === 0 ? tankOneKey : index === 1 ? tankTwoKey : tankThreeKey
      );

      return (
        <TextField
          type="number"
          {...props}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setGallonInputs(
              gallonInputs.map((gallonInput, idx) => {
                return idx === index
                  ? {
                      ...gallonInput,
                      // convert from pounds to gallons
                      value: QuoteCalculator.convertTankSizeToUnit({
                        size: (e.target.value as any) || 0,
                        unit: TankSizeUnit.gallons,
                      }),
                    }
                  : gallonInput;
              })
            );

            props.onChange(e);
          }}
          disabled={!isPriceQuoteEditable}
          onFocus={highlightInput}
          height="h-11"
          leadingText={leadingText}
          trailingText={
            _.isFunction(trailingText)
              ? trailingText(tankSizeUnit)
              : trailingText
          }
        />
      );
    }
  };

  const renderLaborInputs = ({
    title,
    formikKey,
    index,
    leadingText,
    trailingText,
  }: {
    title: string;
    formikKey: string;
    index: number;
    leadingText?: string;
    trailingText?: string | ((unit: keyof typeof TankSizeUnit) => string);
  }) => {
    switch (title) {
      case TANK_SIZE_TITLE:
        return renderTankInputs({
          index,
          leadingText,
          trailingText,
        });

      case NUMBER_OF_BATCHES_TITLE:
        const props = getFieldProps(
          index === 0
            ? numberOfBatchesOneKey
            : index === 1
            ? numberOfBatchesTwoKey
            : numberOfBatchesThreeKey
        );

        return (
          <TextField
            type="number"
            {...props}
            disabled={!isPriceQuoteEditable}
            onFocus={highlightInput}
            height="h-11"
            leadingText={leadingText}
            trailingText={trailingText}
          />
        );

      default:
        return (
          <TextField
            type="number"
            {...getFieldProps(formikKey)}
            disabled={!isPriceQuoteEditable}
            onFocus={highlightInput}
            height="h-11"
            leadingText={leadingText}
            trailingText={trailingText}
          />
        );
    }
  };

  useEffect(() => {
    const unitSizeInOz =
      selectedSizeIndex === SIZES.ONE
        ? values.sizeOneQuantity
        : selectedSizeIndex === SIZES.TWO
        ? values.sizeTwoQuantity
        : values.sizeThreeQuantity;

    setValues({
      ...values,
      [numberOfBatchesOneKey]: QuoteCalculator.calculateNumberOfBatches({
        unitSizeInOz,
        numOfUnits: values.orderQuantityOne,
        tankSizeInLbs: tankSizeInLbsOne,
      }),
      [numberOfBatchesTwoKey]: QuoteCalculator.calculateNumberOfBatches({
        unitSizeInOz,
        numOfUnits: values.orderQuantityTwo,
        tankSizeInLbs: tankSizeInLbsTwo,
      }),
      [numberOfBatchesThreeKey]: QuoteCalculator.calculateNumberOfBatches({
        unitSizeInOz,
        numOfUnits: values.orderQuantityThree,
        tankSizeInLbs: tankSizeInLbsThree,
      }),
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    values.fillSizeOne,
    values.fillSizeTwo,
    values.fillSizeThree,
    values.orderQuantityOne,
    values.orderQuantityTwo,
    values.orderQuantityThree,
    tankSizeInLbsOne,
    tankSizeInLbsTwo,
    tankSizeInLbsThree,
  ]);

  return (
    <div
      className={`${isActive ? 'h-auto' : 'h-0 overflow-hidden'} flex flex-col`}
    >
      <SectionHeader
        approval={approval}
        isApprover={isApprover}
        handleApprovalClick={handleApprovalClick}
        title="Labor"
        checkboxLabel="Operations Review Complete"
      />
      <div className="px-3 bg-white">
        {laborCostInputs.map(
          (
            laborCost: {
              title: string;
              formikKeys?: Maybe<string>[];
              leadingText?: string;
              trailingText?:
                | string
                | ((unit: keyof typeof TankSizeUnit) => string);
            },
            index: number
          ) => {
            const { title, formikKeys, leadingText, trailingText } = laborCost;

            return (
              <div
                key={title + index}
                className={`grid grid-cols-10 items-center min-h-[44px]
                ${index === 0 ? 'mt-4' : 'py-[6px]'}`}
              >
                <span className="col-span-2 text-grey-50">{title}</span>
                {formikKeys &&
                  formikKeys.map((formikKey: Maybe<string>, index: number) => {
                    if (formikKey === TANK_SIZE_UNIT_TOGGLE_INPUT) {
                      return (
                        <DoubleButton
                          key={'tank-size-unit-toggle'}
                          activeButton={
                            tankSizeUnit === TankSizeUnit.pounds
                              ? 'buttonOne'
                              : 'buttonTwo'
                          }
                          buttonOneAction={() =>
                            setTankSizeUnit(TankSizeUnit.pounds)
                          }
                          buttonOneText="Pounds"
                          buttonTwoAction={() =>
                            setTankSizeUnit(TankSizeUnit.gallons)
                          }
                          buttonTwoText="Gallons"
                          size="medium"
                          type="soft"
                        />
                      );
                    }

                    return (
                      <div
                        key={(formikKey ?? '') + index}
                        className="col-span-2 mr-6"
                      >
                        {renderLaborInputs({
                          title,
                          formikKey: formikKey!,
                          index,
                          leadingText,
                          trailingText,
                        })}
                      </div>
                    );
                  })}
              </div>
            );
          }
        )}
      </div>
    </div>
  );
};
