// Libraries
import React from 'react';
import { PencilSquareIcon } from '@heroicons/react/16/solid';
import { useFormikContext } from 'formik';
// Components
import { Button, TextField } from 'design-system';
// Utils
import { SectionProps } from './types';
// Constants
import { SECTIONS } from '../constants';
import { WorksheetFormValues } from '../worksheet.types';
import { highlightInput } from '../utils';

interface OrderQuantitySectionProps extends SectionProps {
  data: any;
  isPriceQuoteEditable: boolean;
  setActiveSection: React.Dispatch<React.SetStateAction<string | null>>;
  onCancel: () => void;
  onSubmit: () => void;
}

export const OrderQuantitySection: React.FC<OrderQuantitySectionProps> = ({
  data,
  isActive,
  isPriceQuoteEditable,
  setActiveSection,
  onCancel,
  onSubmit,
}) => {
  const { getFieldProps, values } = useFormikContext<WorksheetFormValues>();

  return (
    <div
      className={`grid grid-cols-10 items-center px-3 border bg-white border-b-black border-l-0 border-r-0 border-t-0 rounded-tl rounded-tr ${
        isActive ? 'py-2' : 'py-4'
      }`}
    >
      <span className="col-span-2 text-grey-50">{data.title}</span>
      {data.formikKeys.map((formikKey: string, index: number) => {
        return isActive ? (
          <div className="col-span-2 mr-6" key={formikKey + index}>
            <TextField
              {...getFieldProps(formikKey)}
              onFocus={highlightInput}
              height="h-11"
              type="number"
            />
          </div>
        ) : (
          <span
            key={formikKey + index}
            className={`${data.isTopSection && 'font-semibold'} col-span-2`}
          >
            {(values as { [x: string]: any })[formikKey].toLocaleString(
              'en-US'
            )}
          </span>
        );
      })}
      <div className="col-start-10 flex items-center justify-end cursor-pointer">
        {isActive ? (
          <div className="flex gap-[5px]">
            <Button onClick={onCancel} type="secondary" text="Cancel" />
            <Button type="primary" text="Save" onClick={onSubmit} />
          </div>
        ) : (
          isPriceQuoteEditable && (
            <PencilSquareIcon
              onClick={() => setActiveSection(SECTIONS.ORDER_QTY)}
              className=" h-4 w-4 text-royal-50"
            />
          )
        )}
      </div>
    </div>
  );
};
