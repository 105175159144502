// Libraries
import React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
// Components
import {
  Autocomplete,
  Button,
  Modal,
  Select,
  TextArea,
  TextField,
} from 'design-system';
// Utils
import { InciChip, InciFamilyChip, RmChip } from './chips';
import { useApi } from 'api';
import { UserSession } from 'context';
// Constants
import {
  CHEMICAL_FAMILY_ASSOCIABLE_TYPES,
  CHEMICAL_FAMILY_RELATIONSHIP_TYPES,
  CHEMICAL_FAMILY_RELATIONSHIP_TYPE_OPTIONS,
  CHEMICAL_FAMILY_TYPES,
} from './constants';

interface ChemicalFamilyModalProps {
  chemicalFamilyType: string;
  handleClose: () => void;
  onSubmit: (values: any) => void;
  userSession: UserSession;
}

export type ChemicalFamilyModalFormValues = {
  associable: {
    name: string;
    uuid: string;
  };
  associableType: string;
  createdAt: Date;
  familyName: string;
  givenName: string;
  maxAmount: number | undefined;
  minAmount: number | undefined;
  notes: string;
  relationshipType: string;
  userUuid: string;
};

export const ChemicalFamilyModal: React.FC<ChemicalFamilyModalProps> = ({
  chemicalFamilyType,
  handleClose,
  onSubmit,
  userSession,
}) => {
  const { searchIncis, searchRawMaterials, searchChemicalFamilies } = useApi();
  const { givenName, familyName, uuid } = userSession;
  const userUuid = uuid;

  const initialValues = {
    associable: {
      name: '',
      uuid: '',
    },
    associableType: CHEMICAL_FAMILY_ASSOCIABLE_TYPES.INCI,
    createdAt: new Date(),
    familyName,
    givenName,
    maxAmount: undefined,
    minAmount: undefined,
    notes: '',
    relationshipType: '',
    userUuid,
  } as ChemicalFamilyModalFormValues;

  const formik = useFormik({
    initialValues,
    validationSchema: Yup.object({
      associable: Yup.object().shape({
        name: Yup.string().required(
          'Please select an item to add to this group'
        ),
        uuid: Yup.string().required(
          'Please select an item to add to this group'
        ),
      }),
      relationshipType: Yup.string().required('Please select a parameter'),
      maxAmount: Yup.number().when('relationshipType', {
        is: (val: string) =>
          val === CHEMICAL_FAMILY_RELATIONSHIP_TYPES.MAX_AMOUNT,
        then: Yup.number().required('Please enter an amount'),
      }),
      minAmount: Yup.number().when('relationshipType', {
        is: (val: string) =>
          val === CHEMICAL_FAMILY_RELATIONSHIP_TYPES.MIN_AMOUNT,
        then: Yup.number().required('Please enter an amount'),
      }),
    }).shape({}),
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: (values) => {
      // Handle form submission
      onSubmit(values);
    },
  });

  const { values, getFieldProps, setFieldValue } = formik;

  const relationshipTypeIsMinOrMaxAmount =
    values.relationshipType === CHEMICAL_FAMILY_RELATIONSHIP_TYPES.MIN_AMOUNT ||
    values.relationshipType === CHEMICAL_FAMILY_RELATIONSHIP_TYPES.MAX_AMOUNT;

  const renderAutocomplete = () => {
    switch (values.associableType) {
      case CHEMICAL_FAMILY_ASSOCIABLE_TYPES.INCI:
        return (
          <Autocomplete
            key={'inci-autocomplete'}
            error={formik.errors.associable?.name}
            helperText={formik.errors.associable?.name}
            clearField={() => {
              setFieldValue('associable.uuid', initialValues.associable.uuid);
              setFieldValue('associable.name', initialValues.associable.name);
            }}
            label="INCI Name"
            placeholder="Search"
            onChange={(option: Option) => {
              setFieldValue('associable.uuid', option.value);
              setFieldValue('associable.name', option.label);
            }}
            responseParser={(response) =>
              response?.results.map((result: any) => ({
                label:
                  result._source.us_name ||
                  result._source.name ||
                  result._source.inci_name ||
                  '',
                value: result._source.uuid,
              }))
            }
            search={searchIncis}
          />
        );
      case CHEMICAL_FAMILY_ASSOCIABLE_TYPES.RAW_MATERIAL:
        return (
          <Autocomplete
            key={'rm-autocomplete'}
            error={formik.errors.associable?.name}
            helperText={formik.errors.associable?.name}
            clearField={() => {
              setFieldValue('associable.uuid', initialValues.associable.uuid);
              setFieldValue('associable.name', initialValues.associable.name);
            }}
            label="Raw Material Name"
            placeholder="Search"
            onChange={(option: Option) => {
              setFieldValue('associable.uuid', option.value);
              setFieldValue('associable.name', option.label);
            }}
            responseParser={(response) =>
              response.data.map((result: any) => ({
                label: result.attributes.name,
                value: result.id,
              }))
            }
            search={searchRawMaterials}
          />
        );
      case CHEMICAL_FAMILY_ASSOCIABLE_TYPES.CHEMICAL_FAMILY:
        return (
          <Autocomplete
            key={'chemical-family-autocomplete'}
            error={formik.errors.associable?.name}
            helperText={formik.errors.associable?.name}
            clearField={() =>
              setFieldValue('associable.uuid', initialValues.associable.uuid)
            }
            label="INCI Family Name"
            placeholder="Search"
            onChange={(option: Option) => {
              setFieldValue('associable.uuid', option.value);
              setFieldValue('associable.name', option.label);
            }}
            responseParser={(response) =>
              response?.data.map((result: any) => ({
                label: result.attributes.name,
                value: result.id,
              }))
            }
            search={searchChemicalFamilies}
          />
        );
      default:
        break;
    }
  };

  const renderChips = () => {
    return (
      <>
        {InciChip({
          onClick: () => {
            if (
              values.associableType !== CHEMICAL_FAMILY_ASSOCIABLE_TYPES.INCI
            ) {
              setFieldValue('associable.uuid', initialValues.associable.uuid); // Reset the value when switching
              setFieldValue(
                'associableType',
                CHEMICAL_FAMILY_ASSOCIABLE_TYPES.INCI
              );
            }
          },
          selected:
            values.associableType === CHEMICAL_FAMILY_ASSOCIABLE_TYPES.INCI,
        })}
        {RmChip({
          onClick: () => {
            if (
              values.associableType !==
              CHEMICAL_FAMILY_ASSOCIABLE_TYPES.RAW_MATERIAL
            ) {
              setFieldValue('associable.uuid', initialValues.associable.uuid); // Reset the value when switching
              setFieldValue(
                'associableType',
                CHEMICAL_FAMILY_ASSOCIABLE_TYPES.RAW_MATERIAL
              );
            }
          },
          selected:
            values.associableType ===
            CHEMICAL_FAMILY_ASSOCIABLE_TYPES.RAW_MATERIAL,
        })}
        {chemicalFamilyType !== CHEMICAL_FAMILY_TYPES.INCI_FAMILY &&
          InciFamilyChip({
            onClick: () => {
              if (
                values.associableType !==
                CHEMICAL_FAMILY_ASSOCIABLE_TYPES.CHEMICAL_FAMILY
              ) {
                setFieldValue('associable.uuid', initialValues.associable.uuid); // Reset the value when switching
                setFieldValue(
                  'associableType',
                  CHEMICAL_FAMILY_ASSOCIABLE_TYPES.CHEMICAL_FAMILY
                );
              }
            },
            selected:
              values.associableType ===
              CHEMICAL_FAMILY_ASSOCIABLE_TYPES.CHEMICAL_FAMILY,
          })}
      </>
    );
  };

  return (
    <Modal handleClose={() => null}>
      <div className="w-full sm:w-[687px] flex flex-col">
        <div className="flex flex-col p-6 gap-6 bg-white">
          <span className="font-semibold">Add new:</span>
          <div className="flex flex-col sm:flex-row gap-3 sm:gap-6">
            {renderChips()}
          </div>
          {renderAutocomplete()}
          <div className="flex flex-col gap-3 sm:flex-row sm:justify-between">
            <Select
              error={formik.errors.relationshipType}
              helperText={formik.errors.relationshipType}
              placeholder="Select"
              label="Parameter"
              options={CHEMICAL_FAMILY_RELATIONSHIP_TYPE_OPTIONS}
              onChange={(option: Option) =>
                setFieldValue('relationshipType', option.value)
              }
              value={values.relationshipType}
              width={`${
                relationshipTypeIsMinOrMaxAmount
                  ? 'w-full'
                  : 'sm:w-[313.5px] w-auto'
              }`}
            />
            {values.relationshipType ===
              CHEMICAL_FAMILY_RELATIONSHIP_TYPES.MAX_AMOUNT && (
              <TextField
                error={formik.errors.maxAmount}
                helperText={formik.errors.maxAmount}
                label="Amount"
                {...getFieldProps('maxAmount')}
                trailingText="%"
                trailingTextColor="text-grey-20"
                type="number"
              />
            )}
            {values.relationshipType ===
              CHEMICAL_FAMILY_RELATIONSHIP_TYPES.MIN_AMOUNT && (
              <TextField
                error={formik.errors.minAmount}
                helperText={formik.errors.minAmount}
                label="Amount"
                {...getFieldProps('minAmount')}
                trailingText="%"
                trailingTextColor="text-grey-20"
                type="number"
              />
            )}
          </div>
          <TextArea rows={5} label="Notes" {...getFieldProps('notes')} />
        </div>
        <div className="flex justify-end p-4 bg-white border-t border-grey-90">
          <div className="flex gap-3">
            <Button
              size="large"
              type="secondary"
              text="Cancel"
              onClick={handleClose}
            />
            <Button
              onClick={formik.handleSubmit}
              size="large"
              type="primary"
              text="Add"
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};
