// Libraries
import React, { useCallback, useContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  ChevronDownIcon,
  ChevronUpIcon,
  MagnifyingGlassIcon,
  PlusIcon,
  QueueListIcon,
} from '@heroicons/react/16/solid';
import _ from 'lodash';
import format from 'date-fns/format';
// Components
import {
  Button,
  Chip,
  Pagination,
  Select,
  Spinner,
  TextField,
} from 'design-system';
// Utils
import { useApi } from 'api';
import { LeftNavContext, MobileContext } from 'context';
import { IApiData, ChemicalFamilyAttributes } from 'api';
// Constants
import { ROUTES, UUID_SHOW_ROUTE_STRING } from 'features/navigation';
import {
  CHEMICAL_FAMILY_TYPE_OPTIONS,
  CHEMICAL_FAMILY_TABLE_SORT_OPTIONS,
} from './constants';

const ENTRIES_PER_PAGE = 10;

export const ChemicalFamilyIndex = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const [chemicalFamilies, setChemicalFamilies] = useState<
    IApiData<ChemicalFamilyAttributes>[]
  >([]);
  const [
    userInitiatedSearchCompleted,
    setUserInitiatedSearchCompleted,
  ] = useState(false);
  const [totalResults, setTotalResults] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedFilterChips, setSelectedFilterChips] = useState<string[]>([]);
  const [sortOption, setSortOption] = useState('name');
  const { searchChemicalFamilies } = useApi();
  const { isLeftNavOpen } = useContext(LeftNavContext);
  const { isMobile } = useContext(MobileContext);
  const navigate = useNavigate();

  // eslint-disable-next-line
  const debouncedSearch = useCallback(
    _.debounce((query) => {
      setIsLoading(true);

      searchChemicalFamilies({
        query: encodeURIComponent(query),
        urlParams: `&page=${currentPage}&perPage=${ENTRIES_PER_PAGE}&filters=${selectedFilterChips
          .map((chip) => _.snakeCase(chip))
          .join(',')}&sort=${sortOption}`,
        handleSuccess: (response) => {
          setChemicalFamilies(response.data);
          setTotalResults(response.meta?.total || 0);
        },
        handleFinally: () => {
          setIsLoading(false);
          if (query) setUserInitiatedSearchCompleted(true);
        },
      });
    }, 500),
    [currentPage, sortOption, searchChemicalFamilies, selectedFilterChips]
  );

  useEffect(() => {
    setCurrentPage(1);
  }, [searchQuery]);

  useEffect(() => {
    debouncedSearch(searchQuery);
  }, [searchQuery, debouncedSearch]);

  const sortedChemicalFamilies = chemicalFamilies.sort((a, b) => {
    if (sortOption === 'name') {
      return a.attributes.name.localeCompare(b.attributes.name);
    }
    if (sortOption === 'type') {
      return a.attributes.chemicalFamilyType.localeCompare(
        b.attributes.chemicalFamilyType
      );
    }
    if (sortOption === 'addedBy') {
      return a.attributes.createdByFullName.localeCompare(
        b.attributes.createdByFullName
      );
    }
    if (sortOption === 'lastModified') {
      return (
        // eslint-disable-line
        new Date(b.attributes.updatedAt).getTime() -
        new Date(a.attributes.updatedAt).getTime()
      );
    }
    return 0;
  });

  const parseChemicalFamilyType = (type: string) => {
    return CHEMICAL_FAMILY_TYPE_OPTIONS.map((option: Option) => {
      return option.value === _.camelCase(type) ? option.label : '';
    });
  };

  const renderDesktopTable = () => {
    return (
      <>
        <div className="grid grid-cols-chemicalFamilyIndexTable py-3 gap-12">
          {CHEMICAL_FAMILY_TABLE_SORT_OPTIONS.map(
            (header: Option, idx: number) => (
              <div
                onClick={() => setSortOption(header.value)}
                key={header.value}
                className={`flex ${
                  idx === CHEMICAL_FAMILY_TABLE_SORT_OPTIONS.length - 1
                    ? 'ml-auto'
                    : ''
                } ${idx === 1 ? '-ml-10' : ''}`}
              >
                <div className="flex cursor-pointer items-center gap-1">
                  <span className="text-sm">{header.label}</span>
                  {idx !== 0 &&
                    (sortOption !== header.value ? (
                      <ChevronDownIcon className="h-4 w-4 text-royal-50" />
                    ) : (
                      <ChevronUpIcon className="h-4 w-4 text-royal-50" />
                    ))}
                </div>
              </div>
            )
          )}
        </div>
        {sortedChemicalFamilies ? (
          sortedChemicalFamilies.map((chemicalFamily) => (
            <div
              onClick={() =>
                navigate(
                  ROUTES.SHOW_CHEMICAL_FAMILY.route.replace(
                    UUID_SHOW_ROUTE_STRING,
                    chemicalFamily.id
                  )
                )
              }
              key={chemicalFamily.attributes.name}
              className="grid grid-cols-chemicalFamilyIndexTable items-center gap-12 py-4 cursor-pointer text-grey-50 border-t border-grey-90 hover:bg-blue-90"
            >
              <div>
                <QueueListIcon className="w-4 h-4 text-peach-40" />
              </div>
              <div className="text-royal-50 -ml-10">
                {chemicalFamily.attributes.name}
              </div>
              <div className="text-grey-20">
                {parseChemicalFamilyType(
                  chemicalFamily.attributes.chemicalFamilyType
                )}
              </div>
              <div>{chemicalFamily.attributes.createdByFullName}</div>
              <div className="ml-auto">
                {format(
                  new Date(chemicalFamily.attributes.updatedAt),
                  'MM/dd/yy'
                )}
              </div>
            </div>
          ))
        ) : (
          <div className="flex justify-center items-center">
            <Spinner />
          </div>
        )}
      </>
    );
  };

  const renderMobileTable = () => {
    return isLoading ? (
      <div className="flex justify-center items-center">
        <Spinner />
      </div>
    ) : (
      <div className="flex flex-col gap-6">
        {sortedChemicalFamilies.map((chemicalFamily) => (
          <div
            key={chemicalFamily.attributes.name}
            className="flex flex-col gap-3 border-b border-grey-90 py-4"
          >
            <div className="flex items-center gap-2">
              <QueueListIcon className="w-4 h-4 text-peach-40" />
              <span className="text-royal-50 cursor-pointer">
                {chemicalFamily.attributes.name}
              </span>
            </div>
            <div className="flex flex-col gap-3">
              <span>
                {parseChemicalFamilyType(
                  chemicalFamily.attributes.chemicalFamilyType
                )}
              </span>
              <span className="text-grey-50">
                {chemicalFamily.attributes.createdBy}
              </span>
              <span className="text-grey-50">
                {format(
                  new Date(chemicalFamily.attributes.updatedAt),
                  'MM/dd/yy'
                )}
              </span>
            </div>
          </div>
        ))}
      </div>
    );
  };

  return (
    <>
      {isMobile && (
        <div className="sticky top-0 z-50 w-full p-6 pl-12 mx-auto bg-white font-agipo">
          RM & INCI Groups
        </div>
      )}
      <div className="bg-[url(img/rm-inci-groups-bg.png)] bg-cover bg-no-repeat min-h-[372px] sm:min-h-[416px] text-grey-20">
        <div
          className={`max-w-[1440px] mx-auto pt-[204px] sm:pt-[252px] flex flex-col font-inter pr-6 sm:pr-16 ${
            isLeftNavOpen && !isMobile ? 'pl-[303px]' : 'sm:pl-32 pl-6'
          }`}
        >
          <div className="flex flex-col sm:flex-row sm:justify-between gap-16 pb-[30px]">
            <div className="sm:w-1/2 w-full flex flex-col">
              <span className="font-agipo text-[48px] leading-[56px] font-bold">
                RM & INCI Groups
              </span>
              <span className="font-inter text-grey-50">
                Create and update groups of RMs and INCIs. These groups can
                represent Retailer Standards, Client No-Nos, and other families
                of RMs and/or INCIs for reference in project details,
                formulation, etc.
              </span>
            </div>
            <Button
              onClick={() => navigate(ROUTES.CREATE_CHEMICAL_FAMILY.route)}
              leadingIcon={<PlusIcon className="h-6 w-6" />}
              size="large"
              text="New Group"
            />
          </div>
        </div>
      </div>
      <div
        className={`max-w-[1440px] mx-auto flex flex-col gap-8 pr-6 sm:pr-16 mt-8 ${
          isLeftNavOpen && !isMobile ? 'pl-[303px]' : 'sm:pl-32 pl-6'
        }`}
      >
        <div className="flex flex-col gap-2 sm:w-1/2">
          <TextField
            placeholder="Search"
            trailingIcon={
              <MagnifyingGlassIcon className="text-grey-50 h-5 w-5" />
            }
            value={searchQuery}
            onChange={(e: any) => {
              setSearchQuery(e.target.value);
              setUserInitiatedSearchCompleted(false);
            }}
            height="h-11"
          />
          {userInitiatedSearchCompleted &&
            (chemicalFamilies && chemicalFamilies.length > 0 ? (
              <span className="font-inter text-grey-20 text-xs">
                Showing results for{' '}
                <span className="font-bold">{searchQuery}</span>
              </span>
            ) : (
              <span className="font-inter text-grey-20 text-xs">
                No results for <span className="font-bold">{searchQuery}.</span>
                <span> Make sure your search excludes typos.</span>
              </span>
            ))}
        </div>
        {isMobile && (
          <Select options={CHEMICAL_FAMILY_TABLE_SORT_OPTIONS} value="name" />
        )}
        <div className="flex gap-6 sm:gap-0 flex-col sm:flex-row w-full justify-between items-center">
          <div className="flex flex-wrap gap-4 justify-center flex-grow">
            {CHEMICAL_FAMILY_TYPE_OPTIONS.map((chip) => (
              <Chip
                bgColor="bg-grey-95"
                key={chip.value}
                label={chip.label}
                onClick={() => {
                  setSelectedFilterChips((prev) => {
                    if (prev.includes(chip.value)) {
                      return [...prev];
                    }
                    return [...prev, chip.value];
                  });
                }}
                value={chip.value}
                onCloseIconClick={(event) => {
                  event.stopPropagation();
                  setSelectedFilterChips((prev) => {
                    return prev.filter((value) => value !== chip.value);
                  });
                }}
                textColor="text-grey-40"
                selected={selectedFilterChips.includes(chip.value)}
                selectedBgColor="bg-royal-95"
                selectedTextColor="text-royal-40"
              />
            ))}
          </div>
          {!isMobile && (
            <span className="mr-auto sm:ml-auto">{totalResults} groups</span>
          )}
        </div>
        <div>
          {isMobile ? renderMobileTable() : renderDesktopTable()}
          <Pagination
            currentPage={currentPage}
            entriesPerPage={ENTRIES_PER_PAGE}
            totalResults={totalResults}
            setCurrentPage={setCurrentPage}
          />
        </div>
      </div>
    </>
  );
};
