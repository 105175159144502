// Libraries
import React from 'react';
import {
  BeakerIcon,
  EyeDropperIcon,
  QueueListIcon,
} from '@heroicons/react/16/solid';
// Components
import { SquareChip, SquareIcon } from 'design-system';

export const InciChip = ({
  onClick,
  selected,
}: {
  onClick: () => void;
  selected: boolean;
}) => {
  return (
    <SquareChip
      backgroundColor="bg-teal-95"
      borderColor={selected ? 'border-teal-40' : 'border-white'}
      hoverColor="hover:bg-teal-80"
      label="INCI"
      labelColor="text-teal-30"
      leadingIcon={
        <SquareIcon
          backgroundColor="bg-teal-90"
          icon={<EyeDropperIcon className="w-4 h-4 text-teal-40" />}
        />
      }
      onClick={onClick}
      selected={selected}
    />
  );
};

export const RmChip = ({
  onClick,
  selected,
}: {
  onClick: () => void;
  selected: boolean;
}) => {
  return (
    <SquareChip
      backgroundColor="bg-blue-95"
      borderColor={selected ? 'border-blue-40' : 'border-white'}
      hoverColor="hover:bg-blue-80"
      label="RM"
      labelColor="text-blue-30"
      leadingIcon={
        <SquareIcon
          backgroundColor="bg-blue-90"
          icon={<BeakerIcon className="w-4 h-4 text-blue-40" />}
        />
      }
      onClick={onClick}
      selected={selected}
    />
  );
};

export const InciFamilyChip = ({
  onClick,
  selected,
}: {
  onClick: () => void;
  selected: boolean;
}) => {
  return (
    <SquareChip
      backgroundColor="bg-peach-95"
      borderColor={selected ? 'border-peach-40' : 'border-white'}
      hoverColor="hover:bg-peach-80"
      label="INCI Family"
      labelColor="text-peach-30"
      leadingIcon={
        <SquareIcon
          backgroundColor="bg-peach-90"
          icon={<QueueListIcon className="w-4 h-4 text-peach-40" />}
        />
      }
      onClick={onClick}
      selected={selected}
    />
  );
};
