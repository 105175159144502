// Libraries
import { JwtPayload } from 'jwt-decode';
// Utils
import { MOQ_UNITS, Price } from 'features/types';
// Constants
import { PricingQuoteConstants } from 'features/pricing-quote';
import { API_TYPES, RESOURCE_TYPES } from './api.constants';

const { PRODUCTION_LOCATIONS } = PricingQuoteConstants;

export enum API_VERSION {
  v1 = 'v1',
  v2 = 'v2',
}

export interface IApiResponse {
  data?: Maybe<IApiData<any> & IApiData<any>[]>;
  error?: string;
  errors?: string[];
  errorMessage?: string;
  exception?: string;
  included: IApiData<any>[];
  meta: any;
  status: number;
}

interface IRelationship {
  id: string;
  type?: keyof typeof API_TYPES;
}

export interface IApiRelationships {
  [type: string]: {
    data: IRelationship[] & IRelationship;
  };
}

export interface IApiData<ResourceAttributeType, ParsedIncludedType = any> {
  id: string;
  attributes: ResourceAttributeType & { [x: string]: Maybe<any> };
  type?: keyof typeof API_TYPES;
  relationships: IApiRelationships;
  meta?: any;
  parsedIncluded?: ParsedIncludedType;
}

export interface IJWTPayload extends JwtPayload {
  permissions: Array<string>;
}

export type PhaseAttributes = {
  name: string;
  procedure?: string;
};

export enum MASTER_BOX_LABEL {
  less_than_six = '6 Or Less',
  six_or_greater = 'Greater than 6',
}

export interface BriefSupplementAttributes {
  benchmarkNotes?: string;
  cappingHeadCount?: number;
  closure: string;
  compoundingHeadCount?: number;
  container: string;
  crueltyFree?: boolean | undefined;
  efficiencyPercentage: number;
  evaluationPriorityNotes: string;
  evaluationPriorityOne: string;
  evaluationPriorityThree: string;
  evaluationPriorityTwo: string;
  expectedOpeningOrderQuantity?: number;
  firstSampleDeliveryDate?: Date | undefined;
  fragranceNotes?: string;
  glutenFree?: boolean | undefined;
  ingredientNotes?: string;
  innerBox: boolean;
  marginTargetPercentage?: number;
  marketNotes?: string;
  masterBox: keyof typeof MASTER_BOX_LABEL;
  material: string;
  operatorHeadCount?: number;
  orderQuantityNotes?: string;
  other?: Maybe<number>;
  otherHeadCount?: number;
  overCap: boolean;
  p65Restricted?: boolean | undefined;
  packagingNotes?: string;
  packingOrPalletizerHeadCount?: number;
  preworkHeadCount?: number;
  productionLocation: Maybe<valueof<typeof PRODUCTION_LOCATIONS>>;
  projectNotes?: string;
  retailerStandards?: any;
  runRate?: number;
  tamperSleeve: boolean;
  targetCostNotes?: string;
  timelineNotes?: string;
  unitCarton: boolean;
  unitCartonHeadCount?: number;
  vegan?: boolean | undefined;
}

export type BriefAttributes = Partial<BriefSupplementAttributes> & {
  additionalComments?: string; //text
  bMarks?: IApiData<BenchmarkAttributes>[];
  companyName?: string;
  countries?: string[];
  customer?: any;
  briefSupplement?: { data: IApiData<BriefSupplementAttributes> };
  fragranceComment?: string;
  fragranceType?: string;
  labels?: string[];
  kind: string;
  minimumOrderQuantity: Maybe<number> | undefined;
  msrpTargetCost: Maybe<number>;
  msrpTargetMaxCost: Maybe<number>;
  msrpTargetMinCost: Maybe<number>;
  mustExcludeIngredients?: string;
  mustHaveIngredients?: string;
  primaryPackaging?: string;
  projectName: string;
  secondaryPackaging?: string;
  size?: number;
  specificFragrances?: string[];
  standards?: any;
  structure: string;
  targetCost: Maybe<number>;
  targetCustomer?: string; //text
  targetDate?: Date | null;
  targetMaxCost: Maybe<number>;
  targetMinCost: Maybe<number>;
  unit: string;
};

export enum RawMaterialLocations {
  NY = 'New York',
  LA = 'Los Angeles',
  MIA = 'Miami',
}

export type IngredientAttributes = {
  active: boolean;
  amount: number | string;
  areEuInciFactorsVerified?: boolean;
  areEuIncisVerified?: boolean;
  areUsInciFactorsVerified?: boolean;
  areUsIncisVerified?: boolean;
  formulaUuid: string;
  highestPrice: Maybe<PriceAttributes>;
  key: Maybe<boolean>;
  lotNumber: Maybe<string>;
  manufacturer: Maybe<any>;
  mayContain: boolean;
  position: number;
  rawMaterialEuIncis: Array<any>;
  rawMaterialLocation: valueof<typeof RawMaterialLocations>;
  rawMaterialName: string;
  rawMaterialRmId: Maybe<string>;
  rawMaterialSampleCode: Maybe<string>;
  rawMaterialTradeName: string;
  rawMaterialUsIncis: Array<any>;
  rawMaterialUuid: string;
  solvent: boolean;
  supplier: Maybe<any>;
  usApiDrugPurposes: string[];
  usIncisVerified: false;
};

export type InciAttributes = {
  casNumber: Maybe<string>;
  createdAt: Date;
  ecNumber: Maybe<string>;
  euName: string;
  functions: string[];
  harmonizedName: Maybe<string>;
  kind: string;
  name: Maybe<string>;
  updatedAt: Date;
  usName: string;
};

export type ResourceType = valueof<typeof RESOURCE_TYPES>;

export type IHandleSuccess<T, K> = (data: T, token?: K) => void;

export type RequestOptions = {
  urlParams: string;
  handleSuccess: IHandleSuccess<any, any>;
  handleError?: (error: unknown) => void;
  handleFinally?: () => void;
};

export interface SearchRequestOptions extends RequestOptions {
  query: string;
}

export interface PostPatchRequestOptions
  extends Omit<RequestOptions, 'urlParams' | 'handleSuccess'> {
  urlParams?: string;
  data: any;
  handleSuccess?: IHandleSuccess<any, any>;
  headers?: any;
}

export type resourceFunctionType = (options: RequestOptions) => Promise<void>;
export type searchResourceFunctionType = (
  options: SearchRequestOptions
) => Promise<void>;

export type patchAndPostResourceFunctionType = (
  options: PostPatchRequestOptions
) => Promise<void>;

export interface IAPI {
  deleteAllChemicalFamilyAssociations: resourceFunctionType;
  deleteChemicalFamily: resourceFunctionType;
  deleteChemicalFamilyAssociation: resourceFunctionType;
  deleteCustomer: resourceFunctionType;
  deleteFormula: resourceFunctionType;
  deleteInci: resourceFunctionType;
  deleteIngredient: resourceFunctionType;
  deletePhase: resourceFunctionType;
  deleteProjectFile: resourceFunctionType;
  deleteProjectOrder: resourceFunctionType;
  deleteRtlCatalogueEntry: resourceFunctionType;
  getChemicalFamily: resourceFunctionType;
  getCustomer: resourceFunctionType;
  getCompanies: resourceFunctionType;
  getCompany: resourceFunctionType;
  getCompanyCustomers: resourceFunctionType;
  getCompanyProjects: resourceFunctionType;
  getCustomerNotifications: resourceFunctionType;
  getEmployee: resourceFunctionType;
  getEmployees: resourceFunctionType;
  getFormula: resourceFunctionType;
  getFormulaWarnings: resourceFunctionType;
  getInci: resourceFunctionType;
  getIncis: resourceFunctionType;
  getFormulaBatches: resourceFunctionType;
  getFormulaPriceQuote: resourceFunctionType;
  getManufacturers: resourceFunctionType;
  getProject: resourceFunctionType;
  getProjectFeedback: resourceFunctionType;
  getProjectFormulas: resourceFunctionType;
  getProjectWorksheets: resourceFunctionType;
  getRawMaterial: resourceFunctionType;
  getRawMaterials: resourceFunctionType;
  getRtlCatalogueEntry: resourceFunctionType;
  getSuppliers: resourceFunctionType;
  getProjectFile: resourceFunctionType;
  getProjectFiles: resourceFunctionType;
  getProjectFileUrl: resourceFunctionType;
  patchChemicalFamily: patchAndPostResourceFunctionType;
  patchCompany: patchAndPostResourceFunctionType;
  patchCustomer: patchAndPostResourceFunctionType;
  patchCustomerNotificationsMarkAllAsViewed: patchAndPostResourceFunctionType;
  patchEmployee: patchAndPostResourceFunctionType;
  patchFinalBrief: patchAndPostResourceFunctionType;
  patchFormula: patchAndPostResourceFunctionType;
  patchFormulaBatch: patchAndPostResourceFunctionType;
  patchFormulaPriceQuote: patchAndPostResourceFunctionType;
  patchInci: patchAndPostResourceFunctionType;
  patchIngredient: patchAndPostResourceFunctionType;
  patchPhase: patchAndPostResourceFunctionType;
  patchProject: patchAndPostResourceFunctionType;
  patchProjectV2: patchAndPostResourceFunctionType;
  patchRawMaterial: patchAndPostResourceFunctionType;
  patchProjectThirdPartyTest: patchAndPostResourceFunctionType;
  patchProjectOrderSuccess: patchAndPostResourceFunctionType;
  patchProjectWorksheet: patchAndPostResourceFunctionType;
  patchProjectWorksheetPriceQuote: patchAndPostResourceFunctionType;
  patchRtlCatalogueEntry: patchAndPostResourceFunctionType;
  patchWorksheetFeedback: patchAndPostResourceFunctionType;
  postChemicalFamily: patchAndPostResourceFunctionType;
  postCustomer: patchAndPostResourceFunctionType;
  postFormulaBatch: patchAndPostResourceFunctionType;
  postEmail: patchAndPostResourceFunctionType;
  postEmployee: patchAndPostResourceFunctionType;
  postFinalBrief: patchAndPostResourceFunctionType;
  postFormula: patchAndPostResourceFunctionType;
  postFormulaIngredients: patchAndPostResourceFunctionType;
  postFormulaPriceQuote: patchAndPostResourceFunctionType;
  postInci: patchAndPostResourceFunctionType;
  postManufacturers: patchAndPostResourceFunctionType;
  postProjectOrder: patchAndPostResourceFunctionType;
  postPhase: patchAndPostResourceFunctionType;
  postProject: patchAndPostResourceFunctionType;
  postProjectFeedback: patchAndPostResourceFunctionType;
  postProjectV2: patchAndPostResourceFunctionType;
  createProjectsForRtlCatalogueOrder: patchAndPostResourceFunctionType;
  postRawMaterial: patchAndPostResourceFunctionType;
  postSuppliers: patchAndPostResourceFunctionType;
  postProjectFile: patchAndPostResourceFunctionType;
  postProjectThirdPartyTest: patchAndPostResourceFunctionType;
  postIngredientListFeedback: patchAndPostResourceFunctionType;
  postProjectWorksheet: patchAndPostResourceFunctionType;
  postProjectWorksheetPriceQuote: patchAndPostResourceFunctionType;
  postWorksheetFeedback: patchAndPostResourceFunctionType;
  postRtlCatalogueEntry: patchAndPostResourceFunctionType;
  resendFeedbackApprovalEmail: patchAndPostResourceFunctionType;
  shareProjectWorksheetPriceQuoteWithCustomer: patchAndPostResourceFunctionType;
  searchChemicalFamilies: searchResourceFunctionType;
  searchCompanies: searchResourceFunctionType;
  searchFormulas: searchResourceFunctionType;
  searchIncis: searchResourceFunctionType;
  searchProjects: searchResourceFunctionType;
  searchRawMaterials: searchResourceFunctionType;
  searchRtlCatalogueEntries: searchResourceFunctionType;
}

export type BatchAttributes = {
  appearanceOvernight: Maybe<string>;
  appearanceInitial: Maybe<string>;
  appearanceRange: string;
  colorOvernight: Maybe<string>;
  colorInitial: Maybe<string>;
  createdAt: string;
  lotNumber: string; // required
  notesOvernight: Maybe<string>;
  notesInitial: Maybe<string>;
  notesRange: Maybe<string>;
  odorOvernight: Maybe<string>;
  odorInitial: Maybe<string>;
  phOvernight: Maybe<string | number>;
  phInitial: Maybe<string | number>;
  phRangeMin: Maybe<string | number>;
  phRangeMax: Maybe<string | number>;
  quantity: string | number; // required
  solidsPercentageOvernight: Maybe<string | number>;
  solidsPercentageInitial: Maybe<string | number>;
  solidsPercentageRangeMin: Maybe<string | number>;
  solidsPercentageRangeMax: Maybe<string | number>;
  specificGravityOvernight: Maybe<string | number>;
  specificGravityInitial: Maybe<string | number>;
  specificGravityRangeMin: Maybe<string | number>;
  specificGravityRangeMax: Maybe<string | number>;
  viscosityPercentageOvernight: Maybe<string | number>;
  viscosityPercentageInitial: Maybe<string | number>;
  viscosityReadingOvernight: Maybe<string | number>;
  viscosityReadingInitial: Maybe<string | number>;
  viscosityReadingMinRange: Maybe<string | number>;
  viscosityReadingMaxRange: Maybe<string | number>;
  viscositySpeedOvernight: Maybe<string | number>;
  viscositySpeedInitial: Maybe<string | number>;
  viscositySpeedRange: Maybe<string | number>;
  viscositySpindleOvernight: Maybe<string>;
  viscositySpindleInitial: Maybe<string>;
  viscositySpindleRange: Maybe<string>;
};

export type RawMaterialAttributes = {
  // Classifications
  canadaRestricted: Maybe<boolean>;
  canadaRestrictedExplanation: Maybe<string>;
  crueltyFree: Maybe<boolean>;
  crueltyFreeExplanation: Maybe<string>;
  euRestricted: Maybe<boolean>;
  euRestrictedExplanation: Maybe<string>;
  glutenFree: Maybe<boolean>;
  glutenFreeExplanation: Maybe<string>;
  p65Restricted: Maybe<boolean>;
  p65RestrictedExplanation: Maybe<string>;
  reachRegistered: Maybe<boolean>;
  reachRegisteredExplanation: Maybe<string>;
  reachRestricted: Maybe<boolean>;
  reachRestrictedExplanation: Maybe<string>;
  vegan: Maybe<boolean>;
  veganExplanation: Maybe<string>;

  // EU Verification
  euInciFactorsVerified: unknown;
  euInciFactorsVerifiedBy: unknown;
  euIncisVerified: unknown;
  euIncisVerifiedBy: unknown;
  // US Verification
  usInciFactorsVerified: unknown;
  usInciFactorsVerifiedBy: unknown;
  usIncisVerified: unknown;
  usIncisVerifiedBy: unknown;

  // Raw Material
  createdAt: Date;
  functions: Array<string>;
  location: valueof<typeof RawMaterialLocations>;
  marketingDescription: string;
  marketingName: string;
  name: string;
  notes: string;
  origin: Array<string>;
  rmId: Maybe<string>;
  sampleCode: Maybe<string>;
  synonyms: Array<string>;
  tradeName: string;
  updatedAt: Date;

  // INCIS
  euIncis: Array<any>;
  incis: { data: Array<any> };
  usIncis: Array<any>;
};

export type BenchmarkAttributes = {
  url: string;
  appearance: boolean;
  color: boolean;
  texture: boolean;
  viscosity: boolean;
  fragrance: boolean;
};

export type RawMaterialInciAttributes = {
  allergen: Maybe<boolean>;
  amount: number | string;
  concentrationLevel: Maybe<number>;
  inciUuid: string;
  incidental: Maybe<boolean>;
  maxPercentage: number;
  minPercentage: number;
  preservative: Maybe<boolean>;
  rawMaterialUuid: string;
  region: 'US' | 'EU';
  usApiDrugName: Maybe<string>;
  usApiMax: Maybe<number | string>;
  usApiMin: Maybe<number | string>;
  usApiPurityFactor: Maybe<number | string>;
};

export enum InviteStatus {
  accepted = 'accepted',
  pending = 'pending',
  not_invited = 'not_invited',
}

export type CustomerAttributes = {
  companyName: string;
  companyUuid: string;
  email: string;
  familyName: string;
  givenName: string;
  inviteStatus: Maybe<InviteStatus>;
  thirdPartyId: Maybe<string>;
  notificationPreference: string;
};

export type CompanyAttributes = {
  name: string;
  customer: { data: IApiData<any> };
};

export enum FeedbackStatus {
  approved = 'approved',
  pending = 'pending',
  rejected = 'rejected',
}

export type FeedbackAttributes = {
  status: keyof typeof FeedbackStatus;
  content: string;
  feedbackableType: string;
  created_byType: string;
  created_at: string;
  updated_at: string;
};

export type FormulaAttributes = {
  appearance?: string;
  batchQuantity?: string;
  color?: string;
  companyName: string;
  companyUuid: string;
  createdAt?: string;
  description?: string;
  directionsForUse?: string;
  dryWeight?: number;
  externalId: string;
  featuresBenefits?: string;
  locked: boolean;
  name: string;
  note?: Maybe<string>;
  noteCreatedBy?: Maybe<string>;
  noteCreatedOn?: Maybe<string | Date>;
  noteLastModifiedBy?: Maybe<string>;
  noteLastModifiedOn?: Maybe<string | Date>;
  observations?: string;
  odor?: string;
  packaging?: string;
  pH24h?: number;
  pHInitial?: number;
  phMax?: number;
  phMin?: number;
  procedure?: string[];
  productionLocation?: string;
  productionRecipeId: Maybe<string>;
  projectExternalId?: string;
  projectName: string;
  projectUuid: string;
  recommendedFor?: string;
  sg24h?: number;
  sgInitial?: number;
  sgMax?: number;
  sgMin?: number;
  stability?: string;
  status?: string;
  updatedAt: string;
  usage?: string;
  visc24hReadingNum?: number;
  visc24hReadingPercent?: number;
  visc24hSpindle?: string;
  visc24hSpindleSpeed?: number;
  viscInitialReadingNum?: number;
  viscInitialReadingPercent?: number;
  viscInitialSpindle?: string;
  viscInitialSpindleSpeed?: number;
};

export type SampleFeedbackAttributes = {
  round: number;
  colorFeedback: string;
  colorNote?: Maybe<string>;
  fragranceFeedback: string;
  fragranceNote?: Maybe<string>;
  formulaUuid: string;
  textureFeedback: string;
  textureNote?: Maybe<string>;
  applicationFeedback: string;
  applicationNote?: Maybe<string>;
  emolliencyFeedback: string;
  emolliencyNote?: Maybe<string>;
  viscosityFeedback: string;
  viscosityNote?: Maybe<string>;
  performanceFeedback: string;
  performanceNote?: Maybe<string>;
  otherNote?: Maybe<string>;
  createdAt: string;
  updatedAt: string;
};

export type SampleRecipientAttributes = {
  address1: string;
  address2: string;
  city: string;
  email: string;
  firstName: string;
  lastName: string;
  phone: string;
  state: string;
  zip: string;
  createdAt: string;
  updatedAt: string;
};

export type SampleSubmissionAttributes = {
  additionalNotes?: string;
  dateCreated: string;
  submissionFormId: string;
};

export type SampleAttributes = {
  customerFeedback: string;
  labNotes: string;
  lotNumber: string;
  packaging: string;
  quantity: number;
  size: number;
  unit: string;
};

export type EmployeeAttributes = {
  giveName: string;
  familyName: string;
  fullName: string;
  avatarColor: string;
  location: string;
  searchLocation: string;
};

export type ProjectAttributes = {
  briefUuid: string;
  category?: Maybe<string>;
  company: { data: IApiData<CompanyAttributes> };
  companyName: string;
  customerName: string;
  description?: Maybe<string>;
  employees: IApiData<EmployeeAttributes>[];
  externalId?: Maybe<string>;
  name: string;
  mondayProductDevGroup: Maybe<string>;
  otc?: string;
  otcNote?: string;
  previouslyBroughtToMarket?: string;
  previouslyBroughtToMarketNote?: string;
  productClaims?: string[];
  productType: Maybe<string>;
  productTypeNote: Maybe<string>;
  projectType: Maybe<string>;
  createdAt: Maybe<string>;
  updatedAt: Maybe<string>;
  sampleSubmissionRound: Maybe<number>;
  finalQuoteDue?: Maybe<string>;
};

export type PricingQuoteAttributes = {
  cappingHeadCount: number;
  compoundingHeadCount: number;
  createdAt: string;
  efficiencyPercentage: string; // float
  marginTargetPercentage: string; // float
  operatorHeadCount: number;
  otherHeadCount: number;
  packingOrPalletizerHeadCount: number;
  preworkHeadCount: number;
  runRate: number;
  unitCartonHeadCount: number;
};

export type RtlCatalogueEntryAttributes = {
  displayName: string;
  imageId: string;
  minimumOrderQuantity: number;
  price: number;
  productDescription: string;
  status: string;
};

export type ChemicalFamilyAttributes = {
  name: string;
  chemicalFamilyType: string;
  referenceUrl: string;
  isVisibleToCustomers: boolean;
  description: string;
  createdAt: string;
  updatedAt: string;
};

export type ChemicalFamilyAssociationAttributes = {
  associable: {
    uuid: string;
    name: string;
    [x: string]: any;
  };
  associableType: string;
  createdByFullName: string;
  familyName?: string;
  givenName?: string;
  maxAmount: Maybe<number>;
  minAmount: Maybe<number>;
  name: string;
  notes: string;
  relationshipType: string;
  createdAt: string;
  updatedAt: string;
  uuid: string;
};

export type ProductAttributes = {
  clinicalClaimAntiRedness: boolean;
  clinicalClaimBlueLightProtection: boolean;
  clinicalClaimGlutenFree: boolean;
  clinicalClaimHydration: boolean;
  clinicalClaimHypoallergenic: boolean;
  clinicalClaimNoAnimalDerivedIngredients: boolean;
  clinicalClaimNoAnimalTesting: boolean;
  clinicalClaimNonOtcComment: string;
  clinicalClaimSoothing: boolean;
  clinicalClaimVegan: boolean;
  closure: string;
  colorNote: string;
  countryComplianceCanada: boolean;
  countryComplianceEu: boolean;
  countryComplianceNote: string;
  countryComplianceOther: boolean;
  countryComplianceUs: boolean;
  fillSize: number;
  fragranceNote: string;
  internalNotes: string;
  keyBenefitBrightening: boolean;
  keyBenefitEyesReduceAppearanceOfDarkCircles: boolean;
  keyBenefitEyesReduceAppearanceOfPuffiness: boolean;
  keyBenefitEyesReduceAppearanceOfFineLinesCrowsFeet: boolean;
  keyBenefitFirming: boolean;
  keyBenefitHydration: boolean;
  keyBenefitMattifying: boolean;
  keyBenefitMoisturization: boolean;
  keyBenefitRadianceGlow: boolean;
  keyBenefitSelfTanner: boolean;
  keyBenefitSmoothing: boolean;
  keyBenefitTightening: boolean;
  masterBox: string;
  material: string;
  opacity: string;
  otc: boolean;
  primaryComponent: string;
  productCategory: string;
  productType: string;
  productionLoocation: string;
  regulatoryGlutenFree: boolean;
  regulatoryInfraCompliant: boolean;
  regulatoryNoAnimalDerivedIngredients: boolean;
  regulatoryNoAnimalTesting: boolean;
  regulatoryVegan: boolean;
  retailerClaimCyclicSiliconeFree: boolean;
  retailerClaimSephoraClean: boolean;
  retailerClaimTargetClean: boolean;
  retailerClaimUltaClean: boolean;
  rinseOffLeaveOn: boolean;
  spf: string;
  siliconeFree: boolean;
  structure: string;
  testingFinalComponent: boolean;
  testingHript: boolean;
  testingHriptWithDermatologistSignature: boolean;
  testingHriptWithHypoallergenicClaim: boolean;
  testingInVitro: boolean;
  testingOcularIrritation: boolean;
  testingPet: boolean;
  testingSpfDeterminationFda: boolean;
  testingSpfDeterminationIso: boolean;
  testingStability: boolean;
  testingWaterActivity: boolean;
  tinted: boolean;
  typeOfSystem: string;
  unit: string;
};

export type RtlCatalogueWithProductAttributes = ProductAttributes &
  RtlCatalogueEntryAttributes;

export type WorksheetIngredientAttributes = {
  amount: number;
  createdAt: string;
  defaultPrice: Maybe<number>;
  moqInPounds: Maybe<number>;
  name: string;
  originalMoq: Maybe<number>;
  originalMoqUnit: Maybe<string>;
  pricePerPound: Maybe<number>;
  rmCode: Maybe<string>;
  rmUuid: string;
  sampleCode: Maybe<string>;
  supplier: Maybe<string>;
  updatedAt: string;
  uuid: string;
};

export type WorksheetApiResponse = IApiData<
  WorksheetAttributes,
  {
    worksheetIngredients: Maybe<IApiData<WorksheetIngredientAttributes>[]>;
    project: Maybe<IApiData<ProjectAttributes>>;
    formula: Maybe<IApiData<FormulaAttributes>>;
    priceQuote: Maybe<IApiData<PriceQuoteAttributes>>;
    feedbacks: Maybe<IApiData<FeedbackAttributes>[]>;
  }
>;
export type Catalogable = {
  type: 'sun_care' | 'skin_care' | 'hair_care' | 'body_care';
  [x: string]: any;
};

export type WorksheetAttributes = {
  quoteType: keyof typeof PriceQuoteType;
  quoteArchived?: Maybe<boolean>;
  additionalComponentOneA: number;
  additionalComponentOneB: number;
  additionalComponentOneC: number;
  additionalComponentThreeA: number;
  additionalComponentThreeB: number;
  additionalComponentThreeC: number;
  additionalComponentTwoA: number;
  additionalComponentTwoB: number;
  additionalComponentTwoC: number;
  batchingHoursOne: number;
  batchingHoursThree: number;
  batchingHoursTwo: number;
  compoundingHeadCountOne: number;
  compoundingHeadCountThree: number;
  compoundingHeadCountTwo: number;
  compoundingPayRateOne: number;
  compoundingPayRateThree: number;
  compoundingPayRateTwo: number;
  efficiencyPercentageOne: number;
  efficiencyPercentageThree: number;
  efficiencyPercentageTwo: number;
  fillingHeadCountOne: number;
  fillingHeadCountThree: number;
  fillingHeadCountTwo: number;
  fillingPayRateOne: number;
  fillingPayRateThree: number;
  fillingPayRateTwo: number;
  masterBoxOne: number;
  masterBoxThree: number;
  masterBoxTwo: number;
  numberOfBatchesOne: number;
  numberOfBatchesThree: number;
  numberOfBatchesTwo: number;
  orderQuantityOne: number;
  orderQuantityThree: number;
  orderQuantityTwo: number;
  profitOne: number;
  profitThree: number;
  profitTwo: number;
  runRateOne: number;
  runRateThree: number;
  runRateTwo: number;
  sizeOneQuantity: number;
  sizeOneUnit: number;
  sizeThreeQuantity: number;
  sizeThreeUnit: number;
  sizeTwoQuantity: number;
  sizeTwoUnit: number;
  tankSize: number;
  tankSizeOne: Maybe<number>;
  tankSizeTwo: Maybe<number>;
  tankSizeThree: Maybe<number>;
  createdAt: string;
  updatedAt: string;
  sizeOneCustomerCpuOne: Maybe<number>;
  sizeOneCustomerCpuThree: Maybe<number>;
  sizeOneCustomerCpuTwo: Maybe<number>;
  sizeOneProfitOne: Maybe<number>;
  sizeOneProfitThree: Maybe<number>;
  sizeOneProfitTwo: Maybe<number>;
  sizeThreeCustomerCpuOne: Maybe<number>;
  sizeThreeCustomerCpuThree: Maybe<number>;
  sizeThreeCustomerCpuTwo: Maybe<number>;
  sizeThreeProfitOne: Maybe<number>;
  sizeThreeProfitThree: Maybe<number>;
  sizeThreeProfitTwo: Maybe<number>;
  sizeTwoCustomerCpuOne: Maybe<number>;
  sizeTwoCustomerCpuThree: Maybe<number>;
  sizeTwoCustomerCpuTwo: Maybe<number>;
  sizeTwoProfitOne: Maybe<number>;
  sizeTwoProfitThree: Maybe<number>;
  sizeTwoProfitTwo: Maybe<number>;
  sizeOneTankSizeOne: Maybe<number>;
  sizeOneTankSizeTwo: Maybe<number>;
  sizeOneTankSizeThree: Maybe<number>;
  sizeTwoTankSizeOne: Maybe<number>;
  sizeTwoTankSizeTwo: Maybe<number>;
  sizeTwoTankSizeThree: Maybe<number>;
  sizeThreeTankSizeOne: Maybe<number>;
  sizeThreeTankSizeTwo: Maybe<number>;
  sizeThreeTankSizeThree: Maybe<number>;
  sizeOneNumberOfBatchesOne: Maybe<number>;
  sizeOneNumberOfBatchesTwo: Maybe<number>;
  sizeOneNumberOfBatchesThree: Maybe<number>;
  sizeTwoNumberOfBatchesOne: Maybe<number>;
  sizeTwoNumberOfBatchesTwo: Maybe<number>;
  sizeTwoNumberOfBatchesThree: Maybe<number>;
  sizeThreeNumberOfBatchesOne: Maybe<number>;
  sizeThreeNumberOfBatchesTwo: Maybe<number>;
  sizeThreeNumberOfBatchesThree: Maybe<number>;
};

export interface PriceAttributes extends Price {
  // TODO: this is being sent from the ingredient serializer incorrectly and will need to be corrected
  moq_unit: keyof typeof MOQ_UNITS | '';
  quantity_purchased: Maybe<string | number>;
}

// Can be a manufacturer or a supplier
export type VendorAttributes = {
  name: string;
};

export type TaskAttributes = {
  mondayColumnId: string;
  name: string;
  status: string;
  updatedAt: string;
};

export type NotificationAttributes = {
  createdAt: string;
  notificationTrigger: string;
  notificationType: string;
  payload: {
    actionCompleted?: boolean;
    documentCategory?: string;
    documentName?: string;
    createdBy?: string;
    statusUpdatedTo?: string;
    sampleRound?: number;
  };
  sourceType: string;
  viewedAt: Maybe<string>;
};

export type ThirdPartyTestAttributes = {
  id: string;
  hript50: number;
  hript50Initials: string;
  hript100: number;
  hript100Initials: string;
  pet: number;
  petInitials: string;
  stability: number;
  stabilityInitials: string;
  fda2021StaticSpf30AndWaterResistance: number;
  fda2021StaticSpf30AndWaterResistanceInitials: string;
  fda2021StaticSpf50AndWaterResistance: number;
  fda2021StaticSpf50AndWaterResistanceInitials: string;
  fda2021Spf30Static: number;
  fda2021Spf30StaticInitials: string;
  fda2021Spf50Static: number;
  fda2021Spf50StaticInitials: string;
  fdaCriticalWavelengthBroadSpectrum: number;
  fdaCriticalWavelengthBroadSpectrumInitials: string;
  iso24442: number;
  iso24442Initials: string;
  iso24443: number;
  iso24443Initials: string;
  iso24444Spf30: number;
  iso24444Spf30Initials: string;
  iso24444Spf50: number;
  iso24444Spf50Initials: string;
  comedogenicityPatchTest: number;
  comedogenicityPatchTestInitials: string;
  comedogenicityInRealUse: number;
  comedogenicityInRealUseInitials: string;
  ocularIrritation: number;
  ocularIrritationInitials: string;
  safetyInUsePediatricianSignOff: number;
  safetyInUsePediatricianSignOffInitials: string;
  acknowledged: boolean;
  additionalTests: any;
  createdAt: string;
  updatedAt: string;
};

export enum PriceQuoteType {
  preliminary = 'preliminary',
  final = 'final',
}

export enum PriceQuoteSizeUnits {
  ml = 'ml',
  oz = 'oz',
  g = 'g',
}

export type SizeUnits = keyof typeof PriceQuoteSizeUnits;

export enum PriceQuoteStatus {
  draft = 'draft',
  in_review = 'in_review',
  rejected = 'rejected',
  approved = 'approved',
}

// Not to be confused with PricingQuoteAttributes
export type PriceQuoteAttributes = {
  id: string;
  worksheetId: string;
  formulaId: string;
  quoteType: keyof typeof PriceQuoteType;
  personalNote: Maybe<string>;
  quoteVersionNumber: string;
  status: keyof typeof PriceQuoteStatus;
  quoteDate: Maybe<Date | null>;
  validFor: Maybe<number>;
  paymentTerms: Maybe<string>;
  incoterms: Maybe<string>;
  deliveryTerms: Maybe<string>;
  formulaDescription: Maybe<string>;
  moqOne: Maybe<number>;
  moqTwo: Maybe<number>;
  moqThree: Maybe<number>;
  sizeOneQuantity: Maybe<number | string>;
  sizeOneUnit: Maybe<SizeUnits>;
  sizeOneDescription: Maybe<string>;
  sizeOneMoqOneTotalCostPerUnit: Maybe<number | string>;
  sizeOneMoqTwoTotalCostPerUnit: Maybe<number | string>;
  sizeOneMoqThreeTotalCostPerUnit: Maybe<number | string>;
  sizeTwoQuantity: Maybe<number | string>;
  sizeTwoUnit: Maybe<SizeUnits>;
  sizeTwoDescription: Maybe<string>;
  sizeTwoMoqOneTotalCostPerUnit: Maybe<number | string>;
  sizeTwoMoqTwoTotalCostPerUnit: Maybe<number | string>;
  sizeTwoMoqThreeTotalCostPerUnit: Maybe<number | string>;
  sizeThreeQuantity: Maybe<number | string>;
  sizeThreeUnit: Maybe<SizeUnits>;
  sizeThreeDescription: Maybe<string>;
  sizeThreeMoqOneTotalCostPerUnit: Maybe<number | string>;
  sizeThreeMoqTwoTotalCostPerUnit: Maybe<number | string>;
  sizeThreeMoqThreeTotalCostPerUnit: Maybe<number | string>;
  pricingNotes: Maybe<string>;
  materialsNote: Maybe<string>;
  rawMaterialResponsibility: Maybe<string>;
  containersResponsibility: Maybe<string>;
  decorationResponsibility: Maybe<string>;
  closureResponsibility: Maybe<string>;
  masterResponsibility: Maybe<string>;
  createdAt: string;
  updatedAt: string;
};
