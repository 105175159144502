// Libraries
import React, { useEffect, useRef, useState } from 'react';
import { motion } from 'motion/react';
// Components
import { Spinner, Typography } from 'design-system';
import { FormulaAttributes } from 'api';
// Constants
import { ROUTES, UUID_SHOW_ROUTE_STRING } from 'features/navigation';

enum keyCodes {
  ArrowUp = 'ArrowUp',
  ArrowDown = 'ArrowDown',
  Enter = 'Enter',
  Space = 'Space',
}

enum searchTypes {
  searchFormulaNumber = 'searchFormulaNumber',
  searchFormulaName = 'searchFormulaName',
}

interface FormulaSearchMenuProps {
  inputHasHelperText?: boolean;
  inputHasLabel?: boolean;
  isLoading?: boolean;
  options: any[];
  width?: string;
  onClose: () => void;
  searchType: keyof typeof searchTypes;
  [x: string]: any;
}

export const FormulaSearchMenu: React.FC<FormulaSearchMenuProps> = ({
  inputHasHelperText,
  inputHasLabel,
  isLoading,
  width = 'w-full',
  options,
  onClose,
  searchType,
  ...rest
}) => {
  // Ref to detect click outside the dropdown menu
  const ref = useRef<HTMLDivElement>(null);
  const optionRefs = useRef<(HTMLDivElement | null)[]>([]);
  const [selectedOptionIndex, setSelectedOptionIndex] = useState<number>(-1);

  const OFFSET_WITH_LABEL_AND_HELPER_TEXT_OR_ERROR = 'top-[69px]';

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        onClose();
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [onClose]);

  useEffect(() => {
    // Scroll to selected option when selectedOptionIndex changes
    if (
      selectedOptionIndex !== null &&
      optionRefs.current[selectedOptionIndex]
    ) {
      optionRefs.current[selectedOptionIndex]?.scrollIntoView({
        behavior: 'auto',
      });
    }
  }, [selectedOptionIndex]);

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (isLoading || options.length === 0) return;

    switch (event.code) {
      case keyCodes.ArrowUp:
        event.preventDefault();
        if (selectedOptionIndex === -1) {
          setSelectedOptionIndex(options.length - 1);
        } else {
          setSelectedOptionIndex((prevIndex) =>
            prevIndex === 0 ? options.length - 1 : prevIndex - 1
          );
        }
        break;
      case keyCodes.ArrowDown:
        event.preventDefault();
        if (selectedOptionIndex === -1) {
          setSelectedOptionIndex(0);
        } else {
          setSelectedOptionIndex((prevIndex) =>
            prevIndex === options.length - 1 ? 0 : prevIndex + 1
          );
        }
        break;
      case keyCodes.Enter:
      case keyCodes.Space:
        event.preventDefault();
        if (selectedOptionIndex !== -1 && options[selectedOptionIndex]) {
          rest.onChange(options[selectedOptionIndex]);
          onClose && onClose();
        }
        break;
      default:
        const lowerCaseKey = event.key.toLowerCase();

        // Find all options that start with the key pressed
        const matchingOptions = options.filter((option) =>
          option.label.toLowerCase().startsWith(lowerCaseKey)
        );

        // If there are no matching options, return
        if (matchingOptions.length === 0) return;

        // Select the first matching option and if the key is pressed again, select the next matching option
        if (
          selectedOptionIndex === -1 ||
          matchingOptions.indexOf(options[selectedOptionIndex]) === -1
        ) {
          setSelectedOptionIndex(
            options.findIndex((option) => option === matchingOptions[0])
          );
        } else {
          // Find the index of the currently selected option in the matching options
          const currentIndex = options.findIndex(
            (option) => option === options[selectedOptionIndex]
          );

          // If the currently selected option is the last matching option, select the first matching option
          // Otherwise, select the next matching option
          setSelectedOptionIndex(
            options[selectedOptionIndex] ===
              matchingOptions[matchingOptions.length - 1]
              ? options.findIndex((option) => option === matchingOptions[0])
              : currentIndex + 1
          );
        }

        break;
    }
  };

  const handleOptionClick = (option: FormulaAttributes) => {
    rest.onChange(option);
    onClose && onClose();
  };

  const renderOptions = () => {
    if (isLoading)
      return (
        <div className="flex justify-center p-4 pr-12 bg-white font-inter text-sm">
          <Spinner />
        </div>
      );

    if (options.length === 0)
      return (
        <div className="flex justify-center p-4 pr-12 bg-white font-inter text-sm">
          No results found
        </div>
      );

    switch (searchType) {
      case searchTypes.searchFormulaNumber:
        return options.map((option, index: number) => {
          return (
            <div
              key={index}
              onClick={() => handleOptionClick(option)}
              ref={(ref) => (optionRefs.current[index] = ref)}
              className="flex flex-col p-4 border border-b-grey-90 border-l-0 border-r-0 border-t-0 cursor-pointer hover:bg-grey-99 whitespace-break-spaces"
            >
              <Typography
                onClick={(e: any) => {
                  e.stopPropagation();
                  window.open(
                    ROUTES.SHOW_FORMULA.route.replace(
                      UUID_SHOW_ROUTE_STRING,
                      option.value
                    ),
                    '_blank'
                  );
                }}
                variant="h5"
                weight="semibold"
                color="hyperlink"
                font="inter"
                additionalStyles="w-fit"
              >
                {option.label}
              </Typography>
              <Typography variant="h4" font="inter">
                {option.additionalData.name}
              </Typography>
              <Typography variant="h5" color="secondary" font="inter">
                Last modified{' '}
                {new Date(option.additionalData.updatedAt).toLocaleDateString(
                  'en-US'
                )}
              </Typography>
            </div>
          );
        });
      case searchTypes.searchFormulaName:
        return options.map((option, index: number) => {
          return (
            <div
              key={index}
              onClick={() => handleOptionClick(option)}
              ref={(ref) => (optionRefs.current[index] = ref)}
              className="flex flex-col p-4 border border-b-grey-90 border-l-0 border-r-0 border-t-0 cursor-pointer hover:bg-grey-99 whitespace-break-spaces"
            >
              <Typography
                onClick={(e: any) => {
                  e.stopPropagation();
                  window.open(
                    ROUTES.SHOW_FORMULA.route.replace(
                      UUID_SHOW_ROUTE_STRING,
                      option.value
                    ),
                    '_blank'
                  );
                }}
                variant="h5"
                weight="semibold"
                color="hyperlink"
                font="inter"
              >
                {option.additionalData.externalId}
              </Typography>
              <Typography variant="h4" font="inter">
                {option.label}
              </Typography>
              <Typography variant="h5" color="secondary" font="inter">
                Last modified{' '}
                {new Date(option.additionalData.updatedAt).toLocaleDateString(
                  'en-US'
                )}
              </Typography>
            </div>
          );
        });

      default:
        break;
    }
  };

  return (
    <motion.div
      layout
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.2, ease: 'linear' }}
      ref={ref}
      className={`absolute ${
        inputHasLabel && (inputHasHelperText || rest.error)
          ? OFFSET_WITH_LABEL_AND_HELPER_TEXT_OR_ERROR
          : ''
      } flex flex-col shadow-md z-20 max-h-[16rem] overflow-auto bg-white rounded ${width}`}
      onKeyDown={handleKeyDown}
      tabIndex={0} // Make the div focusable to capture key events
    >
      {renderOptions()}
    </motion.div>
  );
};
