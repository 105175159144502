//Libraries
import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
//Components
import { NavBar, ROUTES } from 'features/navigation';
import { Home, FileView } from 'features/home';
import { CustomerBriefV2, InternalBrief } from 'features/brief';
import { CustomerBriefView, SubmissionSuccess } from 'features/brief';
import { InciPage, IncisDictionary } from 'features/inci';
import { Legal, PrivacyPolicy, TermsOfUse } from 'features/legal';
import { ProjectPage } from 'features/project';
import { OnboardingWrapper, SignIn, VerifyEmail } from 'features/onboarding';
import { RawMaterialPage, RawMaterialsDictionary } from 'features/raw-material';
import { ErrorBoundary, Forbidden, NotFound } from 'features/error';
import { CreateFormulaPage, FormulaPage } from 'features/formula';
import { ProfilePage } from 'features/profile';
import { ProtectedComponent } from 'features/rbac';
import { CustomerProjectPage } from 'features/home/customer-portal/customer-project-page';
import { CustomerPortal } from 'features/home/customer-portal';
import { NotificationsDashboard } from 'features/notifications';
import { RegulatoryInfo } from 'features/regulatory-info';
import { ChemicalFamily, ChemicalFamilyIndex } from 'features/chemical-family';
import {
  RtlFormulaCatalogue,
  RtlFormulaCatalogueShippingDetails,
  RtlFormulaCatalogueOrderConfirmation,
  RtlFormulaCatalogueEntryForm,
} from 'features/rtl-formula-catalogue';
// Utils
import { CustomerViewWarningProvider } from 'features/home/customer-portal/context/customer-warning.provider';
import { NotificationsContextProvider } from 'context';
import { PaymentConfirmationInterstitial } from 'features/brief/customer-brief-v2/pages/payment-confirmation-interstitial.component';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    content: {
      flexGrow: 1,
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      marginLeft: 0,
    },
    contentShift: {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      // @ts-ignore
      marginLeft: theme.custom.drawer.width,
    },
  })
);

interface INavigation {
  isEmployee?: boolean;
}

export const Navigation: React.FC<INavigation> = ({ isEmployee }) => {
  const classes = useStyles();

  return (
    <div className={classes.content}>
      <ErrorBoundary>
        <NotificationsContextProvider>
          {!isEmployee && <NavBar />}
          <CustomerViewWarningProvider>
            <Routes>
              <Route
                path={ROUTES.CREATE_BRIEF.route}
                element={
                  <ProtectedComponent
                    path={ROUTES.CREATE_BRIEF.route}
                    permission={ROUTES.CREATE_BRIEF.visitPermission}
                  >
                    <CustomerBriefV2 />
                  </ProtectedComponent>
                }
              />
              <Route
                path={ROUTES.PAYMENT_CONFIRMATION_INTERSTITIAL.route}
                element={
                  <ProtectedComponent
                    path={ROUTES.PAYMENT_CONFIRMATION_INTERSTITIAL.route}
                    permission={
                      ROUTES.PAYMENT_CONFIRMATION_INTERSTITIAL.visitPermission
                    }
                  >
                    <PaymentConfirmationInterstitial />
                  </ProtectedComponent>
                }
              />
              <Route path={ROUTES.SIGNIN.route} element={<SignIn />} />
              <Route
                path={ROUTES.TERMS_OF_USE.route}
                element={
                  <Legal title="Terms of Use">
                    <TermsOfUse />
                  </Legal>
                }
              />
              <Route
                path={ROUTES.PRIVACY_POLICY.route}
                element={
                  <Legal title="Privacy Policy">
                    <PrivacyPolicy />
                  </Legal>
                }
              />
              <Route
                path={ROUTES.SHOW_CUSTOMER_BRIEF_VIEW.route}
                element={
                  <ProtectedComponent
                    path={ROUTES.SHOW_CUSTOMER_BRIEF_VIEW.route}
                    permission={ROUTES.SHOW_CUSTOMER_BRIEF_VIEW.visitPermission}
                  >
                    <ErrorBoundary>
                      <CustomerBriefView />
                    </ErrorBoundary>
                  </ProtectedComponent>
                }
              />
              <Route
                path={ROUTES.PROFILE.route}
                element={
                  <ProtectedComponent
                    path={ROUTES.PROFILE.route}
                    permission={ROUTES.PROFILE.visitPermission}
                  >
                    <ProfilePage />
                  </ProtectedComponent>
                }
              />
              <Route
                path={ROUTES.CREATE_FORMULA.route}
                element={
                  <ProtectedComponent
                    path={ROUTES.CREATE_FORMULA.route}
                    permission={ROUTES.CREATE_FORMULA.visitPermission}
                  >
                    <CreateFormulaPage />
                  </ProtectedComponent>
                }
              />
              <Route
                path={ROUTES.SHOW_FORMULA.route}
                element={
                  <ProtectedComponent
                    path={ROUTES.SHOW_FORMULA.route}
                    permission={ROUTES.SHOW_FORMULA.visitPermission}
                  >
                    <FormulaPage />
                  </ProtectedComponent>
                }
              />
              <Route
                path={ROUTES.RAW_MATERIALS.route}
                element={
                  <ProtectedComponent
                    path={ROUTES.RAW_MATERIALS.route}
                    permission={ROUTES.RAW_MATERIALS.visitPermission}
                  >
                    <RawMaterialsDictionary />
                  </ProtectedComponent>
                }
              />
              <Route
                path={ROUTES.CREATE_RAW_MATERIAL.route}
                element={
                  <ProtectedComponent
                    path={ROUTES.CREATE_RAW_MATERIAL.route}
                    permission={ROUTES.CREATE_RAW_MATERIAL.visitPermission}
                  >
                    <RawMaterialPage />
                  </ProtectedComponent>
                }
              />
              <Route
                path={ROUTES.SHOW_RAW_MATERIAL.route}
                element={
                  <ProtectedComponent
                    permission={ROUTES.SHOW_RAW_MATERIAL.visitPermission}
                    path={ROUTES.SHOW_RAW_MATERIAL.route}
                  >
                    <RawMaterialPage />
                  </ProtectedComponent>
                }
              />
              <Route
                path={ROUTES.CREATE_INCI.route}
                element={
                  <ProtectedComponent
                    path={ROUTES.CREATE_INCI.route}
                    permission={ROUTES.CREATE_INCI.visitPermission}
                  >
                    <InciPage />
                  </ProtectedComponent>
                }
              />
              <Route
                path={ROUTES.SHOW_INCI.route}
                element={
                  <ProtectedComponent
                    path={ROUTES.SHOW_INCI.route}
                    permission={ROUTES.SHOW_INCI.visitPermission}
                  >
                    <InciPage />
                  </ProtectedComponent>
                }
              />
              <Route
                path={ROUTES.INCIS.route}
                element={
                  <ProtectedComponent
                    path={ROUTES.INCIS.route}
                    permission={ROUTES.INCIS.visitPermission}
                  >
                    <IncisDictionary />
                  </ProtectedComponent>
                }
              />
              <Route
                path={ROUTES.CHEMICAL_FAMILY_INDEX.route}
                element={
                  <ProtectedComponent
                    path={ROUTES.CHEMICAL_FAMILY_INDEX.route}
                    permission={ROUTES.CHEMICAL_FAMILY_INDEX.visitPermission}
                  >
                    <ChemicalFamilyIndex />
                  </ProtectedComponent>
                }
              />
              <Route
                path={ROUTES.SHOW_CHEMICAL_FAMILY.route}
                element={
                  <ProtectedComponent
                    path={ROUTES.SHOW_CHEMICAL_FAMILY.route}
                    permission={ROUTES.CHEMICAL_FAMILY_INDEX.visitPermission}
                  >
                    <ChemicalFamily />
                  </ProtectedComponent>
                }
              />
              <Route
                path={ROUTES.CREATE_CHEMICAL_FAMILY.route}
                element={
                  <ProtectedComponent
                    path={ROUTES.CREATE_CHEMICAL_FAMILY.route}
                    permission={ROUTES.CHEMICAL_FAMILY_INDEX.visitPermission}
                  >
                    <ChemicalFamily initialMode={'create'} />
                  </ProtectedComponent>
                }
              />
              <Route
                path={ROUTES.SHOW_PROJECT.route}
                element={
                  <ProtectedComponent
                    path={ROUTES.SHOW_PROJECT.route}
                    permission={ROUTES.SHOW_PROJECT.visitPermission}
                  >
                    <ProjectPage />
                  </ProtectedComponent>
                }
              />
              <Route
                path={ROUTES.SHOW_PROJECT_FILE.route}
                element={
                  <ProtectedComponent
                    path={ROUTES.SHOW_PROJECT_FILE.route}
                    permission={ROUTES.SHOW_PROJECT_FILE.visitPermission}
                  >
                    <FileView />
                  </ProtectedComponent>
                }
              />
              <Route
                path={ROUTES.RTL_FORMULA_CATALOGUE.route}
                element={
                  <ProtectedComponent
                    path={ROUTES.RTL_FORMULA_CATALOGUE.route}
                    permission={ROUTES.RTL_FORMULA_CATALOGUE.visitPermission}
                  >
                    <RtlFormulaCatalogue />
                  </ProtectedComponent>
                }
              />
              <Route
                path={ROUTES.RTL_FORMULA_CATALOGUE_ENTRY.route}
                element={
                  <ProtectedComponent
                    path={ROUTES.RTL_FORMULA_CATALOGUE_ENTRY.route}
                    permission={
                      ROUTES.RTL_FORMULA_CATALOGUE_ENTRY.visitPermission
                    }
                  >
                    <RtlFormulaCatalogueEntryForm />
                  </ProtectedComponent>
                }
              />
              <Route
                path={ROUTES.RTL_FORMULA_CATALOGUE_SHIPPING_DETAILS.route}
                element={
                  <ProtectedComponent
                    path={ROUTES.RTL_FORMULA_CATALOGUE_SHIPPING_DETAILS.route}
                    permission={
                      ROUTES.RTL_FORMULA_CATALOGUE_SHIPPING_DETAILS
                        .visitPermission
                    }
                  >
                    <RtlFormulaCatalogueShippingDetails />
                  </ProtectedComponent>
                }
              />
              <Route
                path={ROUTES.CREATE_RTL_CATALOGUE_ENTRY.route}
                element={
                  <ProtectedComponent
                    path={ROUTES.CREATE_RTL_CATALOGUE_ENTRY.route}
                    permission={
                      ROUTES.CREATE_RTL_CATALOGUE_ENTRY.visitPermission
                    }
                  >
                    <RtlFormulaCatalogueEntryForm />
                  </ProtectedComponent>
                }
              />
              <Route
                path={ROUTES.RTL_FORMULA_CATALOGUE_ORDER_CONFIRMATION.route}
                element={
                  <ProtectedComponent
                    path={ROUTES.RTL_FORMULA_CATALOGUE_ORDER_CONFIRMATION.route}
                    permission={
                      ROUTES.RTL_FORMULA_CATALOGUE_ORDER_CONFIRMATION
                        .visitPermission
                    }
                  >
                    <RtlFormulaCatalogueOrderConfirmation />
                  </ProtectedComponent>
                }
              />
              <Route
                path={ROUTES.EMPLOYEE_VIEW_CUSTOMER_PROJECT.route}
                element={
                  <ProtectedComponent
                    path={ROUTES.EMPLOYEE_VIEW_CUSTOMER_PROJECT.route}
                    permission={
                      ROUTES.EMPLOYEE_VIEW_CUSTOMER_PROJECT.visitPermission
                    }
                  >
                    <CustomerProjectPage isEmployee={true} />
                  </ProtectedComponent>
                }
              />
              <Route
                path={ROUTES.EMPLOYEE_VIEW_CUSTOMER_DASHBOARD.route}
                element={
                  <ProtectedComponent
                    path={ROUTES.EMPLOYEE_VIEW_CUSTOMER_DASHBOARD.route}
                    permission={
                      ROUTES.EMPLOYEE_VIEW_CUSTOMER_DASHBOARD.visitPermission
                    }
                  >
                    <CustomerPortal />
                  </ProtectedComponent>
                }
              />
              <Route
                path={ROUTES.CREATE_INTERNAL_BRIEF.route}
                element={
                  <ProtectedComponent
                    path={ROUTES.CREATE_INTERNAL_BRIEF.route}
                    permission={ROUTES.CREATE_INTERNAL_BRIEF.visitPermission}
                  >
                    <InternalBrief />
                  </ProtectedComponent>
                }
              />
              <Route
                path={'*'}
                element={
                  <ProtectedComponent
                    path={'*'}
                    permission={ROUTES.HOME.visitPermission}
                    // TODO: may need to think harder about where this should go in all cases
                    redirectTo={ROUTES.VERIFY_EMAIL.route}
                  >
                    <Home />
                  </ProtectedComponent>
                }
              />
              <Route
                path={ROUTES.CUSTOMER_NOTIFICATIONS_DASHBOARD.route}
                element={
                  <ProtectedComponent
                    path={ROUTES.CUSTOMER_NOTIFICATIONS_DASHBOARD.route}
                    permission={
                      ROUTES.CUSTOMER_NOTIFICATIONS_DASHBOARD.visitPermission
                    }
                  >
                    <NotificationsDashboard />
                  </ProtectedComponent>
                }
              />
              <Route
                path={ROUTES.BRIEF_SUBMISSION_SUCCESS.route}
                element={
                  <ProtectedComponent
                    path={ROUTES.BRIEF_SUBMISSION_SUCCESS.route}
                    permission={ROUTES.BRIEF_SUBMISSION_SUCCESS.visitPermission}
                  >
                    <SubmissionSuccess />
                  </ProtectedComponent>
                }
              />
              <Route
                path={ROUTES.ONBOARDING.route}
                element={<OnboardingWrapper />}
              />
              <Route
                path={ROUTES.VERIFY_EMAIL.route}
                element={<VerifyEmail />}
              />
              <Route
                path={ROUTES.REGULATORY_INFO.route}
                element={<RegulatoryInfo />}
              />
              <Route
                path={ROUTES.FORBIDDEN_403.route}
                element={<Forbidden />}
              />
              <Route path="*" element={<NotFound />} />
            </Routes>
          </CustomerViewWarningProvider>
        </NotificationsContextProvider>
      </ErrorBoundary>
    </div>
  );
};
