import { TankSizeUnit } from './worksheet.types';

export const SECTIONS = {
  ORDER_QTY: 'orderQty',
  FORMULA: 'formula',
  LABOR: 'labor',
  COMPONENTS: 'components',
  MARGIN: 'margin',
};

export const WORKSHEET_FORM_KEYS = {
  ADDITIONAL_COMPONENT_ONE_A: 'additionalComponentOneA',
  ADDITIONAL_COMPONENT_ONE_B: 'additionalComponentOneB',
  ADDITIONAL_COMPONENT_ONE_C: 'additionalComponentOneC',
  ADDITIONAL_COMPONENT_THREE_A: 'additionalComponentThreeA',
  ADDITIONAL_COMPONENT_THREE_B: 'additionalComponentThreeB',
  ADDITIONAL_COMPONENT_THREE_C: 'additionalComponentThreeC',
  ADDITIONAL_COMPONENT_TWO_A: 'additionalComponentTwoA',
  ADDITIONAL_COMPONENT_TWO_B: 'additionalComponentTwoB',
  ADDITIONAL_COMPONENT_TWO_C: 'additionalComponentTwoC',
  BATCHING_HOURS_ONE: 'batchingHoursOne',
  BATCHING_HOURS_THREE: 'batchingHoursThree',
  BATCHING_HOURS_TWO: 'batchingHoursTwo',
  COMPOUNDING_HEAD_COUNT_ONE: 'compoundingHeadCountOne',
  COMPOUNDING_HEAD_COUNT_THREE: 'compoundingHeadCountThree',
  COMPOUNDING_HEAD_COUNT_TWO: 'compoundingHeadCountTwo',
  COMPOUNDING_PAY_RATE_ONE: 'compoundingPayRateOne',
  COMPOUNDING_PAY_RATE_THREE: 'compoundingPayRateThree',
  COMPOUNDING_PAY_RATE_TWO: 'compoundingPayRateTwo',
  EFFICIENCY_PERCENTAGE_ONE: 'efficiencyPercentageOne',
  EFFICIENCY_PERCENTAGE_THREE: 'efficiencyPercentageThree',
  EFFICIENCY_PERCENTAGE_TWO: 'efficiencyPercentageTwo',
  FILL_SIZE_ONE: 'fillSizeOne',
  FILL_SIZE_THREE: 'fillSizeThree',
  FILL_SIZE_TWO: 'fillSizeTwo',
  FILLING_HEAD_COUNT_ONE: 'fillingHeadCountOne',
  FILLING_HEAD_COUNT_THREE: 'fillingHeadCountThree',
  FILLING_HEAD_COUNT_TWO: 'fillingHeadCountTwo',
  FILLING_PAY_RATE_ONE: 'fillingPayRateOne',
  FILLING_PAY_RATE_THREE: 'fillingPayRateThree',
  FILLING_PAY_RATE_TWO: 'fillingPayRateTwo',
  INGREDIENTS: 'ingredients',
  MASTER_BOX_ONE: 'masterBoxOne',
  MASTER_BOX_THREE: 'masterBoxThree',
  MASTER_BOX_TWO: 'masterBoxTwo',
  NUMBER_OF_BATCHES_ONE: 'numberOfBatchesOne',
  NUMBER_OF_BATCHES_THREE: 'numberOfBatchesThree',
  NUMBER_OF_BATCHES_TWO: 'numberOfBatchesTwo',
  ORDER_QUANTITY_ONE: 'orderQuantityOne',
  ORDER_QUANTITY_THREE: 'orderQuantityThree',
  ORDER_QUANTITY_TWO: 'orderQuantityTwo',
  PROFIT_ONE: 'profitOne',
  PROFIT_THREE: 'profitThree',
  PROFIT_TWO: 'profitTwo',
  RUN_RATE_ONE: 'runRateOne',
  RUN_RATE_THREE: 'runRateThree',
  RUN_RATE_TWO: 'runRateTwo',
  SIZE_ONE_CUSTOMER_CPU_ONE: 'sizeOneCustomerCpuOne',
  SIZE_ONE_CUSTOMER_CPU_THREE: 'sizeOneCustomerCpuThree',
  SIZE_ONE_CUSTOMER_CPU_TWO: 'sizeOneCustomerCpuTwo',
  SIZE_ONE_QUANTITY: 'sizeOneQuantity',
  SIZE_ONE_UNIT: 'sizeOneUnit',
  SIZE_THREE_CUSTOMER_CPU_ONE: 'sizeThreeCustomerCpuOne',
  SIZE_THREE_CUSTOMER_CPU_THREE: 'sizeThreeCustomerCpuThree',
  SIZE_THREE_CUSTOMER_CPU_TWO: 'sizeThreeCustomerCpuTwo',
  SIZE_THREE_QUANTITY: 'sizeThreeQuantity',
  SIZE_THREE_UNIT: 'sizeThreeUnit',
  SIZE_TWO_CUSTOMER_CPU_ONE: 'sizeTwoCustomerCpuOne',
  SIZE_TWO_CUSTOMER_CPU_THREE: 'sizeTwoCustomerCpuThree',
  SIZE_TWO_CUSTOMER_CPU_TWO: 'sizeTwoCustomerCpuTwo',
  SIZE_TWO_QUANTITY: 'sizeTwoQuantity',
  SIZE_TWO_UNIT: 'sizeTwoUnit',
  TANK_SIZE: 'tankSize', // Legacy field can be removed eventually
  TANK_SIZE_ONE: 'tankSizeOne',
  TANK_SIZE_TWO: 'tankSizeTwo',
  TANK_SIZE_THREE: 'tankSizeThree',
  NOTES: 'notes',
  SIZE_ONE_PROFIT_ONE: 'sizeOneProfitOne',
  SIZE_ONE_PROFIT_THREE: 'sizeOneProfitThree',
  SIZE_ONE_PROFIT_TWO: 'sizeOneProfitTwo',
  SIZE_THREE_PROFIT_ONE: 'sizeThreeProfitOne',
  SIZE_THREE_PROFIT_THREE: 'sizeThreeProfitThree',
  SIZE_THREE_PROFIT_TWO: 'sizeThreeProfitTwo',
  SIZE_TWO_PROFIT_ONE: 'sizeTwoProfitOne',
  SIZE_TWO_PROFIT_THREE: 'sizeTwoProfitThree',
  SIZE_TWO_PROFIT_TWO: 'sizeTwoProfitTwo',
  SIZE_ONE_TANK_SIZE_ONE: 'sizeOneTankSizeOne',
  SIZE_ONE_TANK_SIZE_TWO: 'sizeOneTankSizeTwo',
  SIZE_ONE_TANK_SIZE_THREE: 'sizeOneTankSizeThree',
  SIZE_TWO_TANK_SIZE_ONE: 'sizeTwoTankSizeOne',
  SIZE_TWO_TANK_SIZE_TWO: 'sizeTwoTankSizeTwo',
  SIZE_TWO_TANK_SIZE_THREE: 'sizeTwoTankSizeThree',
  SIZE_THREE_TANK_SIZE_ONE: 'sizeThreeTankSizeOne',
  SIZE_THREE_TANK_SIZE_TWO: 'sizeThreeTankSizeTwo',
  SIZE_THREE_TANK_SIZE_THREE: 'sizeThreeTankSizeThree',
  SIZE_ONE_NUMBER_OF_BATCHES_ONE: 'sizeOneNumberOfBatchesOne',
  SIZE_ONE_NUMBER_OF_BATCHES_TWO: 'sizeOneNumberOfBatchesTwo',
  SIZE_ONE_NUMBER_OF_BATCHES_THREE: 'sizeOneNumberOfBatchesThree',
  SIZE_TWO_NUMBER_OF_BATCHES_ONE: 'sizeTwoNumberOfBatchesOne',
  SIZE_TWO_NUMBER_OF_BATCHES_TWO: 'sizeTwoNumberOfBatchesTwo',
  SIZE_TWO_NUMBER_OF_BATCHES_THREE: 'sizeTwoNumberOfBatchesThree',
  SIZE_THREE_NUMBER_OF_BATCHES_ONE: 'sizeThreeNumberOfBatchesOne',
  SIZE_THREE_NUMBER_OF_BATCHES_TWO: 'sizeThreeNumberOfBatchesTwo',
  SIZE_THREE_NUMBER_OF_BATCHES_THREE: 'sizeThreeNumberOfBatchesThree',
};

export const orderQuantityData = {
  title: 'Order Qty',
  formikKeys: [
    WORKSHEET_FORM_KEYS.ORDER_QUANTITY_ONE,
    WORKSHEET_FORM_KEYS.ORDER_QUANTITY_TWO,
    WORKSHEET_FORM_KEYS.ORDER_QUANTITY_THREE,
  ],
};

export const componentCosts = [
  {
    title: 'Master Box',
    formikKeys: [
      WORKSHEET_FORM_KEYS.MASTER_BOX_ONE,
      WORKSHEET_FORM_KEYS.MASTER_BOX_TWO,
      WORKSHEET_FORM_KEYS.MASTER_BOX_THREE,
    ],
    leadingText: '$',
  },
  {
    title: "Add'l Component 1",
    formikKeys: [
      WORKSHEET_FORM_KEYS.ADDITIONAL_COMPONENT_ONE_A,
      WORKSHEET_FORM_KEYS.ADDITIONAL_COMPONENT_TWO_A,
      WORKSHEET_FORM_KEYS.ADDITIONAL_COMPONENT_THREE_A,
    ],
    leadingText: '$',
  },
  {
    title: "Add'l Component 2",
    formikKeys: [
      WORKSHEET_FORM_KEYS.ADDITIONAL_COMPONENT_ONE_B,
      WORKSHEET_FORM_KEYS.ADDITIONAL_COMPONENT_TWO_B,
      WORKSHEET_FORM_KEYS.ADDITIONAL_COMPONENT_THREE_B,
    ],
    leadingText: '$',
  },
  {
    title: "Add'l Component 3",
    formikKeys: [
      WORKSHEET_FORM_KEYS.ADDITIONAL_COMPONENT_ONE_C,
      WORKSHEET_FORM_KEYS.ADDITIONAL_COMPONENT_TWO_C,
      WORKSHEET_FORM_KEYS.ADDITIONAL_COMPONENT_THREE_C,
    ],
    leadingText: '$',
  },
];

export const TANK_SIZE_UNIT_TOGGLE_INPUT = 'tankSizeUnitToggle';
export const TANK_SIZE_TITLE = 'Tank Size';
export const NUMBER_OF_BATCHES_TITLE = 'Number of Batches';

export const laborCostInputs = [
  {
    title: 'Batching:',
  },
  {
    title: TANK_SIZE_TITLE,
    formikKeys: [undefined, undefined, undefined, TANK_SIZE_UNIT_TOGGLE_INPUT],
    trailingText: (unit: keyof typeof TankSizeUnit) => unit,
  },
  {
    title: NUMBER_OF_BATCHES_TITLE,
    formikKeys: [
      WORKSHEET_FORM_KEYS.NUMBER_OF_BATCHES_ONE,
      WORKSHEET_FORM_KEYS.NUMBER_OF_BATCHES_TWO,
      WORKSHEET_FORM_KEYS.NUMBER_OF_BATCHES_THREE,
    ],
  },
  {
    title: 'Batching Hours',
    formikKeys: [
      WORKSHEET_FORM_KEYS.BATCHING_HOURS_ONE,
      WORKSHEET_FORM_KEYS.BATCHING_HOURS_TWO,
      WORKSHEET_FORM_KEYS.BATCHING_HOURS_THREE,
    ],
  },
  {
    title: 'Run rate per minute',
    formikKeys: [
      WORKSHEET_FORM_KEYS.RUN_RATE_ONE,
      WORKSHEET_FORM_KEYS.RUN_RATE_TWO,
      WORKSHEET_FORM_KEYS.RUN_RATE_THREE,
    ],
  },
  {
    title: 'Efficiency %',
    formikKeys: [
      WORKSHEET_FORM_KEYS.EFFICIENCY_PERCENTAGE_ONE,
      WORKSHEET_FORM_KEYS.EFFICIENCY_PERCENTAGE_TWO,
      WORKSHEET_FORM_KEYS.EFFICIENCY_PERCENTAGE_THREE,
    ],
    trailingText: '%',
  },
  {
    title: 'Filling:',
  },
  {
    title: 'Head Count',
    formikKeys: [
      WORKSHEET_FORM_KEYS.FILLING_HEAD_COUNT_ONE,
      WORKSHEET_FORM_KEYS.FILLING_HEAD_COUNT_TWO,
      WORKSHEET_FORM_KEYS.FILLING_HEAD_COUNT_THREE,
    ],
  },
  {
    title: 'Pay Rate',
    formikKeys: [
      WORKSHEET_FORM_KEYS.FILLING_PAY_RATE_ONE,
      WORKSHEET_FORM_KEYS.FILLING_PAY_RATE_TWO,
      WORKSHEET_FORM_KEYS.FILLING_PAY_RATE_THREE,
    ],
    leadingText: '$',
    trailingText: '/hr',
  },
  {
    title: 'Compounding:',
  },
  {
    title: 'Head Count',
    formikKeys: [
      WORKSHEET_FORM_KEYS.COMPOUNDING_HEAD_COUNT_ONE,
      WORKSHEET_FORM_KEYS.COMPOUNDING_HEAD_COUNT_TWO,
      WORKSHEET_FORM_KEYS.COMPOUNDING_HEAD_COUNT_THREE,
    ],
  },
  {
    title: 'Pay Rate',
    formikKeys: [
      WORKSHEET_FORM_KEYS.COMPOUNDING_PAY_RATE_ONE,
      WORKSHEET_FORM_KEYS.COMPOUNDING_PAY_RATE_TWO,
      WORKSHEET_FORM_KEYS.COMPOUNDING_PAY_RATE_THREE,
    ],
    leadingText: '$',
    trailingText: '/hr',
  },
];

export const FILL_SIZE_KEYS = [
  {
    unit: WORKSHEET_FORM_KEYS.SIZE_ONE_UNIT,
    size: WORKSHEET_FORM_KEYS.SIZE_ONE_QUANTITY,
  },
  {
    unit: WORKSHEET_FORM_KEYS.SIZE_TWO_UNIT,
    size: WORKSHEET_FORM_KEYS.SIZE_TWO_QUANTITY,
  },
  {
    unit: WORKSHEET_FORM_KEYS.SIZE_THREE_UNIT,
    size: WORKSHEET_FORM_KEYS.SIZE_THREE_QUANTITY,
  },
];

export const FILL_SIZE_MENU_OPTIONS = {
  EDIT: 'edit',
  DELETE: 'delete',
};

export const QUOTE_MENU_OPTIONS = {
  ARCHIVE: 'archive',
  UNARCHIVE: 'unarchive',
};
