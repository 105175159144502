// Libraries
import React from 'react';
import { Link } from 'react-router-dom';
// Utils
import { IApiData, ProjectAttributes } from 'api';
import { Checkbox, Divider, Typography } from 'design-system';

interface ConceptProductSpecificShowPageProps {
  project: IApiData<ProjectAttributes>;
}

export const ConceptProductSpecificShowPage: React.FC<ConceptProductSpecificShowPageProps> = ({
  project,
}) => {
  const benchmarks = project.parsedIncluded?.brief?.attributes?.bMarks;

  const renderBenchmarks = () => {
    return benchmarks.map((benchmark: any, index: number) => {
      return (
        <div
          key={index}
          className="flex flex-col gap-6 w-full p-6 rounded border border-grey-90"
        >
          <Typography font="inter" variant="h5" weight="semibold">
            Benchmark {index + 1}
          </Typography>
          <div className="flex flex-row gap-x-9">
            <div className="flex flex-col gap-y-4">
              <Typography
                font="inter"
                variant="h6"
                weight="semibold"
                color="secondary"
              >
                Brand Name
              </Typography>
              <Typography font="inter" variant="h4">
                {benchmark?.attributes?.brandName}
              </Typography>
            </div>
            <div className="flex flex-col gap-y-4">
              <Typography
                font="inter"
                variant="h6"
                weight="semibold"
                color="secondary"
              >
                Product Name
              </Typography>
              <Typography font="inter" variant="h4">
                {benchmark?.attributes?.productName}
              </Typography>
            </div>
          </div>
          <div className="flex flex-col gap-y-4">
            <Typography font="inter" variant="h6" color="secondary">
              Product Link
            </Typography>
            <Typography font="inter" variant="h4" color="secondary">
              http://
              {
                <Link
                  to={benchmark?.attributes?.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-royal-50"
                >
                  {benchmark?.attributes?.url}
                </Link>
              }
            </Typography>
          </div>
          <div className="flex flex-col gap-y-3">
            <Typography font="inter" variant="h6" weight="semibold">
              Likes
            </Typography>
            <Checkbox
              primaryLabel="Appearance"
              checked={benchmark?.attributes?.appearance}
              disabled
            />
            <Checkbox
              primaryLabel="Color"
              checked={benchmark?.attributes?.color}
              disabled
            />
            <Checkbox
              primaryLabel="Fragrance"
              checked={benchmark?.attributes?.fragrance}
              disabled
            />
            <Checkbox
              primaryLabel="Texture"
              checked={benchmark?.attributes?.texture}
              disabled
            />
            <Checkbox
              primaryLabel="Viscosity"
              checked={benchmark?.attributes?.viscosity}
              disabled
            />
          </div>
          <div className="flex flex-col gap-y-5">
            <Typography
              font="inter"
              variant="h6"
              color="secondary"
              weight="semibold"
            >
              Additional Comments
            </Typography>
            <Typography font="inter" variant="h4">
              {benchmark?.attributes?.comment}
            </Typography>
          </div>
        </div>
      );
    });
  };

  return (
    <div className="flex flex-col gap-y-12">
      <div className="flex flex-col gap-y-6">
        <Typography font="inter" variant="h3" id="product-vision">
          Product Vision
        </Typography>
        <Typography font="inter" variant="h4">
          {project.attributes.description}
        </Typography>
      </div>

      <Divider />

      <div className="flex flex-col gap-y-6">
        <Typography font="inter" variant="h3">
          Benchmarks
        </Typography>
        <Typography font="inter" variant="h3" color="secondary">
          Benchmarks are products on the market whose qualities you’d like to
          emulate.
        </Typography>
        <div className="flex flex-col gap-6" id="benchmarks">
          {benchmarks.length > 0
            ? renderBenchmarks()
            : 'No benchmarks available'}
        </div>
      </div>
    </div>
  );
};
