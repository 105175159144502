// Libraries
import React from 'react';
import { Checkbox, Typography } from 'design-system';
import { CheckIcon } from '@heroicons/react/16/solid';
// Utils
import { IApiData, ProjectAttributes } from 'api';

interface TestingShowPageProps {
  project: IApiData<ProjectAttributes>;
}

export const TestingShowPage: React.FC<TestingShowPageProps> = ({
  project,
}) => {
  return (
    <div className="flex flex-col gap-y-6">
      <Typography font="inter" variant="h3" id="rd">
        R&D Documents
      </Typography>
      <div>
        <div className="flex flex-col gap-y-3">
          <Typography font="inter" variant="h4" weight="semibold">
            What R&D documents are needed?
          </Typography>
          <div className="flex flex-row items-center gap-x-1">
            <CheckIcon className="w-4 h-4 text-grey-60" />
            <Typography font="inter" variant="h4">
              Preliminary IL per submission (Standard)
            </Typography>
          </div>
          <div className="flex flex-row items-center gap-x-1">
            <CheckIcon className="w-4 h-4 text-grey-60" />
            <Typography font="inter" variant="h4">
              Estimated Quote (Standard)
            </Typography>
          </div>
          <Checkbox
            primaryLabel="COA"
            disabled
            checked={Boolean(project.attributes?.coa)}
          />
        </div>
      </div>
      <Typography font="inter" variant="h4">
        {project.attributes?.otherRAndDDocuments}
      </Typography>
    </div>
  );
};
