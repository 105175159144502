// Libraries
import React from 'react';
import { useFormikContext } from 'formik';
// Utils
import { SectionProps } from './types';
// Components
import { SectionHeader } from '../section-header.component';
import { TextField } from 'design-system';
import { USDollar } from 'features/utils';
// Constants
import { WORKSHEET_FORM_KEYS } from '../constants';
import { highlightInput } from '../utils';
import { SIZES } from '../worksheet.types';

export const MarginSection: React.FC<SectionProps> = ({
  costs,
  isActive,
  isPriceQuoteEditable,
  selectedSizeIndex,
}) => {
  const { getFieldProps } = useFormikContext();

  const getProfitInputsFromSizeIndex = () => {
    switch (selectedSizeIndex) {
      case SIZES.ONE:
        return {
          profitOne: WORKSHEET_FORM_KEYS.SIZE_ONE_PROFIT_ONE,
          profitTwo: WORKSHEET_FORM_KEYS.SIZE_ONE_PROFIT_TWO,
          profitThree: WORKSHEET_FORM_KEYS.SIZE_ONE_PROFIT_THREE,
        };
      case SIZES.TWO:
        return {
          profitOne: WORKSHEET_FORM_KEYS.SIZE_TWO_PROFIT_ONE,
          profitTwo: WORKSHEET_FORM_KEYS.SIZE_TWO_PROFIT_TWO,
          profitThree: WORKSHEET_FORM_KEYS.SIZE_TWO_PROFIT_THREE,
        };
      case SIZES.THREE:
        return {
          profitOne: WORKSHEET_FORM_KEYS.SIZE_THREE_PROFIT_ONE,
          profitTwo: WORKSHEET_FORM_KEYS.SIZE_THREE_PROFIT_TWO,
          profitThree: WORKSHEET_FORM_KEYS.SIZE_THREE_PROFIT_THREE,
        };
      default:
        return {
          profitOne: WORKSHEET_FORM_KEYS.PROFIT_ONE,
          profitTwo: WORKSHEET_FORM_KEYS.PROFIT_TWO,
          profitThree: WORKSHEET_FORM_KEYS.PROFIT_THREE,
        };
    }
  };

  const { profitOne, profitTwo, profitThree } = getProfitInputsFromSizeIndex();

  return (
    <div
      className={`${isActive ? 'h-auto' : 'h-0 overflow-hidden'} flex flex-col`}
    >
      <SectionHeader title="Margin" />
      <div className="px-3 bg-white">
        <div className="grid grid-cols-10 py-[6px] items-center min-h-[44px]">
          <div className="flex flex-col col-span-2 text-grey-50">
            <span>Cost</span>
            <span className="text-xs">Formula + Labor + Component</span>
          </div>
          <span className="col-span-2 mr-6">
            {USDollar.format(costs[0].totalCost || 0)}
          </span>
          <span className="col-span-2 mr-6">
            {USDollar.format(costs[1].totalCost || 0)}
          </span>
          <span className="col-span-2 mr-6">
            {USDollar.format(costs[2].totalCost || 0)}
          </span>
        </div>
        <div className="grid grid-cols-10 py-[6px] items-center min-h-[44px]">
          <span className="col-span-2 text-grey-50">Profit</span>
          <div className="col-span-2 mr-6">
            <TextField
              {...getFieldProps(profitOne)}
              disabled={!isPriceQuoteEditable}
              height="h-11"
              onFocus={highlightInput}
              leadingText={'$'}
              type="number"
            />
          </div>
          <div className="col-span-2 mr-6">
            <TextField
              {...getFieldProps(profitTwo)}
              disabled={!isPriceQuoteEditable}
              height="h-11"
              onFocus={highlightInput}
              leadingText={'$'}
              type="number"
            />
          </div>
          <div className="col-span-2 mr-6">
            <TextField
              {...getFieldProps(profitThree)}
              disabled={!isPriceQuoteEditable}
              height="h-11"
              onFocus={highlightInput}
              leadingText={'$'}
              type="number"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
