// Libraries
import React from 'react';
// Components
import { Divider } from 'design-system';
import {
  InitialOrderQuantity,
  Timeline,
  TargetCogs,
  FillSize,
  PointOfContact,
  Basics,
  ProductBasics,
} from '../form-modules';
// Utils
import { ProductionLocation } from '../form-modules/production-location.component';

export const ProjectOverview: React.FC = () => {
  return (
    <>
      <Basics />
      <Divider />
      <PointOfContact />
      <Divider />
      <ProductBasics />
      <FillSize />
      <Divider />
      <InitialOrderQuantity />
      <Divider />
      <TargetCogs />
      <Divider />
      <Timeline />
      <Divider />
      <ProductionLocation />
    </>
  );
};
