// Libraries
import React from 'react';
// Utils
import { IApiData, ProjectAttributes } from 'api';
import { Typography } from 'design-system';

interface PackagingShowPageProps {
  project: IApiData<ProjectAttributes>;
}

export const PackagingShowPage: React.FC<PackagingShowPageProps> = ({
  project,
}) => {
  const brief = project.parsedIncluded?.brief;
  const briefSupplement = brief.attributes?.briefSupplement?.data?.attributes;

  return (
    <div className="flex flex-col gap-y-6">
      <Typography font="inter" variant="h3">
        Components
      </Typography>
      <div className="flex flex-col gap-y-3">
        <Typography font="inter" variant="h4" weight="bold">
          Primary Component
        </Typography>
        <Typography font="inter" variant="h4">
          {briefSupplement?.container}
        </Typography>
      </div>
      <div className="flex flex-col gap-y-3">
        <Typography font="inter" variant="h4" weight="bold">
          Material
        </Typography>
        <Typography font="inter" variant="h4">
          {briefSupplement?.material}
        </Typography>
      </div>
      <div className="flex flex-col gap-y-3">
        <Typography font="inter" variant="h4" weight="bold">
          Closure
        </Typography>
        <Typography font="inter" variant="h4">
          {briefSupplement?.closure}
        </Typography>
      </div>
    </div>
  );
};
