// Libraries
import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import {
  TextField,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
} from '@material-ui/core';
import { Alert, Autocomplete } from '@material-ui/lab';
// Components
import { EmployeeAvatar } from './employee-avatar.component';
// Utils
import { useApi } from 'api';

interface IAssignmentDialog {
  open: boolean;
  item: any;
  assignableItemType: string;
  handleClose: () => void;
  AssignEmployeesToItem: (itemId: string, employeeIds: Array<string>) => void;
}

function getEmployeeFullName(employee: any) {
  return `${employee.attributes.givenName} ${employee.attributes.familyName}`;
}

export const AssignmentDialog: React.FC<IAssignmentDialog> = ({
  item,
  open,
  assignableItemType,
  AssignEmployeesToItem,
  handleClose,
}) => {
  const { getEmployees } = useApi();
  const [employees, setEmployees] = useState<Array<any>>([]);
  const [isError, setIsError] = useState<boolean>(false);

  useEffect(() => {
    open &&
      getEmployees({
        urlParams: '',
        handleSuccess: (response) => {
          setEmployees(response.data);
        },
        handleError: (error) => {
          setIsError(true);
          Rollbar.error(error);
        },
      });
  }, [open, getEmployees]);

  const [selectedEmployeeNames, setSelectedEmployeeNames] = useState<
    Array<any>
  >([]);

  useEffect(() => {
    setSelectedEmployeeNames(
      item?.attributes?.employees?.data?.map(getEmployeeFullName)
    );
  }, [item]);

  const handleEmployeeAssign = (
    event: React.ChangeEvent<any>,
    employeeNames: Array<string>
  ) => {
    setSelectedEmployeeNames([...employeeNames]);
  };

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    const employeeIds = selectedEmployeeNames.map((selectedEmployeeName) => {
      return employees.find(
        (employee) => getEmployeeFullName(employee) === selectedEmployeeName
      ).id;
    });

    AssignEmployeesToItem(item.id, employeeIds);
    handleClose();
  };

  const cancelAssignment = () => {
    setSelectedEmployeeNames(
      item?.attributes?.employees?.data?.map(getEmployeeFullName)
    );
    handleClose();
  };

  return (
    <Dialog open={open}>
      {isError ? (
        <Alert variant="filled" severity="error">
          There was an error loading employees
        </Alert>
      ) : (
        <DialogTitle>{`Assign to a ${
          assignableItemType.charAt(0).toUpperCase() +
          assignableItemType.slice(1)
        }`}</DialogTitle>
      )}
      <DialogContent>
        <DialogContentText>
          {`Search and select names from the dropdown below to add them to this
          ${
            assignableItemType.charAt(0).toLowerCase() +
            assignableItemType.slice(1)
          }`}
        </DialogContentText>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                disabled
                value={item && item.attributes.name}
                fullWidth
              />
              <Autocomplete
                multiple
                disabled={isError}
                disableCloseOnSelect
                value={selectedEmployeeNames}
                onChange={handleEmployeeAssign}
                options={employees.map(getEmployeeFullName)}
                renderOption={(employeeFullName, { selected }) => {
                  const [givenName, familyName] = employeeFullName.split(' ');
                  return (
                    <>
                      <EmployeeAvatar
                        givenName={givenName}
                        familyName={familyName}
                        avatarColor={
                          employees.find(
                            (employee) =>
                              getEmployeeFullName(employee) === employeeFullName
                          ).attributes.avatarColor
                        }
                      />
                      {employeeFullName}
                    </>
                  );
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Employees"
                    margin="normal"
                    variant="outlined"
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}></Grid>
            <Grid item xs={4}>
              <Button
                fullWidth
                type="submit"
                variant="contained"
                color="primary"
                disabled={isError}
              >
                Assign/Unassign
              </Button>
            </Grid>
            <Grid item xs={2}>
              <Button
                fullWidth
                variant="contained"
                color="primary"
                onClick={cancelAssignment}
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  );
};
