// Libraries
import React from 'react';
// Components
import { Select } from './select.component';
import { FormulaSearchMenu } from 'features/formula';

interface FormulaSelectProps {
  formulas: Option[];
  selectedFormula: string;
  isLoading: boolean;
  onSelect: (formula: any) => void;
}

export const FormulaSelect: React.FC<FormulaSelectProps> = ({
  formulas,
  selectedFormula,
  isLoading,
  onSelect,
}) => {
  return (
    <div>
      <Select
        CustomMenu={({ onClose }) => (
          <FormulaSearchMenu
            options={formulas}
            isLoading={isLoading}
            onChange={onSelect}
            onClose={onClose}
            searchType="searchFormulaNumber"
          />
        )}
        inputHasLabel
        isLoading={isLoading}
        options={formulas}
        value={selectedFormula}
      />
    </div>
  );
};
