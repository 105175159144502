// Libraries
import React from 'react';
// Utils
import { IApiData, ProjectAttributes } from 'api';
import { Divider, Typography } from 'design-system';

interface FormulaSamplesShowPageProps {
  project: IApiData<ProjectAttributes>;
}

export const FormulaSamplesShowPage: React.FC<FormulaSamplesShowPageProps> = ({
  project,
}) => {
  const sampleRecipients = project.parsedIncluded?.sampleRecipients;

  const renderSampleRecipients = () => {
    return sampleRecipients.map((recipient: any, index: string) => (
      <div
        key={index}
        className="flex flex-col gap-y-6 w-full p-6 rounded-[4px] border border-grey-90"
      >
        <div>
          <div className="flex flex-col gap-y-2">
            <Typography font="inter" variant="h6" color="secondary">
              Name
            </Typography>
            <Typography font="inter" variant="h4">
              {recipient?.attributes?.fullName}
            </Typography>
          </div>
        </div>
        <div className="flex flex-col gap-y-4">
          <Typography font="inter" variant="h6" color="secondary">
            Address Line 1
          </Typography>
          <Typography font="inter" variant="h4">
            {recipient?.attributes?.address1}
          </Typography>
        </div>
        <div className="flex flex-col gap-y-4">
          <Typography font="inter" variant="h6" color="secondary">
            Address Line 2
          </Typography>
          <Typography font="inter" variant="h4">
            {recipient?.attributes?.address2}
          </Typography>
        </div>
        <div className="flex flex-row justify-between">
          <div className="flex flex-col gap-y-4 w-full">
            <Typography font="inter" variant="h6" color="secondary">
              City
            </Typography>
            <Typography font="inter" variant="h4">
              {recipient?.attributes?.city}
            </Typography>
          </div>
          <div className="flex flex-col gap-y-4 w-full">
            <Typography font="inter" variant="h6" color="secondary">
              State
            </Typography>
            <Typography font="inter" variant="h4">
              {recipient?.attributes?.state}
            </Typography>
          </div>
          <div className="flex flex-col gap-y-4 w-full">
            <Typography font="inter" variant="h6" color="secondary">
              Zip
            </Typography>
            <Typography font="inter" variant="h4">
              {recipient?.attributes?.zip}
            </Typography>
          </div>
        </div>
        <div className="flex flex-row justify-between gap-x-9">
          <div className="flex flex-col gap-y-4 w-full">
            <Typography font="inter" variant="h6" color="secondary">
              Email
            </Typography>
            <Typography font="inter" variant="h4">
              {recipient?.attributes?.email}
            </Typography>
          </div>
          <div className="flex flex-col gap-y-4 w-full">
            <Typography font="inter" variant="h6" color="secondary">
              Phone
            </Typography>
            <Typography font="inter" variant="h4">
              {recipient?.attributes?.phone}
            </Typography>
          </div>
        </div>
      </div>
    ));
  };

  return (
    <div className="flex flex-col gap-y-8">
      <div className="flex flex-col gap-y-12">
        <Typography font="inter" variant="h3" id="sample-quantity">
          Sample Quantity
        </Typography>
        <div className="flex flex-col gap-y-5">
          <Typography
            font="inter"
            variant="h6"
            weight="semibold"
            color="secondary"
          >
            Desired number of samples
          </Typography>
          {project.attributes?.sampleQuantity ? (
            <div className="flex flex-row items-center gap-x-3">
              <Typography font="inter" variant="h4">
                {project.attributes?.sampleQuantity}
              </Typography>
              <Typography font="inter" variant="h4" color="secondary">
                per recipient
              </Typography>
            </div>
          ) : (
            <Typography font="inter" variant="h4" color="secondary">
              Not Specified
            </Typography>
          )}
        </div>
        <Divider />
      </div>

      <div className="flex flex-col gap-y-6">
        <Typography font="inter" variant="h3" id="sample-recipients">
          Sample Recipients
        </Typography>
        <Typography font="inter" variant="h4" color="secondary">
          Shipping information for sample recipients{' '}
          {<span className="font-inter font-bold">other</span>} than the point
          of contact.
        </Typography>
        {sampleRecipients?.length ? (
          renderSampleRecipients()
        ) : (
          <Typography font="inter" variant="h4" color="secondary">
            No sample recipients
          </Typography>
        )}
      </div>
    </div>
  );
};
