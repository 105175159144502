// Libraries
import React from 'react';
import { PencilSquareIcon } from '@heroicons/react/16/solid';
import { Link } from 'react-router-dom';
// Components
import { Typography } from 'design-system';
// Utils
import {
  BriefAttributes,
  IApiData,
  ProjectAttributes,
  WorksheetAttributes,
} from 'api';
// Constants
import { ROUTES, UUID_SHOW_ROUTE_STRING } from 'features/navigation';
import { USDollar } from 'features/utils';
import { MASTER_BOX_OPTIONS } from 'features/brief/form-modules/master-box.component';

interface WorksheetInfoProps {
  isPriceQuoteEditable: boolean;
  project: Maybe<IApiData<ProjectAttributes>> & {
    brief: IApiData<BriefAttributes>;
  };
  formulaName: string;
  formulaExternalId: string;
  formulaUuid: string;
  worksheet: IApiData<WorksheetAttributes>;
}

export const WorksheetInfo: React.FC<WorksheetInfoProps> = ({
  isPriceQuoteEditable,
  project,
  formulaName,
  formulaExternalId,
  formulaUuid,
  worksheet,
}) => {
  const formulaLink = `${ROUTES.SHOW_FORMULA.route.replace(
    UUID_SHOW_ROUTE_STRING,
    formulaUuid
  )}`;

  const renderTargetCost = () => {
    const { attributes } = project?.brief || {};

    if (attributes?.targetMinCost && attributes?.targetMaxCost) {
      return USDollar.formatRange(
        attributes?.targetMinCost,
        attributes?.targetMaxCost as number
      );
    }

    return USDollar.format(attributes?.targetCost as number);
  };

  const renderMasterBox = () => {
    return MASTER_BOX_OPTIONS.find(
      (option) =>
        (project.brief as any).attributes.briefSupplement.data.attributes
          .masterBox === option.value
    )?.label;
  };

  return (
    <div className="col-start-2 col-span-10 flex justify-between mt-[60px]">
      <div className="flex flex-col gap-1">
        <div className="flex flex-col gap-y-1">
          <Link to={formulaLink} target="_blank">
            <Typography
              variant="h5"
              color="hyperlink"
              font="inter"
            >{`Formula #${formulaExternalId}`}</Typography>
          </Link>
          <Typography variant="h5" color="secondary" font="inter">
            {`${formulaName}`}
          </Typography>
          <Typography variant="h5" color="secondary" font="inter">
            {`${(project as any).company.attributes.name}`}
          </Typography>
        </div>
        <div className="text-lg">
          Pricing Worksheet V{worksheet.attributes.quoteVersion}
        </div>
      </div>
      <div className="flex gap-9 border border-grey-95 p-4">
        <div className="flex flex-col gap-1">
          <span className="font-mono text-xs uppercase text-grey-50">
            Location
          </span>
          <span>
            {
              (project.brief as any).attributes.briefSupplement.data.attributes
                .productionLocation
            }
          </span>
        </div>
        <div className="flex flex-col gap-1">
          <span className="font-mono text-xs uppercase text-grey-50">
            Target Cogs
          </span>
          <span>{renderTargetCost()}</span>
        </div>
        <div className="flex flex-col gap-1">
          <span className="font-mono text-xs uppercase text-grey-50">
            Primary Component
          </span>
          <span>
            {project.attributes.productType}/
            {project.brief.attributes.structure}
          </span>
        </div>
        <div className="flex flex-col gap-1">
          <span className="font-mono text-xs uppercase text-grey-50">
            Master Box
          </span>
          <span>{renderMasterBox()}</span>
        </div>
        {isPriceQuoteEditable && (
          <div className="cursor-pointer">
            <Link
              to={ROUTES.SHOW_PROJECT.route.replace(
                UUID_SHOW_ROUTE_STRING,
                project!.id
              )}
              target="_blank"
            >
              <PencilSquareIcon className="h-4 w-4 text-royal-50" />
            </Link>
          </div>
        )}
      </div>
    </div>
  );
};
