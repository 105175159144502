export const CHEMICAL_FAMILY_RELATIONSHIP_TYPES = {
  BANNED: 'banned',
  REQUIRED: 'required',
  MIN_AMOUNT: 'minAmount',
  MAX_AMOUNT: 'maxAmount',
};

export const CHEMICAL_FAMILY_TYPES = {
  CLAIM_GROUP: 'claimGroup',
  INCI_FAMILY: 'inciFamily',
  RETAILER_STANDARD: 'retailerStandard',
  CLIENT_NO_NO_LIST: 'clientNoNoList',
};

export const CHEMICAL_FAMILY_ASSOCIABLE_TYPES = {
  INCI: 'Inci',
  RAW_MATERIAL: 'RawMaterial',
  CHEMICAL_FAMILY: 'ChemicalFamily',
};

export const CHEMICAL_FAMILY_TABLE_COLUMNS = {
  NAME: 'name',
  TYPE: 'type',
  CREATED_BY: 'createdBy',
  UPDATED_AT: 'updatedAt',
};

export const CHEMICAL_FAMILY_TABLE_SORT_OPTIONS = [
  // This first option is a placeholder for the icon that appears next to the name column
  { label: '', value: '' },
  { label: 'Name', value: CHEMICAL_FAMILY_TABLE_COLUMNS.NAME },
  { label: 'Type', value: CHEMICAL_FAMILY_TABLE_COLUMNS.TYPE },
  { label: 'Added By', value: CHEMICAL_FAMILY_TABLE_COLUMNS.CREATED_BY },
  { label: 'Last Modified', value: CHEMICAL_FAMILY_TABLE_COLUMNS.UPDATED_AT },
];

export const CHEMICAL_FAMILY_TYPE_OPTIONS = [
  { label: 'Claim Groups', value: CHEMICAL_FAMILY_TYPES.CLAIM_GROUP },
  { label: 'INCI Families', value: CHEMICAL_FAMILY_TYPES.INCI_FAMILY },
  {
    label: 'Retailer Standards',
    value: CHEMICAL_FAMILY_TYPES.RETAILER_STANDARD,
  },
  { label: 'Client No-Nos', value: CHEMICAL_FAMILY_TYPES.CLIENT_NO_NO_LIST },
];

export const CHEMICAL_FAMILY_RELATIONSHIP_TYPE_OPTIONS = [
  { label: 'Banned', value: CHEMICAL_FAMILY_RELATIONSHIP_TYPES.BANNED },
  { label: 'Required', value: CHEMICAL_FAMILY_RELATIONSHIP_TYPES.REQUIRED },
  { label: 'Min Amount', value: CHEMICAL_FAMILY_RELATIONSHIP_TYPES.MIN_AMOUNT },
  { label: 'Max Amount', value: CHEMICAL_FAMILY_RELATIONSHIP_TYPES.MAX_AMOUNT },
];
