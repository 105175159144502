// Libraries
import React from 'react';
import { motion, AnimatePresence } from 'motion/react';

interface ModalProps {
  children: JSX.Element | JSX.Element[];
  handleClose: () => void;
}

export const Modal: React.FC<ModalProps> = ({ children, handleClose }) => {
  const handleOutsideModalClick = (event: any) => {
    event.stopPropagation();
    if (event.target === event.currentTarget) {
      handleClose();
    }
  };

  return (
    <div
      onMouseDown={handleOutsideModalClick}
      className="overscroll-none flex items-center justify-center fixed z-[1401] w-full h-full top-0 left-0 bg-black bg-opacity-25"
    >
      <AnimatePresence mode="wait">
        <motion.div
          layout
          initial={{ top: '100vh' }}
          animate={{ top: 0 }}
          exit={{ top: '100vh' }}
          transition={{ duration: 0.3, ease: 'linear' }}
          className="z-50 relative h-auto w-fit-content flex flex-col rounded shadow-md bg-white"
        >
          {children}
        </motion.div>
      </AnimatePresence>
    </div>
  );
};
