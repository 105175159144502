// Libraries
import React from 'react';
import _ from 'lodash';

interface VerticalCardProps {
  title: string | React.ReactNode;
  content?: string | string[];
  icon?: React.ReactComponentElement<any> | null;
  iconColor?: string;
  image?: string;
  isDisabled?: boolean;
  action?: () => void;
  [key: string]: any;
}

export const VerticalCard: React.FC<VerticalCardProps> = ({
  title,
  content,
  icon: Icon,
  iconColor,
  isDisabled,
  image,
  action,
  ...rest
}) => {
  const renderContent = () => {
    if (Array.isArray(content)) {
      return (
        <div className="flex flex-col">
          {content.map((item, index) => {
            return (
              <p
                key={index}
                className="ml-4 mb-1 mr-4 font-inter text-xs text-grey-50"
              >
                {item}
              </p>
            );
          })}
        </div>
      );
    }
    return (
      <p className="ml-4 mb-4 mr-4 font-inter text-xs text-grey-50">
        {content}
      </p>
    );
  };

  return (
    <div
      className={`max-w-[200px] border border-grey-90 rounded
        ${!isDisabled && 'hover:bg-blue-95 active:border-royal-50'}
        ${!isDisabled && action && 'cursor-pointer'}
        ${isDisabled && 'bg-grey-95'} ${rest.className || ''}`}
      onClick={action || undefined}
      {..._.omit(rest, ['className'])}
    >
      {image && (
        <div className="relative overflow-hidden bg-cover bg-no-repeat">
          <img
            src={image}
            width={200}
            alt=""
            className="h-full md:h-auto object-cover md:object-fill"
          />
        </div>
      )}
      <div>
        <div className="flex flex-row items-center m-4">
          {Icon &&
            React.cloneElement(Icon, {
              className: `${iconColor} mr-2`,
              height: 14,
              width: 14,
            })}
          <p className="font-inter text-base text-grey-20">{title}</p>
        </div>
        {content && renderContent()}
      </div>
    </div>
  );
};
