// Libraries
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { useIdleTimer } from 'react-idle-timer';
// Components
import { Button, Modal } from 'design-system';
// Constants
import { BASE_URL, ROUTES } from 'features/navigation';
import { LOGIN_REQUIRED } from 'api/api.constants';

const getTimeout = () => {
  switch (process.env.REACT_APP_ENV) {
    case 'development':
      return 100 * 60 * 60 * 1000; // 100 hours
    case 'staging':
      return 30 * 60 * 1000; // 30 minutes
    default:
      return 30 * 60 * 1000; // 30 minutes
  }
};

const timeout = getTimeout();
const promptBeforeIdle = 2 * 60 * 1000; // 2 minutes before timeout

export const ActivityTracker = () => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const [remaining, setRemaining] = useState<number>(timeout);
  const { getAccessTokenSilently, logout } = useAuth0();
  const navigate = useNavigate();

  // This effect is used to check if the user's Auth0 token is still valid every 15 minutes
  useEffect(() => {
    const checkSession = async () => {
      try {
        await getAccessTokenSilently();
      } catch (error: any) {
        if (error.error === LOGIN_REQUIRED) {
          navigate(`${ROUTES.SIGNIN.route}?inactive=true`);
        }
      }
    };

    const interval = setInterval(checkSession, 15 * 60 * 1000); // 15 minutes

    return () => clearInterval(interval);
  }, [getAccessTokenSilently, navigate]);

  const onIdle = () => {
    setShowModal(false);
    logout({
      returnTo: `${BASE_URL}${ROUTES.SIGNIN.route}?inactive=true`,
      client_id: process.env.AUTH0_SPA_CLIENT_ID,
    });
  };

  const onActive = () => {
    setShowModal(false);
  };

  const onPrompt = () => {
    setShowModal(true);
  };

  const { getRemainingTime, activate } = useIdleTimer({
    onIdle,
    onActive,
    onPrompt,
    timeout,
    promptBeforeIdle,
    throttle: 500,
    crossTab: true,
    syncTimers: 200,
  });

  useEffect(() => {
    const interval = setInterval(() => {
      setRemaining(Math.ceil(getRemainingTime() / 1000));
    }, 500);

    return () => clearInterval(interval);
  });

  const handleStillHere = () => {
    activate();
  };

  return (
    <>
      {showModal && (
        <Modal handleClose={() => null}>
          <div className="w-full sm:w-[687px] flex flex-col rounded">
            <div className="flex flex-col p-6 gap-6 bg-white rounded-t">
              <span className="font-semibold">You will be signed out soon</span>
              <span>It appears you have been inactive for a while.</span>
              <span>
                To protect your information, you will be logged out of Element
                in <span className="font-semibold">{remaining}</span> seconds
              </span>
            </div>
            <div className="flex justify-end p-4 bg-white border-t border-grey-90 rounded-b">
              <div className="flex gap-3">
                <Button
                  onClick={handleStillHere}
                  size="large"
                  type="primary"
                  text="Stay logged in"
                />
              </div>
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};
