// Libraries
import React from 'react';
// Components
import { Divider, Typography } from 'design-system';
import { ExclamationTriangleIcon } from '@heroicons/react/16/solid';
import { FormulaCard } from 'features/formula/formula-card';
// Utils
import {
  FillSize,
  InitialOrderQuantity,
  PointOfContact,
  Timeline,
} from '../form-modules';
// Constants
import { SampleRecipients } from './sample-recipients.component';

interface InternalRtlBriefEditProps {
  rtlFormulaUuid: Maybe<string>;
}

export const InternalRtlBriefEdit: React.FC<InternalRtlBriefEditProps> = ({
  rtlFormulaUuid,
}) => {
  return (
    <div className="flex flex-col gap-y-12 h-auto md:px-56 lg:px-56 pt-12 w-full pl-6 pr-6 md:p-9 md:pb-[200px] lg:p-12 lg:pb-[200px]">
      <div className="flex flex-col gap-y-6">
        <PointOfContact isExternalBrief={true} />
      </div>

      <Divider />

      <FillSize />

      <InitialOrderQuantity />

      <Divider />

      <Timeline />

      <Divider />

      <SampleRecipients />

      <Divider />

      <div className="flex flex-col gap-y-6">
        <div className="flex flex-row gap-x-3 px-1 p-3 pl-3 bg-yellow-95 items-center">
          <ExclamationTriangleIcon className="h-4 w-4 text-peach-60" />
          <Typography variant="h5" font="inter" color="warning">
            To use a different RTL formula, create a new project.
          </Typography>
        </div>
        <FormulaCard formulaUuid={rtlFormulaUuid} />
      </div>
    </div>
  );
};
