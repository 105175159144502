//Libraries
import React from 'react';
import { Alert, Button } from 'design-system';
import { useAuth0 } from '@auth0/auth0-react';
import { Navigate, useSearchParams } from 'react-router-dom';
import getYear from 'date-fns/getYear';

// Constants
import { ROUTES } from 'features/navigation';
//Assets
import stackedLogo from 'img/pm-stacked-logo.svg';
import signInBg from 'img/sign-in-bg.jpeg';

export const SignIn: React.FC = () => {
  const { isAuthenticated, loginWithRedirect } = useAuth0();
  const [searchParams] = useSearchParams();
  const isInactive = searchParams.get('inactive');

  return (
    <>
      {isAuthenticated ? (
        <Navigate to={ROUTES.HOME.route} />
      ) : (
        <div className="h-screen flex">
          <div
            className="w-0 sm:w-1/2 lg:w-2/3 flex-grow h-screen"
            style={{
              backgroundSize: 'cover',
              backgroundImage: `url(${signInBg})`,
            }}
          />
          <div className="flex flex-col w-full sm:w-1/2 lg:w-1/3 max-w-[596px] bg-white text-grey-50 px-16 pt-[192px] pb-3">
            <div className="flex flex-col justify-between h-full">
              <div className="flex flex-col items-center gap-12">
                <img src={stackedLogo} alt="Logo" className="w-[264px] h-16" />
                {isInactive && (
                  <Alert
                    isBanner
                    severity="warning"
                    content="You were logged out due to inactivity"
                  />
                )}
                <div className="flex flex-col items-center gap-3">
                  <Button
                    size="large"
                    text="Log in"
                    onClick={loginWithRedirect}
                  />
                  <span>
                    Don't have an account?{' '}
                    <button
                      className="text-royal-50 hover:underline cursor-pointer bg-transparent border-none p-0"
                      onClick={() =>
                        loginWithRedirect({
                          screen_hint: 'signup',
                        })
                      }
                    >
                      Sign up
                    </button>
                  </span>
                </div>
              </div>
              <div className="flex justify-center mt-auto">
                <span>© Copyright Prime Matter Labs {getYear(Date.now())}</span>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
