// Libraries
import React from 'react';
import { Divider, Typography } from 'design-system';
// Utils
import { IApiData, ProjectAttributes } from 'api';

interface ProjectOverviewShowPageProps {
  project: IApiData<ProjectAttributes>;
}

export const ProjectOverviewShowPage: React.FC<ProjectOverviewShowPageProps> = ({
  project,
}) => {
  const brief = project.parsedIncluded.brief;

  return (
    <div className="flex flex-col gap-y-12">
      <div className="flex flex-col gap-y-6">
        <Typography font="inter" variant="h3" id="basics">
          Basics
        </Typography>
        <div className="flex flex-col gap-y-4">
          <Typography
            font="inter"
            variant="h6"
            color="secondary"
            weight="semibold"
          >
            Name of Project
          </Typography>
          <Typography font="inter" variant="h4">
            {project.attributes.name}
          </Typography>
        </div>
        <div className="flex flex-col gap-y-4">
          <Typography
            font="inter"
            variant="h6"
            color="secondary"
            weight="semibold"
          >
            Company Name
          </Typography>
          <Typography font="inter" variant="h4">
            {project.attributes.companyName}
          </Typography>
        </div>
      </div>

      <Divider />

      <div className="flex flex-col gap-y-6">
        <Typography font="inter" variant="h3" id="point-of-contact">
          Point of Contact
        </Typography>
        <div className="flex flex-col gap-y-4">
          <Typography
            font="inter"
            variant="h6"
            color="secondary"
            weight="semibold"
          >
            Contact Name
          </Typography>
          <Typography font="inter" variant="h4">
            {project.parsedIncluded?.contact?.attributes?.fullName}
          </Typography>
        </div>
      </div>

      <Divider />

      <div className="flex flex-col gap-y-6">
        <Typography font="inter" variant="h3" id="product-basics">
          Product Basics
        </Typography>
        <div className="flex flex-row w-full">
          <div className="flex flex-col gap-y-4 flex-1">
            <Typography
              font="inter"
              variant="h6"
              color="secondary"
              weight="semibold"
            >
              Product Category
            </Typography>
            <Typography font="inter" variant="h4">
              {project.attributes?.category}
            </Typography>
          </div>
          <div className="flex flex-col gap-y-4 flex-1">
            <Typography
              font="inter"
              variant="h6"
              color="secondary"
              weight="semibold"
            >
              Product Type
            </Typography>
            <Typography font="inter" variant="h4">
              {project.attributes?.productType}
            </Typography>
          </div>
        </div>
      </div>

      <Divider />

      <div className="flex flex-col gap-y-6">
        <Typography font="inter" variant="h3" id="fill-size">
          Fill Size
        </Typography>
        <div className="flex flex-row gap-x-6">
          <div className="flex flex-col gap-y-4">
            <Typography
              font="inter"
              variant="h6"
              color="secondary"
              weight="semibold"
              additionalStyles="mr-10"
            >
              Fill
            </Typography>
            <Typography font="inter" variant="h4">
              {Number(
                parseFloat(brief.attributes?.size).toFixed(2)
              ).toLocaleString()}
            </Typography>
          </div>
          <div className="flex flex-col gap-y-4 ">
            <Typography
              font="inter"
              variant="h6"
              color="secondary"
              weight="semibold"
            >
              Unit
            </Typography>
            <Typography font="inter" variant="h4">
              {brief?.attributes?.unit}
            </Typography>
          </div>
        </div>
      </div>

      <Divider />

      <div className="flex flex-col gap-y-6">
        <Typography font="inter" variant="h3" id="initial-order-quantity">
          Initial Order Quantity
        </Typography>
        <div className="flex flex-row gap-x-2">
          <Typography font="inter" variant="h4">
            {parseInt(
              brief.attributes?.minimumOrderQuantity,
              10
            ).toLocaleString()}
          </Typography>
          <Typography font="inter" variant="h4" color="secondary">
            units
          </Typography>
        </div>
      </div>

      <Divider />

      <div className="flex flex-col gap-y-6">
        <Typography font="inter" variant="h3" id="target-cogs">
          Target Cost of Goods Sold (COGS)
        </Typography>
        <div className="flex flex-col gap-y-4">
          <Typography
            font="inter"
            variant="h6"
            color="secondary"
            weight="semibold"
          >
            Cost Per Ounce
          </Typography>
          <Typography font="inter" variant="h4">
            {brief.attributes?.targetCost
              ? `$${Number(
                  parseFloat(brief.attributes?.targetCost).toFixed(2)
                ).toLocaleString()}`
              : `$${Number(
                  parseFloat(brief.attributes?.targetMinCost).toFixed(2)
                ).toLocaleString()} - $${Number(
                  parseFloat(brief.attributes?.targetMaxCost).toFixed(2)
                ).toLocaleString()}`}
          </Typography>
        </div>
      </div>

      <Divider />

      <div className="flex flex-col gap-y-6">
        <Typography font="inter" variant="h3" id="timeline">
          Timeline
        </Typography>
        <div className="flex flex-row justify-between">
          <div className="flex flex-col gap-y-4">
            <Typography
              font="inter"
              variant="h6"
              color="secondary"
              weight="semibold"
            >
              Initial Date of Brief
            </Typography>
            <Typography font="inter" variant="h4">
              {project.attributes.createdAt
                ? new Date(project.attributes.createdAt).toLocaleDateString()
                : 'N/A'}
            </Typography>
          </div>
          <div className="flex flex-col gap-y-4">
            <Typography
              font="inter"
              variant="h6"
              color="secondary"
              weight="semibold"
            >
              Approval Date
            </Typography>
            <Typography font="inter" variant="h4">
              {project.attributes.approvalDate
                ? new Date(
                    project.attributes?.approvalDate
                  ).toLocaleDateString()
                : 'N/A'}
            </Typography>
          </div>
          <div className="flex flex-col gap-y-4">
            <Typography
              font="inter"
              variant="h6"
              color="secondary"
              weight="semibold"
            >
              Target Launch Date
            </Typography>
            <Typography font="inter" variant="h4">
              {brief.attributes.targetDate
                ? new Date(brief.attributes?.targetDate).toLocaleDateString()
                : 'N/A'}
            </Typography>
          </div>
        </div>
      </div>
    </div>
  );
};
