// Libraries
import React, { useState } from 'react';
import { TrashIcon } from '@heroicons/react/24/outline';
import { EllipsisVerticalIcon } from '@heroicons/react/16/solid';
import { useFormikContext } from 'formik';
// Components
import { Menu } from 'design-system/select.component';
// Utils
import { WorksheetFormValues } from './worksheet.types';
import { QuoteCalculator } from '../quote-calculator/quote-calculator.component';
// Constants
import { FILL_SIZE_KEYS, FILL_SIZE_MENU_OPTIONS } from './constants';

interface FillSizeSelectorProps {
  isPriceQuoteEditable: boolean;
  openFillSizeModal: (index?: number) => void;
  selectedSizeIndex: number;
  setSelectedSizeIndex: (index: number) => void;
}

export const FillSizeSelector: React.FC<FillSizeSelectorProps> = ({
  isPriceQuoteEditable,
  openFillSizeModal,
  selectedSizeIndex,
  setSelectedSizeIndex,
}) => {
  const { values, setFieldValue } = useFormikContext<WorksheetFormValues>();
  const {
    sizeOneUnit,
    sizeOneQuantity,
    sizeTwoUnit,
    sizeTwoQuantity,
    sizeThreeUnit,
    sizeThreeQuantity,
  } = values;

  const [editSizeDropdown, setEditSizeDropdown] = useState<number | null>(null);

  const sizeMenuOptions = [
    {
      label: 'Edit fill size',
      value: 'edit',
      additionalStyles:
        'text-grey-20 text-base border border-t-0 border-l-0 border-r-0 border-b-1/2 border-grey-90',
    },
    {
      label: 'Delete fill size',
      leadingIcon: <TrashIcon className="w-5 h-5 text-red-50" />,
      value: 'delete',
      additionalStyles: 'text-red-50 text-base',
    },
  ];

  const sizes = [
    { unit: sizeOneUnit, size: sizeOneQuantity },
    { unit: sizeTwoUnit, size: sizeTwoQuantity },
    { unit: sizeThreeUnit, size: sizeThreeQuantity },
  ];

  const totalFillSizes = sizes.filter(({ size, unit }) => size && unit).length;

  const renderSizes = () => {
    return sizes.map(({ size, unit }, index) => {
      if (!size || !unit) return null;

      const sizeInOunces = QuoteCalculator.convertContainerSizeToOunce({
        size,
        unit: unit as any,
      });

      return (
        <div className="relative flex items-center" key={size + unit + index}>
          <div
            onClick={() => {
              setSelectedSizeIndex(index);
              setFieldValue('selectedSizeIndex', index);
            }}
            key={index}
            className={`cursor-pointer font-medium  ${
              index === selectedSizeIndex &&
              'border-b-[3px] border-royal-50 text-grey-20'
            }`}
          >
            {sizeInOunces} oz
          </div>

          {isPriceQuoteEditable && (
            <EllipsisVerticalIcon
              onClick={() => setEditSizeDropdown(index)}
              className="w-4 h-4 ml-1 text-grey-50 cursor-pointer"
            />
          )}

          {editSizeDropdown === index && (
            <div className="absolute -top-8 left-0">
              <Menu
                width="w-[200px]"
                onClose={() => setEditSizeDropdown(null)}
                options={sizeMenuOptions}
                onChange={(option: any) => {
                  const userConfirmed = window.confirm(
                    'Are you sure? This cannot be undone.'
                  );
                  if (userConfirmed) handleMenuClick(option.value, index);
                }}
              />
            </div>
          )}
        </div>
      );
    });
  };

  const handleMenuClick = (value: string, index: number) => {
    switch (value) {
      case FILL_SIZE_MENU_OPTIONS.EDIT:
        openFillSizeModal(index);
        break;
      case FILL_SIZE_MENU_OPTIONS.DELETE:
        handleDeleteSize(index);
        break;
      default:
        break;
    }
  };

  const handleDeleteSize = (index: number) => {
    const sizeKey = FILL_SIZE_KEYS[index];
    const nextSize = sizes[index + 1];
    const nextSizeKey = FILL_SIZE_KEYS[index + 1];

    /**
     * if the next size does not exist then the size being deleted is the last size
     * if so then clear the current size
     *  */
    if (!nextSize?.unit || !nextSize?.size) {
      setFieldValue(sizeKey.unit, '');
      setFieldValue(sizeKey.size, 0);
    } else {
      // if the size being deleted is not the last size then move the next size to the current size and clear the next size
      setFieldValue(sizeKey.unit, nextSize.unit);
      setFieldValue(sizeKey.size, nextSize.size);
      setFieldValue(nextSizeKey.unit, '');
      setFieldValue(nextSizeKey.size, 0);
    }
  };

  return (
    <div className="col-start-2 col-span-10 flex gap-16 text-lg text-grey-50">
      {renderSizes()}
      {totalFillSizes < 3 && isPriceQuoteEditable && (
        <span
          className="cursor-pointer font-medium"
          onClick={() => openFillSizeModal()}
        >
          Add fill size +
        </span>
      )}
    </div>
  );
};
