// Libraries
import React from 'react';
// Utils
import { IApiData, ProjectAttributes } from 'api';
import { Checkbox, Divider, Typography } from 'design-system';

interface RegulatoryShowPageProps {
  project: IApiData<ProjectAttributes>;
}

export const RegulatoryShowPage: React.FC<RegulatoryShowPageProps> = ({
  project,
}) => {
  const brief = project.parsedIncluded?.brief;
  const countries = ['US', 'Canada', 'China', 'EU', 'Japan', 'ASEAN', 'Other'];

  const renderCountryCheckboxes = () => {
    return countries.map((country) => {
      const isChecked = Boolean(brief?.attributes.countries?.includes(country));
      if (country === 'Other') {
        return (
          <Checkbox
            key={country}
            primaryLabel={country}
            checked={isChecked}
            disabled
            description="Note: Additional countries will require a third party regulatory consultant"
            direction="col"
          />
        );
      } else {
        return (
          <Checkbox
            key={country}
            primaryLabel={country}
            checked={isChecked}
            disabled
          />
        );
      }
    });
  };

  return (
    <div className="flex flex-col gap-y-12">
      <div className="flex flex-col gap-y-6">
        <div className="flex flex-col gap-y-3">
          <Typography font="inter" variant="h3" id="statements-needed">
            Statements Needed
          </Typography>
          <Typography font="inter" variant="h4" weight="semibold">
            Select necessary statements:
          </Typography>
          <Checkbox
            primaryLabel="Vegan ingredients"
            checked={Boolean(project.attributes?.veganIngredientsStatement)}
            disabled
          />
          <Checkbox
            primaryLabel="Gluten free ingredients"
            checked={Boolean(project.attributes?.glutenFreeStatement)}
            disabled
          />
          <Checkbox
            primaryLabel="No animal derived ingredients"
            checked={Boolean(
              project.attributes?.noAnimalDerivedIngredientsStatement
            )}
            disabled
          />
          <Checkbox
            primaryLabel="No animal tested ingredients"
            checked={Boolean(
              project.attributes?.noAnimalTestedIngredientsStatement
            )}
            disabled
          />
          <Checkbox
            primaryLabel="IFRA compliance"
            checked={Boolean(project.attributes?.ifraComplianceStatement)}
            disabled
          />
        </div>
        <Typography font="inter" variant="h4">
          {project.attributes.statementsNote}
        </Typography>
      </div>

      <Divider />
      <div className="flex flex-col gap-y-6">
        <div className="flex flex-col gap-y-3">
          <Typography font="inter" variant="h3" id="country-compliance">
            Country Compliance
          </Typography>
          <Typography font="inter" variant="h4" weight="semibold">
            Product should comply with the following countries’ regulations:
          </Typography>
          {renderCountryCheckboxes()}
        </div>
        <Typography font="inter" variant="h4">
          {project.attributes?.countryComplianceNote}
        </Typography>
      </div>
    </div>
  );
};
