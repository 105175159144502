// Libraries
import React from 'react';
import { Checkbox, Divider, Typography } from 'design-system';
// Utils
import { IApiData, ProjectAttributes } from 'api';

interface ClaimsShowPageProps {
  project: IApiData<ProjectAttributes>;
}

export const ClaimsShowPage: React.FC<ClaimsShowPageProps> = ({ project }) => {
  return (
    <div className="flex flex-col gap-y-12">
      <div className="flex flex-col gap-y-6">
        <Typography font="inter" variant="h3" id="benefits">
          Benefits
        </Typography>
        <Typography font="inter" variant="h4" color="secondary">
          Attributes supported by ingredients in the formula.
        </Typography>
        <div className="flex flex-col gap-y-3">
          <Typography font="inter" variant="h4" weight="bold">
            Skin
          </Typography>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-y-3 justify-between">
            <div className="flex flex-col gap-y-3">
              <Checkbox
                primaryLabel="Brightening"
                checked={project.attributes?.brightening}
                disabled
              />
              <Checkbox
                primaryLabel="Firming"
                checked={project.attributes?.firming}
                disabled
              />
              <Checkbox
                primaryLabel="Hydration"
                checked={project.attributes?.hydration}
                disabled
              />
              <Checkbox
                primaryLabel="Mattifying"
                checked={project.attributes?.mattifying}
                disabled
              />
              <Checkbox
                primaryLabel="Moisturization"
                checked={project.attributes?.moisturization}
                disabled
              />
              <Checkbox
                primaryLabel="Radiance/glow"
                checked={project.attributes?.radianceGlow}
                disabled
              />
              <Checkbox
                primaryLabel="Self Tanner"
                checked={project.attributes?.selfTanner}
                disabled
              />
            </div>
            <div className="flex flex-col gap-y-3">
              <Checkbox
                primaryLabel="Soothing"
                checked={project.attributes?.soothing}
                disabled
              />
              <Checkbox
                primaryLabel="Tightening"
                checked={project.attributes?.tightening}
                disabled
              />
              <Checkbox
                primaryLabel="Eyes: Reduce appearance of dark circles"
                checked={project.attributes?.eyesReduceAppearanceOfDarkCircles}
                disabled
              />
              <Checkbox
                primaryLabel="Eyes; Reduce appearance of Fine line/crows feet"
                checked={
                  project.attributes?.eyesReduceAppearanceOfLineCrowsFeet
                }
                disabled
              />
              <Checkbox
                primaryLabel="Eyes: Reduce appearance of puffiness"
                checked={project.attributes?.eyesReduceAppearanceOfPuffiness}
                disabled
              />
            </div>
          </div>
        </div>
        <div className="flex flex-col gap-y-3">
          <Typography font="inter" variant="h4" weight="bold">
            Hair
          </Typography>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-y-3 justify-between">
            <div className="flex flex-col gap-y-3">
              <Checkbox
                primaryLabel="Anti-frizz"
                checked={project.attributes?.antiFrizz}
                disabled
              />
              <Checkbox
                primaryLabel="Conditioning"
                checked={project.attributes?.conditioning}
                disabled
              />
              <Checkbox
                primaryLabel="Curl defining"
                checked={project.attributes?.curlDefining}
                disabled
              />
              <Checkbox
                primaryLabel="Deep conditioning"
                checked={project.attributes?.deepConditioning}
                disabled
              />
              <Checkbox
                primaryLabel="Detangle"
                checked={project.attributes?.detangle}
                disabled
              />
              <Checkbox
                primaryLabel="Hard hold"
                checked={project.attributes?.hardHold}
                disabled
              />
            </div>
            <div className="flex flex-col gap-y-3">
              <Checkbox
                primaryLabel="Light hold"
                checked={project.attributes?.lightHold}
                disabled
              />
              <Checkbox
                primaryLabel="Moisture"
                checked={project.attributes?.moisture}
                disabled
              />
              <Checkbox
                primaryLabel="Shine"
                checked={project.attributes?.shine}
                disabled
              />
              <Checkbox
                primaryLabel="Smoothing"
                checked={project.attributes?.hairSmoothing}
                disabled
              />
              <Checkbox
                primaryLabel="Strengthening"
                checked={project.attributes?.strengthening}
                disabled
              />
            </div>
          </div>
        </div>
      </div>

      <Divider />

      <div className="flex flex-col gap-y-6">
        <Typography font="inter" variant="h3" id="clinical-data-claims">
          Clinical/Data Claims
        </Typography>
        <Typography font="inter" variant="h4" color="secondary">
          Must be supported by clinical substantiation studies, analytical tests
          and/or meets the FDA monograph as an OTC.
        </Typography>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-y-3 justify-between">
          <div className="flex flex-col gap-y-3">
            <Checkbox
              primaryLabel="Acne (OTC)"
              checked={project.attributes?.acneOTC}
              disabled
            />
            <Checkbox
              primaryLabel="Anti-fungal (OTC)"
              checked={project.attributes?.antiFungalOTC}
              disabled
            />
            <Checkbox
              primaryLabel="Anti-redness"
              checked={project.attributes?.antiRedness}
              disabled
            />
            <Checkbox
              primaryLabel="Blue light protection"
              checked={project.attributes?.blueLightProtection}
              disabled
            />
            <Checkbox
              primaryLabel="Gluten free"
              checked={project.attributes?.glutenFree}
              disabled
            />
            <Checkbox
              primaryLabel="Hydration (immediate and/or long term)"
              checked={project.attributes?.hydrationLongTerm}
              disabled
            />
          </div>
          <div className="flex flex-col gap-y-3">
            <Checkbox
              primaryLabel="Hypoallergenic"
              checked={project.attributes?.hypoallergenic}
              disabled
            />
            <Checkbox
              primaryLabel="No animal derived ingredients"
              checked={project.attributes?.noAnimalDerivedIngredients}
              disabled
            />
            <Checkbox
              primaryLabel="No animal testing"
              checked={project.attributes?.noAnimalTesting}
              disabled
            />
            <Checkbox
              primaryLabel="Pain Relief (OTC)"
              checked={project.attributes?.painReliefOTC}
              disabled
            />
            <Checkbox
              primaryLabel="Soothing"
              checked={project.attributes?.soothing}
              disabled
            />
          </div>
        </div>
        <Typography font="inter" variant="h4" weight="bold">
          Non-OTC Claim Comments
        </Typography>
        <Typography font="inter" variant="h4">
          {project.attributes?.nonOtcClaimComments}
        </Typography>
      </div>
    </div>
  );
};
