// Libraries
import React from 'react';

interface SquareChipProps {
  backgroundColor: string;
  borderColor?: string;
  hoverColor?: string;
  onClick: () => void;
  label: string;
  leadingIcon?: React.ReactElement;
  labelColor: string;
  selected?: boolean;
  trailingIcon?: React.ReactElement;
}

export const SquareChip: React.FC<SquareChipProps> = ({
  backgroundColor,
  borderColor,
  hoverColor,
  label,
  leadingIcon,
  labelColor,
  onClick,
  selected = false,
  trailingIcon,
}) => {
  return (
    <div
      onClick={() => onClick()}
      className={`flex w-[220px] items-center h-11 p-3 rounded cursor-pointer border ${borderColor}
      ${backgroundColor} ${labelColor} ${hoverColor}`}
    >
      <div className="flex gap-3">
        {leadingIcon}
        {label}
      </div>
      {trailingIcon}
    </div>
  );
};
